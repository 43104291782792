//@ts-nocheck
import Maybe from 'crocks/Maybe';

import { OnlineMagPageCharts } from './interactivity/chart';
import { OnlineMagPageIFrames } from './interactivity/iframe';
import { OnlineMagPageShares } from './interactivity/share';
import { OnlineMagPageArticles } from './interactivity/article';
import { OnlineMagPageJumps } from './interactivity/page-jump';
import { Competitions } from './interactivity/competition';
import { OnlineMagPageForwards } from './interactivity/forward';
import { OnlineMagPagePollAnswers } from './interactivity/poll-answer';
import { OnlineMagPageLinks } from './interactivity/link';
import { OnlineMagPageVideos } from './interactivity/video';
import { OnlineMagPageGalleries } from './interactivity/gallery';
import { rewriteToUseSSL } from './utils';
import * as constants from './constants';
import { parseChainData } from './events';
import { isGuid } from './guid';

import type { ModuleId, FullModule, OnlineMag } from './@types/pagetiger';
import type { ChartModule } from './@types/charts';

const { MODULES, MODULE_SUB_TYPES, MODULE_TYPES } = constants;

type AllInteractivity =
  | OnlineMagPageLinks
  | OnlineMagPagePollAnswers
  | OnlineMagPageArticles
  | OnlineMagPageIFrames
  | OnlineMagPageVideos
  | OnlineMagPageJumps
  | Competitions
  | OnlineMagPageForwards
  | OnlineMagPageShares
  | OnlineMagPageGalleries
  | OnlineMagPageCharts;

class OnlineMagPages {
  onlineMagIssue: OnlineMag;
  items: OnlineMagPage[];
  length: number;

  constructor(
    pobjOnlineMagIssue: OnlineMag,
    pages: string,
    links: string,
    pollAnswers: string,
    articles: string,
    iframes: string,
    videos: string,
    videoSubtitles: string,
    jumps: string,
    comps: string,
    galleries: string,
    galleryPhotos: string,
    forwards: string,
    shares: string,
    charts: ChartModule[],
    pagePanels: HTMLElement[][]
  ) {
    this.onlineMagIssue = pobjOnlineMagIssue;

    if (pages.length === 0) {
      return;
    }

    this.items = pages
      .split(constants.ROW_SEPARATOR)
      .map(
        (page, index) =>
          new OnlineMagPage(
            this,
            index,
            page,
            links,
            pollAnswers,
            articles,
            iframes,
            videos,
            videoSubtitles,
            jumps,
            comps,
            galleries,
            galleryPhotos,
            forwards,
            shares,
            charts,
            pagePanels[index]
          )
      );

    this.length = this.items.length;
  }
}

class OnlineMagPage {
  onlineMagPages: OnlineMagPages;
  pageIndex: number;
  id: string;
  guid: Guid;
  title: string;
  jpegUrl: string;
  thumbnailUrl: string;
  mandatoryInteractivityMessage: string;
  panels: HTMLElement[];
  height: number;
  width: number;
  chain: string;
  pageUrl: string;
  // OnlineMagPageLinks: OnlineMagPageLinks;
  // OnlineMagPagePollAnswers: OnlineMagPagePollAnswers;
  // OnlineMagPageArticles: OnlineMagPageArticles;
  // OnlineMagPageIFrames: OnlineMagPageIFrames;
  // OnlineMagPageVideos: OnlineMagPageVideos;
  // OnlineMagPageJumps: OnlineMagPageJumps;
  // OnlineMagPageComps: Competitions;
  // OnlineMagPageGalleries: OnlineMagPageGalleries;
  // OnlineMagPageForwards: OnlineMagPageForwards;
  // OnlineMagPageShares: OnlineMagPageShares;
  // OnlineMagPageCharts: OnlineMagPageCharts;
  modules: FullModule[];

  constructor(
    pages: OnlineMagPages,
    pageIndex: number,
    onlineMagPage: string,
    onlineMagPageLinks: string,
    onlineMagPagePollAnswers: string,
    onlineMagPageArticles: string,
    onlineMagPageIFrames: string,
    onlineMagPageVideos: string,
    onlineMagPageVideoSubtitles: string,
    onlineMagPageJumps: string,
    onlineMagPageComps: string,
    onlineMagPageGalleries: string,
    onlineMagPageGalleryPhotos: string,
    onlineMagPageForwards: string,
    onlineMagPageShares: string,
    onlineMagPageCharts: ChartModule[],
    panels: HTMLElement[] | undefined
  ) {
    this.onlineMagPages = pages;

    if (onlineMagPage.length == 0 && !isGuid(arData[1])) {
      return;
    }
    var arData = onlineMagPage.split(constants.COLUMN_SEPARATOR);

    this.pageIndex = pageIndex;
    this.id = arData[0];
    this.guid = arData[1];
    this.title = arData[2];
    // TODO: jpegUrl and html should never exist at the same time.
    this.jpegUrl = rewriteToUseSSL(arData[6]);
    this.thumbnailUrl = rewriteToUseSSL(arData[5]);
    this.mandatoryInteractivityMessage = arData[7];
    this.panels = panels || [];
    this.height = parseInt(arData[9]);
    this.width = parseInt(arData[10]);

    this.chain = parseChainData(arData[8]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.PAGE,
      pageIndex: pageIndex
    }));

    this.pageUrl = `${this.onlineMagPages.onlineMagIssue.onlineMagIssueUrl}/page${
      this.pageIndex + 1
    }.htm`;

    const links = new OnlineMagPageLinks(this, onlineMagPageLinks);
    const pollAnswers = new OnlineMagPagePollAnswers(this, onlineMagPagePollAnswers);
    const articles = new OnlineMagPageArticles(this, onlineMagPageArticles);
    const iframes = new OnlineMagPageIFrames(this, onlineMagPageIFrames);
    const videos = new OnlineMagPageVideos(this, onlineMagPageVideos, onlineMagPageVideoSubtitles);
    const jumps = new OnlineMagPageJumps(this, onlineMagPageJumps);
    const comps = new Competitions(this, onlineMagPageComps);
    const galleries = new OnlineMagPageGalleries(
      this,
      onlineMagPageGalleries,
      onlineMagPageGalleryPhotos
    );
    const forwards = new OnlineMagPageForwards(this, onlineMagPageForwards);
    const shares = new OnlineMagPageShares(this, onlineMagPageShares);
    const charts = new OnlineMagPageCharts(this, onlineMagPageCharts);

    this.modules = [
      ...links.items,
      ...pollAnswers.items,
      ...articles.items,
      ...iframes.items,
      ...videos.items,
      ...jumps.items,
      ...comps.items,
      ...galleries.items,
      ...forwards.items,
      ...shares.items,
      ...charts.items
    ];

    // TODO Try and remove these pages
    const page = {
      id: this.id,
      guid: this.guid,
      title: this.title,
      jpegUrl: this.jpegUrl,
      thumbnailUrl: this.thumbnailUrl,
      mandatoryInteractivityMessage: this.mandatoryInteractivityMessage,
      pageIndex: this.pageIndex,
      modules: this.modules,
      panels: panels,
      height: this.height,
      width: this.width
    };

    this.onlineMagPages.onlineMagIssue.pages.push(page);
  }
}

export { OnlineMagPages, OnlineMagPage };
