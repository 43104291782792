/* eslint-disable max-lines, func-style, max-statements, prefer-destructuring, complexity, max-lines-per-function */
// @ts-check
import $ from 'jquery';
import Maybe from 'crocks/Maybe';
import { throttle } from 'throttle-debounce';

import { cancelAndRemoveEvents, fireEvents, transformEvent } from '../events';
import * as constants from '../constants';
import { renderChartElement } from '../widgets';
import { destroyChart, getChartOptions, getDomElement } from '../utils';
import { ChartData, ChartModule } from '../@types/charts';
import { isGuid } from '../guid';
const { ANALYTICS_EVENTS, EVENT_TYPES, MODULES, MODULE_TYPES } = constants;

import type { ModuleTypes } from '../constants';
import type { OnlineMagPage } from '../mag-page';
import type { Guid } from '../guid';

class OnlineMagPageCharts {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageChart[];
  length: number;

  constructor(page: OnlineMagPage, charts: ChartModule[]) {
    this.onlineMagPage = page;

    this.items = charts.reduce((acc, chart) => {
      if (chart.onlineMagPageID === Number(page.id)) {
        const initChart = new OnlineMagPageChart(this, chart);

        page.onlineMagPages.onlineMagIssue.allOnlineMagPageCharts.push(initChart);

        return [...acc, initChart];
      }

      return acc;
    }, []);

    this.length = this.items.length;
  }
}

class OnlineMagPageChart {
  onlineMagCharts: OnlineMagPageCharts;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: number;
  title: string;
  tooltip: string;
  chartData: ChartData;
  backgroundColour: string;
  width: number;
  height: number;
  posLeft: number;
  posTop: number;
  mandatory: boolean;
  mandatoryMessage: string;
  popupWidth: number;
  popupHeight: number;
  popupBackgroundColour: string;
  popupBorderColour: string;
  disableClose: boolean;
  popupBorderSize: number;
  popupMarginSize: number;
  chartTableHtml: string;
  caption: string;
  displayInfo: number;
  infoPosition: number;
  infoTextColour: string;
  isComplete: boolean;
  instance: any;
  chain: any[];
  scale: number;
  highContrast: boolean;

  constructor(charts: OnlineMagPageCharts, chart: ChartModule) {
    if (!isGuid(chart.moduleGuid)) {
      return;
    }

    this.onlineMagCharts = charts;
    this.id = chart.id.toString();
    this.moduleGuid = chart.moduleGuid;
    this.moduleType = MODULES.CHART;
    this.subType = chart.subType;
    this.onlineMagPageID = chart.onlineMagPageID;
    this.title = chart.title;
    this.tooltip = chart.tooltip;
    this.chartData = chart.data;
    this.backgroundColour = chart.backgroundColour;
    this.width = chart.width;
    this.height = chart.height;
    this.posLeft = chart.posLeft;
    this.posTop = chart.posTop;

    const pageTurn = this.onlineMagCharts.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;

    this.mandatory = pageTurn.disableMandatory ? false : chart.mandatory;
    this.mandatoryMessage = chart.mandatoryMessage;
    this.popupWidth = chart.popupWidth;
    this.popupHeight = chart.popupHeight;
    this.popupBackgroundColour = chart.popupBackgroundColour;
    this.popupBorderColour = chart.popupBorderColour;
    this.disableClose = chart.disableClose;
    this.popupBorderSize = chart.popupBorderSize;
    this.popupMarginSize = chart.popupMarginSize;
    this.chartTableHtml = chart.tableHtml;
    /* OnClick = 0, Always = 1, Never = 2 */
    this.caption = chart.caption;
    this.displayInfo = chart.displayInfo;
    this.infoPosition = chart.infoPosition;
    this.infoTextColour = chart.infoTextColour;
    this.scale = this.onlineMagCharts.onlineMagPage.width / 800;
    this.instance = Maybe.Nothing();

    this.chain = transformEvent(chart.chains).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.CHART}-${this.id}`);
    this.highContrast = pageTurn.config.accessibleInterface === 'C';
  }

  clicked() {
    const chart = this;
    const { onlineMagIssue } = this.onlineMagCharts.onlineMagPage.onlineMagPages;
    const {
      baseUrl,
      containerElementID,
      onlineMagDefaultPopupBorderColour,
      onlineMagIssueVisitID
    } = onlineMagIssue;

    $.ajax({
      url: `${baseUrl}?M=14&VID=${onlineMagIssueVisitID}&CID=${chart.id}`,
      dataType: 'jsonp'
    })
      .then(res => {
        if (res.returnStatus !== '1') {
          console.warn(`JSON ERROR: ${res.returnMessage}`);

          return;
        }

        const getChartHeight = () =>
          getDomElement('#ptibox_content.lightbox-content')
            .map(lightBoxEl => {
              const lightboxHeight = lightBoxEl.getBoundingClientRect().height;
              const lightBoxHeightPercentage = ((lightboxHeight + 40) / window.innerHeight) * 100;
              const marginSize = chart.popupMarginSize * 2;
              const borderSize = chart.popupBorderSize * 2;
              const totalEdgeSize = marginSize + borderSize;

              return lightBoxHeightPercentage >= 80
                ? lightboxHeight - totalEdgeSize
                : Math.min(window.innerHeight * 0.8, chart.popupHeight) - totalEdgeSize;
            })
            .option(chart.popupHeight);

        /* eslint-disable */
        const chartResizer = throttle(200, () => {
          getDomElement('#ptibox_div_inner .chart').map(chartEl => {
            const captionHeight = getDomElement('#ptibox_caption')
              .map(el => el.getBoundingClientRect().height)
              .option(0);
            chartEl.style.height = `${getChartHeight() - captionHeight}px`;

            chart.instance.map(instance => {
              const chartWidth = chartEl.getBoundingClientRect().width || chart.width;
              instance.options = getChartOptions(chartWidth, chart);
              instance.update();
            });
          });
        });

        // @ts-ignore
        $().ptibox({
          backgroundColour: chart.popupBackgroundColour,
          borderColour: chart.popupBorderColour
            ? chart.popupBorderColour
            : onlineMagDefaultPopupBorderColour,
          callbackOnClose() {
            window.removeEventListener('resize', chartResizer);
            destroyChart(chart);
            cancelAndRemoveEvents();
            window.ptiHookupLeftRightArrows(containerElementID);
            fireEvents(EVENT_TYPES.ON_CLOSE, chart.chain);
          },
          callbackOnShow() {
            getDomElement(`.js-chart-${chart.id}`).map((chartElement: HTMLCanvasElement) => {
              import(/* webpackChunkName: "chart" */ 'chart.js')
                .then(({ default: Chart }) => {
                  chartElement.innerHTML = chart.chartTableHtml;

                  const chartWidth = chartElement.getBoundingClientRect().width || chart.width;

                  chart.instance = Maybe.Just(
                    new Chart(chartElement.getContext('2d'), {
                      ...chart.chartData,
                      options: getChartOptions(chartWidth, chart)
                    })
                  );

                  chartResizer();
                })
                .catch(console.warn);
            });

            window.addEventListener('resize', chartResizer);
          },
          cssClass: 'pop-up-chart',
          hideOnEscape: !chart.disableClose,
          hideOnOverlayClick: !chart.disableClose,
          infoTextColour: chart.infoTextColour,
          itemArray: [
            $.fn.ptibox.generateItemObject(
              renderChartElement({
                chart,
                pageIndex: this.onlineMagCharts.onlineMagPage.pageIndex,
                style: `height: ${getChartHeight()}px; width: 100%;`
              }),
              '',
              '',
              chart.caption,
              null,
              chart.popupWidth,
              chart.popupHeight,
              chart.infoPosition,
              false
            )
          ],
          margin: chart.popupMarginSize,
          padding: chart.popupBorderSize,
          showCloseButton: !chart.disableClose,
          showInfo: chart.displayInfo,
          showPlayButton: false,
          title: this.title
        });

        cancelAndRemoveEvents();
        fireEvents(EVENT_TYPES.ON_SHOW, chart.chain);
      })
      .catch(console.warn);

    this.isComplete = true;

    onlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    onlineMagIssue.analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

const cleanCharts = (chartElements: HTMLCanvasElement[]) => {
  chartElements.forEach(chartEl => {
    const chartId = parseInt(chartEl.dataset.id);
    const chart = window.pageTurn.allOnlineMagPageCharts.find(c => c.id == chartId);

    if (!chart) {
      return;
    }

    chart.instance.map(instance => instance.destroy());
    chart.instance = Maybe.Nothing();
  });
};

const loadCharts = (chartElements: HTMLCanvasElement[]) => {
  import(/* webpackChunkName: "chart" */ 'chart.js').then(({ default: ChartJs }) => {
    chartElements.forEach(chartEl => {
      const chartId = parseInt(chartEl.dataset.id);
      const chart = window.pageTurn.allOnlineMagPageCharts.find(c => c.id == chartId);

      if (!chart) {
        return;
      }

      const { width } = chartEl.getBoundingClientRect();
      const options = getChartOptions(width, chart);

      chart.instance = Maybe.Just(
        new ChartJs(chartEl, {
          ...chart.chartData,
          options
        })
      );
    });
  });
};

export { cleanCharts, loadCharts, OnlineMagPageChart, OnlineMagPageCharts };
