import { h } from 'preact';
import { useCallback, useRef, useEffect } from 'preact/hooks';
import type { ComponentChildren } from 'preact';

type HoverIntentProps = {
  enterCallback: () => void;
  leaveCallback: () => void;
  enterWait?: number;
  leaveWait?: number;
  children: ComponentChildren;
  component?: string;
};

function HoverIntent(props: HoverIntentProps) {
  const enterWait = props.enterWait === undefined ? 500 : props.enterWait;
  const leaveWait = props.leaveWait === undefined ? 500 : props.leaveWait;
  const componentProp = props.component || 'div';
  const isEnterEnvoked = useRef(false);
  const enterTimer = useRef<number>();
  const leaveTimer = useRef<number>();

  const cancelEnterTimer = useCallback(() => {
    clearTimeout(enterTimer.current);
  }, [enterTimer]);

  const cancelLeaveTimer = useCallback(() => {
    clearTimeout(leaveTimer.current);
  }, [leaveTimer]);

  const handleMouseEnter = useCallback(() => {
    cancelLeaveTimer();
    enterTimer.current = window.setTimeout(() => {
      props.enterCallback();
      isEnterEnvoked.current = true;
    }, enterWait);
  }, [cancelLeaveTimer, props, enterWait]);

  const handelMouseLeave = useCallback(() => {
    cancelEnterTimer();
    leaveTimer.current = window.setTimeout(() => {
      if (isEnterEnvoked.current) {
        props.leaveCallback();
        isEnterEnvoked.current = false;
      }
    }, leaveWait);
  }, [cancelEnterTimer, props, leaveWait]);

  useEffect(() => {
    return () => {
      cancelEnterTimer();
      cancelLeaveTimer();
    };
  }, [cancelEnterTimer, cancelLeaveTimer]);

  return h(
    componentProp,
    {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handelMouseLeave,
      style: { display: 'flex', height: '100%', width: '100%' }
    },
    props.children
  );
}

export { HoverIntent };
