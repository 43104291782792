import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import {
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR,
  COLUMN_SEPARATOR,
  ANALYTICS_EVENTS
} from '../constants';
import { isGuid } from '../guid';
import { getDomElements, isTrue, rewriteLinksToUseSSL } from '../utils';

import type { ModuleTypes } from '../constants';
import type { OnlineMagPage } from '../mag-page';
import type { ServerResponse } from '../@types/pagetiger';
import type { Guid } from '../guid';

class OnlineMagPageArticles {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageArticle[];
  length: number;

  constructor(page: OnlineMagPage, articles: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (articles.length == 0) {
      return;
    }

    const arData = articles.split(ROW_SEPARATOR);

    for (let intArticleIndex = 0; intArticleIndex < arData.length; ++intArticleIndex) {
      // Only add Articles to this page
      if (arData[intArticleIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageArticle(this, arData[intArticleIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageArticles.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageArticle {
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  onClose: string;
  cssClass: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  popupWidth: number;
  popupHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  disableClose: boolean;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  onlineMagPageArticles: OnlineMagPageArticles;
  isComplete: boolean;
  chain: any[];
  focusOutlineColour: string;

  constructor(articles: OnlineMagPageArticles, article: string) {
    this.onlineMagPageArticles = articles;

    if (article.length == 0) {
      return;
    }

    const pageTurn = articles.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const arData = article.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.ARTICLE;
    this.subType = arData[2];
    this.moduleType = MODULES.ARTICLE;
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[26];
    this.tooltip = arData[5];
    this.onClose = arData[7];
    this.cssClass = arData[8];
    this.width = arData[9];
    this.height = arData[10];
    this.posLeft = arData[11];
    this.posTop = arData[12];
    this.popupWidth = parseInt(arData[13]);
    this.popupHeight = parseInt(arData[14]);
    this.popupBorderSize = parseInt(arData[15]);
    this.popupBorderColour = arData[16];
    this.popupMarginSize = parseInt(arData[17]);
    this.popupBackgroundColour = arData[18];
    this.disableClose = isTrue(arData[19]);
    this.important = isTrue(arData[20]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[21]);
    this.mandatoryMessage = arData[22];
    this.hidden = isTrue(arData[23]);
    this.focusOutlineColour = arData[27];
    this.chain = parseChainData(arData[24]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.ARTICLE}-${this.id}`);
  }

  clicked() {
    const objOnlineMagIssue =
      this.onlineMagPageArticles.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { baseUrl, containerElementID, host, onlineMagIssueVisitID } = objOnlineMagIssue;
    const strOnClose = this.onClose;
    const article = this;
    const url = `${baseUrl}?M=6&VID=${onlineMagIssueVisitID}&AID=${this.id}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    })
      .then((response: ServerResponse) => {
        if (response.returnStatus !== '1') {
          console.warn(`JSON ERROR: ${response.returnMessage}`);

          return;
        }

        const strHTML = `
          <div data-t="popup-more-info" class="popup mod-more-info">
            ${rewriteLinksToUseSSL(host, response.returnData)}
          </div>`;

        cancelAndRemoveEvents();
        fireEvents(EVENT_TYPES.ON_SHOW, article.chain);

        // @ts-ignore
        $().ptibox({
          backgroundColour: article.popupBackgroundColour,
          borderColour:
            article.popupBorderColour.length > 0
              ? article.popupBorderColour
              : objOnlineMagIssue.onlineMagDefaultPopupBorderColour,
          callbackOnClose() {
            cancelAndRemoveEvents();
            window.ptiHookupLeftRightArrows(containerElementID);

            if (strOnClose.length > 0) {
              window.setTimeout(strOnClose, 100);
            } else {
              fireEvents(EVENT_TYPES.ON_CLOSE, article.chain);
            }
          },
          callbackOnShow() {
            const videos = getDomElements(`#${containerElementID} video`) as HTMLVideoElement[];
            videos.map((video: HTMLVideoElement) => video.pause());
          },
          cssClass: `ptiArticle ${article.cssClass}`.trim(),
          hideOnEscape: !article.disableClose,
          hideOnOverlayClick: !article.disableClose,
          itemArray: [
            $.fn.ptibox.generateItemObject(
              strHTML,
              '',
              '',
              article.title,
              null,
              article.popupWidth,
              article.popupHeight,
              null,
              false
            )
          ],
          margin: article.popupMarginSize,
          padding: article.popupBorderSize,
          showCloseButton: !article.disableClose,
          showInfo: 2,
          showPlayButton: false
        });
      })
      .catch(console.error);

    article.isComplete = true;

    objOnlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    objOnlineMagIssue.analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageArticle, OnlineMagPageArticles };
