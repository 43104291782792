/* eslint-disable complexity, max-statements, no-shadow */
import { getDomElements } from './utils';

const ACTIVE_AREA = {
  BL: 'bl',
  BR: 'br',
  NONE: 'none',
  TL: 'tl',
  TR: 'tr'
};

const getActiveArea = (container: Element, mouseEvent: MouseEvent): string => {
  const { left, top, width, height } = container.getBoundingClientRect();
  const { clientX, clientY } = mouseEvent;
  const relX = Math.abs(clientX - left);
  const relY = Math.abs(clientY - top);
  const heightRelYDiff = Math.abs(height - relY);
  const widthRelXDiff = Math.abs(width - relX);
  const threshold = 100;

  if (relX < threshold && relY < threshold) {
    return ACTIVE_AREA.TL;
  }

  if (relX < threshold && heightRelYDiff < threshold) {
    return ACTIVE_AREA.BL;
  }

  if (widthRelXDiff < threshold && relY < threshold) {
    return ACTIVE_AREA.TR;
  }

  if (widthRelXDiff < threshold && heightRelYDiff < threshold) {
    return ACTIVE_AREA.BR;
  }

  return ACTIVE_AREA.NONE;
};

const resetCorners = () => {
  getDomElements(`
  .page.mod-right-page,
  .page.mod-left-page,
  .page.mod-prev-behind,
  .page.mod-right-behind,
  .page.mod-left-behind`).map(el => el.removeAttribute('active-corner'));

  getDomElements('.page-fold').map(el => el.classList.remove('mod-show'));
};

export { ACTIVE_AREA, getActiveArea, resetCorners };
