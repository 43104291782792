// @ts-check
import identity from 'ramda/src/identity';

import { isNilOrBlank, parseJson } from './utils';
import type { DocumentAnalytics, DocumentSettings } from './@types/pagetiger';
import { ANIMATION_MODES, COLOURS } from './constants';

const { BLACK, WHITE } = COLOURS
const INTERACTIVITY_COLOUR = '#65CCFF';

const defaultAnalytics: DocumentAnalytics = {
  facebookCode: '',
  googleAnalyticsCode: '',
  ptTracking: false
};

const defaultDocumentSettings: DocumentSettings = {
  allowManualPageTurn: false,
  availableLayouts: 'single and double',
  clickableAreaColour: INTERACTIVITY_COLOUR,
  clickableAreaStyle: 'hover',
  defaultLayoutMobile: 'auto',
  defaultLayoutPC: 'auto',
  frameLeftRightMargin: 10,
  frameStretchToFit: true,
  frameStretchToFitSingle: 'width and height',
  frameToolbarColour: BLACK,
  frameToolbarIconColour: WHITE,
  frameToolbarLogoLink: '',
  frameToolbarLogoTarget: '_blank',
  frameToolbarLogoUrl: '',
  frameToolbarLogoAbsoluteUrl: '',
  frameToolbarPosition: 'top',
  frameToolbarSize: 'Standard',
  frameTopBottomMargin: 25,
  frameWidth: 960,
  mandatoryClickableAreaColour: INTERACTIVITY_COLOUR,
  moveSpeed: '800',
  pageAnimationMobile: ANIMATION_MODES.BOOK,
  pageAnimationPC: ANIMATION_MODES.BOOK,
  popupBackgroundImageUrl: '',
  popupBackgroundLogoUrl: '',
  popupBodyBGColour: '',
  popupBodyColour: '',
  popupBodyFont: '',
  popupBorderColour: '',
  popupButtonBGColour: '',
  popupButtonTextColour: '',
  popupHeadingColour: '',
  popupHeadingFont: '',
  popupHorizontalLineColour: '',
  popupHorizontalLineWidth: '140px',
  popupLinkColour: '',
  popupTableBGColour: '',
  popupTableBorderColour: '',
  popupTableHeadingBGColour: '',
  popupTableHeadingColour: '',
  showContents: true,
  showDownload: false,
  showIssueList: false,
  showLogo: true,
  showPageShadows: 0,
  showPaging: true,
  showPrint: false,
  showSearch: true,
  showShare: true,
  sideNavBehaviour: 'always visible',
  sideNavColour: BLACK,
  sideNavIconColour: WHITE,
  sideNavUseToolbarColour: true,
  sideNavUseToolbarIconColour: true,
  zoomMagnification: '0'
};

const addSettingsDefaults = (parsedStyles: DocumentSettings): DocumentSettings =>
  Object.entries(parsedStyles).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: isNilOrBlank(val) ? defaultDocumentSettings[key] : val
    }),
    {} as DocumentSettings
  );

const addAnalyticsDefaults = (analytics: DocumentAnalytics): DocumentAnalytics => ({
  ...defaultAnalytics,
  ...analytics
});

const parseAnalytics = (analytics: string): DocumentAnalytics =>
  parseJson(analytics)
    .map(addAnalyticsDefaults)
    .either(() => defaultAnalytics, identity);

const parseDocumentSettings = (styles: string): DocumentSettings =>
  parseJson(styles)
    .map(addSettingsDefaults)
    .either(() => defaultDocumentSettings, identity);

export { parseAnalytics, parseDocumentSettings };
