import $ from 'jquery';
import Maybe from 'crocks/Maybe';
import { throttle } from 'throttle-debounce';

import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import * as constants from '../constants';
import { getDomElement, isTrue, openWindow } from '../utils';
import { isGuid } from '../guid';

const { ANALYTICS_EVENTS, COLUMN_SEPARATOR, EVENT_TYPES, MODULES, MODULE_TYPES, ROW_SEPARATOR } =
  constants;

import type { OnlineMagPage } from '../mag-page';
import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPageIFrames {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageIFrame[];
  length: number;

  constructor(page: OnlineMagPage, iframes: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (iframes.length == 0) {
      return;
    }

    const arData = iframes.split(ROW_SEPARATOR);

    for (let intIFrameIndex = 0; intIFrameIndex < arData.length; ++intIFrameIndex) {
      // Only add IFrames to thsi page
      if (arData[intIFrameIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageIFrame(this, arData[intIFrameIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageIFrames.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageIFrame {
  onlineMagPageIFrames: OnlineMagPageIFrames;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  url: string;
  onClose: string;
  cssClass: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  popupWidth: number;
  popupHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  disableClose: boolean;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  chain: any[];
  isComplete: boolean;
  protocol: 'https://' | 'http://';
  focusOutlineColour: string;

  constructor(iframes: OnlineMagPageIFrames, iframe: string) {
    this.onlineMagPageIFrames = iframes;

    if (iframe.length == 0) {
      return;
    }

    const pageTurn = this.onlineMagPageIFrames.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = iframes.onlineMagPage.onlineMagPages.onlineMagIssue;
    const arData = iframe.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.IFRAME;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[26];
    this.tooltip = arData[5];
    this.url = arData[6];
    this.onClose = arData[7];
    this.cssClass = arData[8];
    this.width = arData[9];
    this.height = arData[10];
    this.posLeft = arData[11];
    this.posTop = arData[12];
    this.popupWidth = parseInt(arData[13]);
    this.popupHeight = parseInt(arData[14]);
    this.popupBorderSize = parseInt(arData[15]);
    this.popupBorderColour = arData[16];
    this.popupMarginSize = parseInt(arData[17]);
    this.popupBackgroundColour = arData[18];
    this.disableClose = isTrue(arData[19]);
    this.important = isTrue(arData[20]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[21]);
    this.mandatoryMessage = arData[22];
    this.hidden = isTrue(arData[23]);
    this.focusOutlineColour = arData[27];
    this.chain = parseChainData(arData[24]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.IFRAME}-${this.id}`);
    this.protocol = this.url.indexOf('https://') == 0 ? 'https://' : 'http://';
  }
  clicked() {
    var iframe = this;
    var objOnlineMagIssue = this.onlineMagPageIFrames.onlineMagPage.onlineMagPages.onlineMagIssue;
    var { baseUrl, containerElementID, onlineMagDefaultPopupBorderColour, onlineMagIssueVisitID } =
      objOnlineMagIssue;
    var strOnClose = this.onClose;

    const getIframeHeight = () =>
      getDomElement('#ptibox_content.lightbox-content')
        .map(lightBoxEl => {
          const lightboxHeight = lightBoxEl.getBoundingClientRect().height;
          const lightBoxHeightPercentage = ((lightboxHeight + 40) / window.innerHeight) * 100;

          return lightBoxHeightPercentage >= 80
            ? lightboxHeight - 80
            : Math.min(window.innerHeight * 0.8, iframe.popupHeight) - 40;
        })
        .option(iframe.popupHeight);

    const iframeResizer = throttle(300, () => {
      getDomElement('#ptibox_div_inner .js-iframe-popup').map(
        chartEl => (chartEl.style.height = `${getIframeHeight()}px`)
      );
    });

    cancelAndRemoveEvents(MODULE_TYPES.MODULE);
    fireEvents(EVENT_TYPES.ON_SHOW, iframe.chain);
    $('.lightbox-content').css({ overflowY: 'hidden' });

    if (this.protocol.includes('http:')) {
      openWindow(this.url, '_blank');
    } else {
      const html = `
      <div class="js-iframe-popup" data-t="iframe-modal" style="height: ${getIframeHeight()}px; overflow:hidden;">
        <iframe class="responsive-item" data-t="iframe" title="${iframe.title}" src="${this.url}" frameborder="0"></iframe>
      </div>`;

      // @ts-ignore
      $().ptibox({
        backgroundColour: this.popupBackgroundColour,
        borderColour:
          this.popupBorderColour.length > 0
            ? this.popupBorderColour
            : onlineMagDefaultPopupBorderColour,
        callbackOnClose() {
          window.removeEventListener('resize', iframeResizer);
          cancelAndRemoveEvents(MODULE_TYPES.MODULE);
          fireEvents(EVENT_TYPES.ON_CLOSE, iframe.chain);

          window.ptiHookupLeftRightArrows(containerElementID);
          $('.lightbox-content').css({ overflowY: '' });

          if (strOnClose.length > 0) {
            window.setTimeout(strOnClose, 100);
          }
        },
        callbackOnShow() {
          window.addEventListener('resize', iframeResizer);
        },
        cssClass: this.cssClass,
        hideOnEscape: !this.disableClose,
        hideOnOverlayClick: !this.disableClose,
        itemArray: [
          $.fn.ptibox.generateItemObject(
            html,
            '',
            '',
            this.title,
            null,
            this.popupWidth + this.popupMarginSize * 2,
            this.popupHeight + this.popupMarginSize * 2,
            null,
            false
          )
        ],
        margin: this.popupMarginSize,
        padding: this.popupBorderSize,
        showCloseButton: !this.disableClose,
        showInfo: 2,
        showPlayButton: false
      });
    }

    const url = `${baseUrl}?M=12&VID=${onlineMagIssueVisitID}&FID=${this.id}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    });

    this.isComplete = true;

    objOnlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    objOnlineMagIssue.analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageIFrame, OnlineMagPageIFrames };
