/* Lower level functions for dealing with a lack immutable standard library */

function assoc<T>(target: T, newFields: Partial<T>): T {
  return { ...target, ...newFields };
}

function get<T, K extends keyof T>(target: T, key: K): T[K] {
  return target[key];
}

export { assoc, get };
