type Guid = string & { [$Guid]: true };
declare const $Guid: unique symbol;

const GuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

function isGuid(value: unknown): value is Guid {
  return typeof value === 'string' && GuidRegex.test(value.trim());
}

export { isGuid };
export type { Guid };
