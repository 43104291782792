import Either from 'crocks/Either';
import { Just } from 'crocks/core/Maybe';

import { handleChainActionEvent } from './events';
import { ACTION_TYPES } from './constants';

const { Left, Right } = Either;

/** @typedef {import('./events').Event} Event */

const guidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

const validateID = id => {
  if (Number.isInteger(id) && id > 0 && id < 10000000) {
    return Right(id);
  }

  return Left('invalid ID');
};

const validateGUID = guid => {
  if (typeof guid === 'string' && guid.trim().match(guidRegex)) {
    return Right(guid);
  }

  return Left('invalid GUID');
};

const makeObject = type => value =>
  Right({
    type,
    value
  });

const validateIDGUID = input =>
  typeof input === 'string'
    ? validateGUID(input).chain(makeObject('GUID'))
    : validateID(input).chain(makeObject('ID'));

const pageTigerAPI = {
  activateModule(guid, { goToPage = false, suppressPageAction = false } = {}) {
    validateGUID(guid).either(
      errorMessage => console.warn(`Invoked 'activateModule' with an ${errorMessage}.`),
      validGUID => {
        const event /** @type {Event} */ = {
          actionTypeID: ACTION_TYPES.ACTIVATE_MODULE,
          actionModuleGuid: Just(validGUID),
          actionArgs: [suppressPageAction, goToPage]
        };
        // @ts-ignore
        handleChainActionEvent(event);
      }
    );
  },
  goToPage(idOrGUID) {
    validateIDGUID(idOrGUID).either(
      errorMessage => console.warn(`Invoked 'activateModule' with an ${errorMessage}.`),
      validGUID => {
        const event /** @type {Event} */ = {
          actionTypeID: ACTION_TYPES.ACTIVATE_MODULE,
          actionModuleGuid: Just(validGUID),
          actionArgs: [false, false]
        };
        // @ts-ignore
        handleChainActionEvent(event);
      }
    );
  }
};

export { pageTigerAPI, validateGUID, validateID, validateIDGUID };
