import { h } from 'preact';
import type { ComponentChildren } from 'preact';
import { MutableRef, useEffect, useRef } from 'preact/hooks';

type ClickOutsideProps = {
  handleClick: () => void;
  children: ComponentChildren;
};

type WrapperRef = HTMLDivElement | null;

/**
 * A click outside hook.
 */
function useOutsideClick(
  ref: MutableRef<WrapperRef>,
  clickCallback: ClickOutsideProps['handleClick']
) {
  useEffect(
    function clickEffect() {
      function handleClickOutside({ target }: MouseEvent) {
        if (target instanceof Element && ref.current && !ref.current.contains(target)) {
          clickCallback();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);

      return function clickEffectCleanup() {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [ref]
  );
}

/**
 * Detects if a click happened outside of the component.
 * If the click did happen outside of the component the
 * the
 */
function ClickOutside({ handleClick, children }: ClickOutsideProps) {
  const wrapperRef = useRef<WrapperRef>(null);

  useOutsideClick(wrapperRef, handleClick);

  return <div ref={wrapperRef}>{children}</div>;
}

export { ClickOutside };
