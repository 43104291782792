/* eslint-disable max-statements, prefer-destructuring, max-classes-per-file, complexity, max-lines-per-function */
/* @ts-check */
import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import {
  ANALYTICS_EVENTS,
  COLUMN_SEPARATOR,
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR
} from '../constants';
import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import { defaultToEmptyString, delay, getI18nByKey, handleInvalidFormElement, htmlEncode, isTrue, validateEmail } from '../utils';

import type { OnlineMagPage } from '../mag-page';
import type { ServerResponse } from '../@types/pagetiger';
import type { ModuleTypes } from '../constants';
import { Guid, isGuid } from '../guid';

class OnlineMagPageForwards {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageForward[];
  length: number;

  constructor(page: OnlineMagPage, forwards: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (forwards.length === 0) {
      return;
    }

    const arData = forwards.split(ROW_SEPARATOR);

    for (let intForwardIndex = 0; intForwardIndex < arData.length; ++intForwardIndex) {
      // Only add Forwards to this page
      if (arData[intForwardIndex].split(COLUMN_SEPARATOR)[3] === this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageForward(this, arData[intForwardIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageForwards.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageForward {
  onlineMagPageForwards: OnlineMagPageForwards;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  onClose: string;
  cssClass: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  popupWidth: number;
  popupHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  disableClose: boolean;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  chain: any[];
  isComplete: boolean;
  friendOrColleague: 'friend' | 'colleague';
  friendOrColleagueFormLabel: string;
  ptiboxItems: any[];
  focusOutlineColour: string;

  constructor(forwards: OnlineMagPageForwards, forward: string) {
    this.onlineMagPageForwards = forwards;
    this.submit = this.submit.bind(this);

    if (forward.length === 0) {
      return;
    }

    const pageTurn = this.onlineMagPageForwards.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const arData = forward.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.FORWARD;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[25];
    this.tooltip = arData[5];
    this.onClose = arData[6];
    this.cssClass = arData[7];
    this.width = arData[8];
    this.height = arData[9];
    this.posLeft = arData[10];
    this.posTop = arData[11];
    this.popupWidth = parseInt(arData[12]);
    this.popupHeight = parseInt(arData[13]);
    this.popupBorderSize = parseInt(arData[14]);
    this.popupBorderColour = arData[15];
    this.popupMarginSize = parseInt(arData[16]);
    this.popupBackgroundColour = arData[17];
    this.disableClose = isTrue(arData[18]);
    this.important = isTrue(arData[19]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[20]);
    this.mandatoryMessage = arData[21];
    this.hidden = isTrue(arData[22]);
    this.chain = parseChainData(arData[23]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));
    this.focusOutlineColour = arData[26];

    this.isComplete = previousSession.includes(`${MODULES.FORWARD}-${this.id}`);
    this.friendOrColleague = this.subType === 'Forward to a colleague' ? 'colleague' : 'friend';
    this.friendOrColleagueFormLabel = this.subType === 'Forward to a colleague' ? `${getI18nByKey('Forward.Labels.YourColleaguesEmailAddress')}` : `${getI18nByKey('Forward.Labels.YourFriendsEmailAddress')}`;
  }

  clicked() {
    fireEvents(EVENT_TYPES.ON_SHOW, this.chain);

    const { onlineMagIssue } = this.onlineMagPageForwards.onlineMagPage.onlineMagPages;
    const { containerElementID, analytics } = onlineMagIssue;
    const strOnClose = this.onClose;
    const forward = this;
    const formClassName = 'js-forward-form';
    const html = `
      <div class="ptiforward">

        <div class="js-forward-result">
          <h2 class="pti_result mod-hidden" tabindex="-1"></h2>
        </div>

        <form class="${formClassName}">
          <h2>${htmlEncode(this.title)}</h2>

          <span class="form-error js-forward-form-error" aria-live="polite"></span>

          <div class="form-element">
            <label for="pti_txtForwardFromName" class="pti_question mod-bold">
              <span class="form-element-label-text">${getI18nByKey('Forward.Labels.YourName')}</span>
              <div>
                <input id="pti_txtForwardFromName" class="form-element-input mod-full-width" type="text" required >
              </div>
            </label>
          </div>

          <div class="form-element">
            <label for="pti_txtForwardFromEmail" class="pti_question mod-bold">
              <span class="form-element-label-text">${getI18nByKey('Forward.Labels.YourEmailAddress')}</span>
              <div>
                <input id="pti_txtForwardFromEmail" class="form-element-input mod-full-width" type="email" required >
              </div>
            </label>
          </div>

          <div class="form-element">
            <label for="pti_txtForwardToEmail" class="pti_question mod-bold">
              <span class="form-element-label-text">${this.friendOrColleagueFormLabel}</span>
              <div>
                <input id="pti_txtForwardToEmail" class="form-element-input mod-full-width" type="email" required >
              </div>
            </label>
          </div>

          <div class="form-element">
            <label for="pti_txtForwardMessage" class="pti_question mod-bold">
                <div class="form-element-label-text">
                  ${getI18nByKey('Comp.Labels.Email')}
                  <span class="form-element-label-helper-text">(${getI18nByKey('Comp.Labels.Optional')})</span>
                <div>
                <textarea id="pti_txtForwardMessage" class="form-element-input mod-full-width" rows="5"></textarea>
              </div>
            </label>
          </div>

          <button id="pti_submit" style="margin-top: 15px;" class="ptibox_buttonBG lightbox-button-bg" tabindex="0"><span class="lightbox-button-bg-span">${getI18nByKey('Forward.Buttons.Submit')}</span></button>

        </form>

      </div>`;

    this.ptiboxItems = [];

    this.ptiboxItems.push(
      $.fn.ptibox.generateItemObject(
        html,
        '',
        '',
        this.title,
        null,
        this.popupWidth,
        this.popupHeight,
        null,
        false
      )
    );

    // @ts-ignore
    $().ptibox({
      backgroundColour: this.popupBackgroundColour,
      borderColour:
        this.popupBorderColour.length > 0
          ? this.popupBorderColour
          : onlineMagIssue.onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        cancelAndRemoveEvents();
        fireEvents(EVENT_TYPES.ON_CLOSE, forward.chain);
        window.ptiHookupLeftRightArrows(containerElementID);

        if (strOnClose.length > 0) {
          window.setTimeout(strOnClose, 100);
        }
      },
      cssClass: this.cssClass,
      hideOnEscape: !this.disableClose,
      hideOnOverlayClick: !this.disableClose,
      itemArray: this.ptiboxItems,
      margin: this.popupMarginSize,
      padding: this.popupBorderSize,
      showCloseButton: !this.disableClose,
      showInfo: 2,
      showPlayButton: false
    });

    $(`.${formClassName}`)
      .find('input')
      .off('invalid')
      .on('invalid', function (e) {
        handleInvalidFormElement(e);
      });

    $(`.${formClassName}`).on('submit', this.submit);

    this.isComplete = true;

    onlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }

  submit() {
    // Get the answers for each question and ensure that all questions have been answer
    const fromNameInput = <HTMLInputElement>document.querySelector('#pti_txtForwardFromName');
    const fromEmailInput = <HTMLInputElement>document.querySelector('#pti_txtForwardFromEmail');
    const toEmailInput = <HTMLInputElement>document.querySelector('#pti_txtForwardToEmail');
    const messageInput = <HTMLInputElement>document.querySelector('#pti_txtForwardMessage');

    const fromName = defaultToEmptyString(fromNameInput.value);
    const fromEmail = defaultToEmptyString(fromEmailInput.value);
    const toEmail = defaultToEmptyString(toEmailInput.value);
    const message = defaultToEmptyString(messageInput.value);
    const { onlineMagIssue } = this.onlineMagPageForwards.onlineMagPage.onlineMagPages;
    const { baseUrl, onlineMagIssueVisitID, analytics } = onlineMagIssue;
    const $formError = $('.js-forward-form-error');

    $('.ptiforward input').removeAttr('aria-invalid');
    $formError.text('').removeClass('mod-show');

    if (fromName.length === 0) {
      $('#pti_txtForwardFromName').attr('aria-invalid', 'true');
      $formError.text(getI18nByKey('Forward.Submission.Error.YourName')).addClass('mod-show');
    } else if (!validateEmail(fromEmail)) {
      $('#pti_txtForwardFromEmail').attr('aria-invalid', 'true');
      $formError.text(getI18nByKey('Forward.Submit.Error.YourEmailAddress')).addClass('mod-show');
    } else if (!validateEmail(toEmail)) {
      $('#pti_txtForwardToEmail').attr('aria-invalid', 'true');

      if(this.subType === 'Forward to a colleague') {
        $formError.text(getI18nByKey('Forward.Submit.Error.YourColleaguesEmailAddress')).addClass('mod-show');
      } else {
        $formError.text(getI18nByKey('Forward.Submit.Error.YourFriendsEmailAddress')).addClass('mod-show');
      }
    } else {
      $('#pti_submit').fadeOut();

      const url = `${baseUrl}?M=10&VID=${onlineMagIssueVisitID}&FID=${this.id}&FN=${encodeURI(
        fromName
      )}&FE=${encodeURI(fromEmail)}&TE=${encodeURI(toEmail)}&MG=${encodeURI(message)}`;

      $.ajax({
        url,
        dataType: 'jsonp'
      }).then((response: ServerResponse) => {
        if (response.returnStatus != '1') {
          console.warn(`JSON ERROR: ${response.returnMessage}`);
          $('#pti_submit').fadeIn();

          return;
        }

        $('#pti_submit').off();
        $('.js-forward-form').remove();

        delay(100).then(() => {
          $('.js-forward-result h2')
            .removeClass('mod-hidden')
            .text(getI18nByKey('Forward.Submit.Success'))
            .trigger('focus');
        });
      });

      analytics.fireEvent(ANALYTICS_EVENTS.SUBMITTED, this.title, this.subType);
    }

    return false;
  }
}

export { OnlineMagPageForward, OnlineMagPageForwards };
