/* eslint-disable max-statements, no-param-reassign */
// @ts-check
import curry from 'ramda/src/curry';
import { debounce, throttle } from 'throttle-debounce';
import defaultTo from 'ramda/src/defaultTo';
import lensPath from 'ramda/src/lensPath';
import pipe from 'ramda/src/pipe';
import view from 'ramda/src/view';

import { fromNullable } from './utils';
import { fireEvents } from './events';
import { EVENT_TYPES, MODULE_SUB_TYPES } from './constants';

/** @typedef {import('./@types/pagetiger').OnlineMag} OnlineMag */
/** @typedef {import('./@types/pagetiger').VideoModule} VideoModule */

/**
 * @param {number} pageIndex - The PageTurn object
 * @returns {(param: OnlineMag) => any}
 */
const getPageModules = pageIndex =>
  pipe(view(lensPath(['pages', pageIndex, 'modules'])), defaultTo([]));

// =======
// HELPERS
// =======

/**
 * @param {OnlineMag} pageTurn - The PageTurn object
 * @param {Event} videoEvent - The page we're on
 * @returns {any}
 */
const getVideo = (pageTurn, videoEvent) => {
  if (videoEvent.target instanceof HTMLVideoElement) {
    const { pageIndex = '0', videoId = '0' } = videoEvent.target.dataset;
    const pageModules = getPageModules(Number(pageIndex))(pageTurn);
    const video = window.getByID(pageModules, Number(videoId));

    return fromNullable(video);
  }
};

/**
 * @param {any} chain - The PageTurn object
 * @returns {number}
 */
const getVideoEventTime = chain => parseInt(chain.eventArgs.option(Infinity));

/**
 * @param {import('./@types/pagetiger').VideoModule} video
 * @param {number} currentTime
 * @returns {number}
 */
const nextTimeChainAction = (video, currentTime) =>
  video.chain.reduce((acc, item) => {
    const eventTime = parseInt(item.eventArgs.option(0));

    if (eventTime > currentTime) {
      if (eventTime > acc) {
        return acc;
      }

      return eventTime;
    }

    return acc;
  }, Infinity);

const checkVideoTimeAndFireEvent = throttle(0, (video, currentTime) => {
  const videoTime = Math.floor(currentTime);

  video.chain
    .filter(chain => chain.eventTypeID === 4)
    .map(chain => {
      const chainActivationTime = getVideoEventTime(chain);
      if (
        !chain.blockFire &&
        chainActivationTime === videoTime &&
        getVideoEventTime(chain) === videoTime
      ) {
        fireEvents(EVENT_TYPES.ON_VIDEO_TIME, [chain]);
        chain.blockFire = true;
      }

      if (chainActivationTime !== videoTime) {
        chain.blockFire = false;
      }
    });
});

// ======
// EVENTS
// ======

const metaDataLoaded = curry((pageTurn, videoEvent) => {
  getVideo(pageTurn, videoEvent).map(video => {
    if (video.currentTime > 0) {
      videoEvent.target.currentTime = video.currentTime;
    }
  });
});

const play = curry((pageTurn, videoEvent) => {
  const videoId = parseInt(videoEvent.target.dataset.videoId);
  const vid = window.getByID(pageTurn.allOnlineMagPageVideos, videoId);

  if (vid && vid.videoPlayed) {
    vid.videoPlayed();
  }

  getVideo(pageTurn, videoEvent).map(video => {
    video.isPlaying = true;
  });
});

const pause = curry(
  /**
   * @param {OnlineMag} pageTurn
   * @param {boolean} turning
   * @param {Event} videoEvent
   * @returns {void}
   */
  (pageTurn, turning, videoEvent) => {
    getVideo(pageTurn, videoEvent).map(
      /** @param {VideoModule} video */
      video => {
        // The pause event fires when the video is removed from the page
        if (!turning) {
          video.isPlaying = false;
        }
      }
    );
  }
);

const bouncedEnded = debounce(500, true, (pageTurn, videoEvent) => {
  getVideo(pageTurn, videoEvent).map(video => {
    video.currentTime = 0;
    video.isPlaying = false;
    video.maxCurrentTimeProgress = videoEvent.target.duration;
    video.hasBeenWatched = true;
    window.ptiVideoCompleted(pageTurn.containerElementID, video.id);
    fireEvents(EVENT_TYPES.ON_COMPLETE, video.chain);

    if (video.subType === MODULE_SUB_TYPES.VIDEO_POP_UP) {
      // ALWAYS:
      // 1) You close ptibox before firing any other events.
      // 2) You then fire any events
      $().ptibox.close();
    }
  });
});

const ended = curry((pageTurn, videoEvent) => bouncedEnded(pageTurn, videoEvent));

const seek = curry((pageTurn, videoEvent) => {
  getVideo(pageTurn, videoEvent).map(video => {
    if (video.hasBeenWatched) {
      return;
    }

    if (video.preventScrubbing && videoEvent.target.currentTime > video.maxCurrentTimeProgress) {
      videoEvent.target.currentTime = video.maxCurrentTimeProgress;

      return;
    }

    // Try and prevent the user from being able to skip past the next video chain
    if (videoEvent.target.currentTime > video.nextChainTime) {
      videoEvent.target.currentTime = video.nextChainTime;
    }
  });
});

const timelineUpdate = curry((pageTurn, videoEvent) => {
  getVideo(pageTurn, videoEvent).map(video => {
    const eventTime = videoEvent.target.currentTime;
    const delta = eventTime - video.currentTime;

    if (videoEvent.target.seeking && video.preventScrubbing) {
      videoEvent.target.currentTime = video.currentTime;
      return;
    }

    // This is our best guess that the video is being played and not being scrubbed or skipped.
    if (videoEvent.target.currentTime > video.maxCurrentTimeProgress && delta < 0.5) {
      video.maxCurrentTimeProgress = eventTime;
      video.currentTime = eventTime;
    }

    // If the user is seeking and trying to get past the next chain
    if (videoEvent.target.seeking && eventTime >= video.nextChainTime) {
      videoEvent.target.currentTime = Math.min(eventTime, video.nextChainTime);
      video.currentTime = video.nextChainTime;
      checkVideoTimeAndFireEvent(video, video.nextChainTime);
      return;
    }

    //If there are still chain events to deal with carry on
    if (video.nextChainTime !== Infinity && video.nextChainTime !== undefined) {
      checkVideoTimeAndFireEvent(video, eventTime);
    }

    video.nextChainTime = nextTimeChainAction(video, video.currentTime);
    video.currentTime = eventTime;
  });
});

export { ended, metaDataLoaded, play, pause, seek, timelineUpdate };
