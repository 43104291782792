import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import * as constants from '../constants';
import { isTrue, openWindow } from '../utils';
import { isGuid } from '../guid';

const { ANALYTICS_EVENTS, COLUMN_SEPARATOR, EVENT_TYPES, MODULES, MODULE_TYPES, ROW_SEPARATOR } =
  constants;

import type { OnlineMagPage } from '../mag-page';
import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPageShares {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageShare[];
  length: number;

  constructor(page: OnlineMagPage, shares: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (shares.length == 0) {
      return;
    }

    var arData = shares.split(ROW_SEPARATOR);

    for (var intShareIndex = 0; intShareIndex < arData.length; ++intShareIndex) {
      // Only add shares to thsi page
      if (arData[intShareIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageShare(this, arData[intShareIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageShares.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageShare {
  onlineMagPageShares: OnlineMagPageShares;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  shareType: string;
  target: '_self' | '_blank';
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  chain: any[];
  isComplete: boolean;
  focusOutlineColour: string;

  constructor(onlineMagPageShares: OnlineMagPageShares, share: string) {
    this.onlineMagPageShares = onlineMagPageShares;

    if (share.length == 0) {
      return;
    }

    const pageTurn = this.onlineMagPageShares.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const arData = share.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.SHARE;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[18];
    this.tooltip = arData[5];
    this.shareType = arData[6];
    this.target = arData[7] as '_self' | '_blank';
    this.width = arData[8];
    this.height = arData[9];
    this.posLeft = arData[10];
    this.posTop = arData[11];
    this.important = isTrue(arData[12]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[13]);
    this.mandatoryMessage = arData[14];
    this.hidden = isTrue(arData[15]);
    this.focusOutlineColour = arData[19];

    this.chain = parseChainData(arData[16]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.SHARE}-${this.id}`);
  }

  clicked() {
    const { onlineMagIssue } = this.onlineMagPageShares.onlineMagPage.onlineMagPages;
    const { onlineMagIssueUrl } = onlineMagIssue;
    const { pageIndex } = this.onlineMagPageShares.onlineMagPage;
    const pageUrl = `${onlineMagIssueUrl}/page${pageIndex + 1}.htm`;

    const url = new URL(`https://www.addtoany.com/add_to/${this.shareType}`);
    url.searchParams.set('linkurl', pageUrl);

    cancelAndRemoveEvents();
    fireEvents(EVENT_TYPES.ON_SHOW, this.chain);

    openWindow(url.href, this.target);
    this.shareVisit();
  }

  shareVisit() {
    const objIssue = this.onlineMagPageShares.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { analytics, baseUrl, onlineMagIssueVisitID } = objIssue;
    const url = `${baseUrl}?M=11&VID=${onlineMagIssueVisitID}&SID=${this.id}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    });

    this.isComplete = true;

    objIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageShare, OnlineMagPageShares };
