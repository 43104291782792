/* eslint-disable max-statements, max-lines-per-function, max-classes-per-file, prefer-destructuring */
// @ts-check
import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import {
  ANALYTICS_EVENTS,
  COLUMN_SEPARATOR,
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR,
  TABLE_SEPARATOR
} from '../constants';
import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import { htmlEncode, isTrue, prefersReducedMotion } from '../utils';
import { OnlineMagPage } from '../mag-page';
import { isGuid } from '../guid';

import type { ServerResponse } from '../@types/pagetiger';
import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPagePollAnswers {
  onlineMagPage: OnlineMagPage;
  items: PollAnswer[];
  length: number;

  constructor(page: OnlineMagPage, pollAnswers: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (pollAnswers.length === 0) {
      return;
    }

    this.items = pollAnswers.split(ROW_SEPARATOR).reduce((acc, pollAnswerTxt) => {
      const pageId = pollAnswerTxt.split(COLUMN_SEPARATOR)[11];

      if (pageId !== this.onlineMagPage.id) {
        return acc;
      }

      const pollAnswer = new PollAnswer(this, pollAnswerTxt);

      this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPagePollAnswers.push(pollAnswer);

      return [...acc, pollAnswer];
    }, []);

    this.length = this.items.length;
  }
}

class PollAnswer {
  onlineMagPagePollAnswers: OnlineMagPagePollAnswers;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPagePollID: string;
  pollTitle: string;
  onPageName: string;
  answer: string;
  tooltip: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  onlineMagPageID: string;
  moduleGuidNext: string;
  onClose: string;
  cssClass: string;
  popupWidth: number;
  popupHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  popupBarColour: string;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  chain: any[];
  isComplete: boolean;
  ptiboxItems: any[];
  focusOutlineColour: string;

  constructor(answers: OnlineMagPagePollAnswers, answer: string) {
    this.onlineMagPagePollAnswers = answers;

    if (answer.length === 0) {
      return;
    }

    const pageTurn = answers.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const answerArray = answer.split(COLUMN_SEPARATOR);
    const guid = answerArray[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = answerArray[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.POLL_ANSWER;
    this.subType = answerArray[2];
    this.onlineMagPagePollID = answerArray[3];
    this.pollTitle = answerArray[4];
    this.onPageName = answerArray[5];
    this.answer = answerArray[5];
    this.tooltip = answerArray[6];
    this.width = answerArray[7];
    this.height = answerArray[8];
    this.posLeft = answerArray[9];
    this.posTop = answerArray[10];
    this.onlineMagPageID = answerArray[11];
    this.moduleGuidNext = answerArray[13];
    this.onClose = answerArray[14];
    this.cssClass = answerArray[15];
    this.popupWidth = parseInt(answerArray[16]);
    this.popupHeight = parseInt(answerArray[17]);
    this.popupBorderSize = parseInt(answerArray[18]);
    this.popupBorderColour = answerArray[19];
    this.popupMarginSize = parseInt(answerArray[20]);
    this.popupBackgroundColour = answerArray[21];
    this.popupBarColour = answerArray[22];
    this.important = isTrue(answerArray[23]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(answerArray[24]);
    this.mandatoryMessage = answerArray[25];
    this.hidden = isTrue(answerArray[27]);
    this.focusOutlineColour = answerArray[31];

    // Chains for selecting a specific poll option.
    const pollAnswerChain = parseChainData(answerArray[12]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    // This is the chain data for the overall poll
    const pollChain = parseChainData(answerArray[28]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.chain = pollAnswerChain.length > 0 ? pollAnswerChain : pollChain;
    this.isComplete = previousSession.includes(
      `${MODULES.POLL_ANSWER}-${this.onlineMagPagePollID}`
    );
  }

  clicked() {
    const { onlineMagPagePollID } = this;
    const { onlineMagIssue } = this.onlineMagPagePollAnswers.onlineMagPage.onlineMagPages;
    const { baseUrl, onlineMagIssueVisitID, analytics } = onlineMagIssue;
    const url = `${baseUrl}?M=5&VID=${onlineMagIssueVisitID}&AID=${this.id}&callback=?`;
    const showResults =
      this.chain.filter(chain => chain.delayMs === 0 && chain.eventTypeID !== EVENT_TYPES.ON_CLOSE)
        .length === 0;

    cancelAndRemoveEvents();
    fireEvents(EVENT_TYPES.ON_SHOW, this.chain);

    $.ajax({
      url,
      dataType: 'jsonp'
    }).then((response: ServerResponse) => {
      if (response.returnStatus !== '1') {
        console.warn(`JSON ERROR: ${response.returnMessage}`);

        return;
      }

      if (showResults) {
        $.fn.ptibox.showLoading();
        this.showPollResults(response.returnData);
      }

      this.isComplete = true;

      this.onlineMagPagePollAnswers.items.forEach(pollAnswer => {
        if (pollAnswer.onlineMagPagePollID === onlineMagPagePollID) {
          pollAnswer.isComplete = true;
        }
      });

      onlineMagIssue.onModuleComplete(this);
      fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);

      analytics.fireEvent(
        ANALYTICS_EVENTS.CLICKED,
        `${this.pollTitle} - ${this.answer}`,
        this.subType
      );
    });
  }

  /**
   * @param {string} results
   */
  showPollResults(results) {
    const { chain, onClose, onlineMagPagePollID, popupBarColour } = this;
    const { onlineMagIssue } = this.onlineMagPagePollAnswers.onlineMagPage.onlineMagPages;
    const reduceMotion = prefersReducedMotion();
    const pollResults = results.split(TABLE_SEPARATOR);
    const title = pollResults[1];
    const pollOptions = pollResults[2].split(ROW_SEPARATOR);
    const html = `
        <div class="ptipoll" data-t="poll-modal" data-poll-id="${onlineMagPagePollID}">
          <h2 class="pti_title">${htmlEncode(title)}</h2>

          ${pollOptions
            .map(pollOption => {
              const [pollId, answer, _liveTotalCount, percentage] =
                pollOption.split(COLUMN_SEPARATOR);
              const backgroundColor = popupBarColour ? `background-color:${popupBarColour}` : ``;

              return `
              <div class="pti_answer">${htmlEncode(answer)}
                <span data-t="poll-result-${pollId}">(${percentage}%)</span>
              </div>
              <div id="pti_bar${pollId}" class="pti_bar" style="width: ${
                reduceMotion ? `${percentage}` : '0%'
              }%; ${backgroundColor}"></div>`;
            })
            .join('')}
        </div>`;

    $.fn.ptibox.hideLoading();

    this.ptiboxItems = [
      $.fn.ptibox.generateItemObject(
        html,
        '',
        '',
        title,
        null,
        this.popupWidth,
        this.popupHeight,
        null,
        false
      )
    ];

    // @ts-ignore
    $().ptibox({
      backgroundColour: this.popupBackgroundColour,
      borderColour:
        this.popupBorderColour.length > 0
          ? this.popupBorderColour
          : onlineMagIssue.onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        cancelAndRemoveEvents();
        fireEvents(EVENT_TYPES.ON_CLOSE, chain);
        window.ptiHookupLeftRightArrows(onlineMagIssue.containerElementID);

        if (onClose.length > 0) {
          window.setTimeout(onClose, 100);
        }
      },
      callbackOnShow() {
        pollOptions.map(pollOption => {
          const [pollId, , , percentage] = pollOption.split(COLUMN_SEPARATOR);
          const $bar = $(`#pti_bar${pollId}`);

          if (!reduceMotion) {
            $bar.animate({ width: `+=${percentage}%` }, 'slow');
          }
        });
      },
      cssClass: this.cssClass,
      itemArray: this.ptiboxItems,
      margin: this.popupMarginSize,
      padding: this.popupBorderSize,
      showInfo: 2,
      showPlayButton: false
    });
  }
}

export { PollAnswer, OnlineMagPagePollAnswers };
