// @ts-check
/* eslint-disable prefer-destructuring, max-statements, max-classes-per-file */
import Maybe from 'crocks/Maybe';
import clamp from 'ramda/src/clamp';

import {
  ANALYTICS_EVENTS,
  COLUMN_SEPARATOR,
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR
} from '../constants';
import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import { isGuid } from '../guid';

import { OnlineMagPage } from '../mag-page';
import { isTrue } from '../utils';

import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPageJumps {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageJump[];
  length: number;

  constructor(page: OnlineMagPage, onlineMagPageJumps: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (onlineMagPageJumps.length === 0) {
      return;
    }

    const arData = onlineMagPageJumps.split(ROW_SEPARATOR);

    for (let intJumpIndex = 0; intJumpIndex < arData.length; ++intJumpIndex) {
      // Only add Jumps to this page
      if (arData[intJumpIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageJump(this, arData[intJumpIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageJumps.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageJump {
  onlineMagPageJumps: OnlineMagPageJumps;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  pageIndex: number;
  pageAtATime: boolean;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  chain: any[];
  isComplete: boolean;
  focusOutlineColour: string;

  constructor(jumps: OnlineMagPageJumps, jump: string) {
    this.onlineMagPageJumps = jumps;

    if (jump.length === 0) {
      return;
    }

    const pageTurn = this.onlineMagPageJumps.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const numPages = this.onlineMagPageJumps.onlineMagPage.onlineMagPages.length;
    const arData = jump.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.JUMP;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[18];
    this.tooltip = arData[5];
    this.pageIndex = clamp(0, numPages, parseInt(arData[6]));
    this.pageAtATime = isTrue(arData[7]);
    this.width = arData[8];
    this.height = arData[9];
    this.posLeft = arData[10];
    this.posTop = arData[11];
    this.important = isTrue(arData[12]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[13]);
    this.mandatoryMessage = arData[14];
    this.focusOutlineColour = arData[19];

    /** @type {any[]} */
    this.chain = parseChainData(arData[16]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.JUMP}-${this.id}`);
  }

  clicked() {
    const { onlineMagIssue } = this.onlineMagPageJumps.onlineMagPage.onlineMagPages;
    const { analytics, containerElementID } = onlineMagIssue;

    this.isComplete = true;

    cancelAndRemoveEvents();
    fireEvents(EVENT_TYPES.ON_SHOW, this.chain);

    onlineMagIssue.onModuleComplete(this);

    window.ptiGotoPage(containerElementID, this.pageIndex);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageJump, OnlineMagPageJumps };
