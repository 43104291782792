/* eslint-disable  max-params, complexity, max-lines-per-function, prefer-destructuring, max-statements, max-classes-per-file, max-lines */
// @ts-check
import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import {
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR,
  COLUMN_SEPARATOR,
  ANALYTICS_EVENTS
} from '../constants';
import { OnlineMagPage } from '../mag-page';
import { SubmissionResponse } from './competition/api';
import { isTrue } from '../utils';
import { isGuid } from '../guid';

import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class Competitions {
  onlineMagPage: OnlineMagPage;
  items: Competition[];
  length: number;

  constructor(page: OnlineMagPage, comps: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (comps.length === 0) {
      return;
    }

    this.items = comps.split(ROW_SEPARATOR).reduce((items, row) => {
      const compPageId = row.split(COLUMN_SEPARATOR)[3];

      if (compPageId === this.onlineMagPage.id) {
        const pageComp = new Competition(this, row);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageComps.push(pageComp);

        return [...items, pageComp];
      }

      return items;
    }, []);

    this.length = this.items.length;
  }
}

class Competition {
  id: number;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: number;
  title: string;
  onPageName: string;
  tooltip: string;
  backgroundColour: string;
  width: number;
  height: number;
  posLeft: number;
  posTop: number;
  mandatory: boolean;
  important: boolean;
  mandatoryMessage: string;
  popupWidth: number;
  popupHeight: number;
  popupBackgroundColour: string;
  popupBorderColour: string;
  disableClose: boolean;
  popupBorderSize: number;
  popupMarginSize: number;
  caption: string;
  displayInfo: number;
  infoPosition: number;
  infoTextColour: string;
  isComplete: boolean;
  instance: any;
  chain: any[];
  importany: boolean;
  onlineMagPageComps: Competitions;
  singleQuestionAtATime: boolean;
  reviewBeforeSubmission: boolean;
  displayResultsSummary: boolean;
  showComments: boolean;
  commentsLabel: string;
  resultChain: Array<any>;
  cssClass: string;
  onClose: string;
  captureEmail: number;
  isOnResultsScreen: boolean;
  hideRetryButton: boolean;
  focusOutlineColour: string;

  constructor(comps: Competitions, comp: string) {
    this.onlineMagPageComps = comps;

    if (comp.length === 0) {
      return;
    }

    const pageTurn = this.onlineMagPageComps.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const data = comp.split(COLUMN_SEPARATOR);
    const guid = data[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = parseInt(data[0]);
    this.moduleGuid = guid;
    this.moduleType = MODULES.COMP;
    this.subType = data[2];
    this.onlineMagPageID = parseInt(data[3]);
    this.title = data[4];
    this.onPageName = data[32];
    this.tooltip = data[5];
    // CaptureEmail values: 1 = No | 2 = Optional | 3 = Required
    this.captureEmail = parseInt(data[6]);
    this.onClose = data[7];
    this.cssClass = data[8];
    this.width = parseInt(data[9]);
    this.height = parseInt(data[10]);
    this.posLeft = parseInt(data[11]);
    this.posTop = parseInt(data[12]);
    this.popupWidth = parseInt(data[13]);
    this.popupHeight = parseInt(data[14]);
    this.popupBorderSize = parseInt(data[15]);
    this.popupBorderColour = data[16];
    this.popupMarginSize = parseInt(data[17]);
    this.popupBackgroundColour = data[18];
    this.disableClose = isTrue(data[19]);
    this.important = isTrue(data[20]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(data[21]);
    this.mandatoryMessage = data[22];
    this.focusOutlineColour = data[33];

    this.chain = parseChainData(data[24]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.singleQuestionAtATime = isTrue(data[25]);
    this.reviewBeforeSubmission = isTrue(data[26]);
    this.displayResultsSummary = isTrue(data[27]);
    this.showComments = isTrue(data[28]);
    this.commentsLabel = data[29];
    this.hideRetryButton = isTrue(data[30]);
    this.resultChain = [];
    this.isOnResultsScreen = false;
    /**
     * If the user has completed the competition and reloads / revisits the doc,
     * we need to know if they've completed it, regardless of the "Remember Previous Interactions"
     * setting.
     * Fixes: https://dev.azure.com/PageTiger/PageTiger/_workitems/edit/2606/
     */
    const hasPreviouslyCompleted = isTrue(data[31]);

    this.isComplete =
      hasPreviouslyCompleted || previousSession.includes(`${MODULES.COMP}-${this.id}`);
  }

  setEmailAddress(emailAddress: string): void {
    this.onlineMagPageComps.onlineMagPage.onlineMagPages.onlineMagIssue.emailAddress = emailAddress;
  }

  clicked() {
    const { onlineMagIssue } = this.onlineMagPageComps.onlineMagPage.onlineMagPages;
    const { analytics, containerElementID, onlineMagDefaultPopupBorderColour } = onlineMagIssue;
    const comp = this;

    import(/* webpackChunkName: "competition" */ './competition/index')
      .then(({ startApp, destroyApp }) => {
        // @ts-ignore
        $().ptibox({
          callbackOnBeforeClose: destroyApp,
          backgroundColour: this.popupBackgroundColour,
          borderColour:
            this.popupBorderColour.length > 0
              ? this.popupBorderColour
              : onlineMagDefaultPopupBorderColour,
          callbackOnClose() {
            cancelAndRemoveEvents();

            comp.isOnResultsScreen
              ? fireEvents(EVENT_TYPES.ON_RESULT_POP_UP_CLOSE, comp.resultChain)
              : fireEvents(EVENT_TYPES.ON_CLOSE, comp.chain);

            comp.isOnResultsScreen = false;

            window.ptiHookupLeftRightArrows(containerElementID);
          },
          cssClass: `${this.cssClass} comp-popup`,
          hideOnEscape: !this.disableClose,
          // Prevent users from being able to accidentally click on the background and
          // close the lightbox and lose their progress
          hideOnOverlayClick: false,
          itemArray: [
            $.fn.ptibox.generateItemObject(
              `<div class="competition-app"></div>`,
              '',
              '',
              this.title,
              null,
              this.popupWidth,
              this.popupHeight,
              null,
              false
            )
          ],
          margin: this.popupMarginSize,
          padding: this.popupBorderSize,
          showCloseButton: !this.disableClose,
          showInfo: 2,
          showPlayButton: false
        });

        const updateLightboxStyles = ({
          backgroundColour,
          borderColour,
          borderSize,
          disableClose,
          marginSize,
          width,
          height
        }: SubmissionResponse['result']['popup']) => {
          $.fn.ptibox.options().padding = borderSize;

          if (borderColour) {
            $.fn.ptibox.options().borderColour = borderColour;
          }

          if (backgroundColour) {
            $.fn.ptibox.options().backgroundColour = backgroundColour;
          }

          $.fn.ptibox.options().margin = marginSize;
          $.fn.ptibox.options().showCloseButton = !disableClose;
          $.fn.ptibox.options().hideOnEscape = !disableClose;
          // Prevent users from being able to accidentally click on the background and
          // close the lightbox and lose their progress
          $.fn.ptibox.options().hideOnOverlayClick = false;
          $.fn.ptibox.options().itemArray = [
            $.fn.ptibox.generateItemObject('', '', '', this.title, null, width, height, null, true)
          ];

          $.fn.ptibox.changeItem();
        };

        cancelAndRemoveEvents();
        startApp(comp, updateLightboxStyles);

        analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
      })
      .catch(console.error);
  }
}

export { Competitions, Competition };
