// @ts-check
/* eslint-disable no-param-reassign, max-statements */
import $ from 'jquery';
import Cookies from 'js-cookie';
import identity from 'ramda/src/identity';

import { getI18nByKey, tryCatch } from './utils';

import type { CookiePreferences, CookieSection, OnlineMag } from './@types/pagetiger';

const COOKIE_PREFS_NAME = 'cookie-prefs';

const saveCookiePreferencesLocalStorage = tryCatch((cookiePrefs: CookiePreferences): void => {
  if (window.localStorage) {
    window.localStorage.setItem(COOKIE_PREFS_NAME, JSON.stringify(cookiePrefs));
  }
});

const saveCookiePreferences = (cookiePrefs: CookiePreferences): void => {
  const isSecure = document.location.protocol === 'https:';
  const sameSite = isSecure ? 'None' : 'Strict';

  Cookies.set(COOKIE_PREFS_NAME, JSON.stringify(cookiePrefs), {
    expires: 365,
    path: '/',
    domain: document.location.hostname,
    sameSite: sameSite,
    secure: isSecure
  });

  saveCookiePreferencesLocalStorage(cookiePrefs);
};

const getCookiePreferencesRaw = (): string => {
  const cookieValue = Cookies.get(COOKIE_PREFS_NAME);

  if (window.localStorage) {
    const cookiePrefsLs = window.localStorage.getItem(COOKIE_PREFS_NAME);

    return cookiePrefsLs ? cookiePrefsLs : '';
  }

  if (cookieValue) {
    return cookieValue;
  }

  return '';
};

const getCookiePreferences = () => tryCatch(getCookiePreferencesRaw)().either(() => '', identity);

const cookieModal = (): void => {
  const pageTurn: OnlineMag = window.ptiGetInstance();

  $('.js-popup-cookie-notice').remove();

  // @ts-ignore
  $().ptibox({
    backgroundColour: '#fff',
    borderColour: '#fff',
    callbackOnShow() {
      setupListeners(pageTurn);
    },
    cssClass: 'cookie-modal',
    hideOnEscape: false,
    hideOnOverlayClick: false,
    itemArray: [
      $.fn.ptibox.generateItemObject(
        getCookieMarkup(pageTurn),
        '',
        '',
        getI18nByKey('Cookies.Title'),
        null,
        700,
        800,
        null,
        false
      )
    ],
    margin: 20,
    padding: 20,
    showCloseButton: false,
    showInfo: 2,
    showPlayButton: false
  });
};

const renderCookieSection = ({ title, text, section, inputChecked }: CookieSection): string => {
  const hasInput = typeof inputChecked === 'boolean';
  const isChecked = inputChecked === true;
  const cookieDescriptionClass = inputChecked === true ? 'enabled' : 'disabled';
  const enabledDisabledText = isChecked ? getI18nByKey('Cookies.Enabled') : getI18nByKey('Cookies.Disabled');

  return `
  <div class="cookie-detail-section">
    <div class="cookie-detail-section-head">
      <h3 class="cookie-detail-section-heading">${title}</h3>
        ${
          hasInput
            ? `
            <div class="cookie-detail-input-container">
              <input id="cookies-${section}" data-t="cookie-input-${section}" class="js-cookie-input-${section} visually-hidden cookie-detail-input" ${
                isChecked ? 'checked' : ''
              } type="checkbox">
              <label for="cookies-${section}" class="cookie-detail-label mod-${cookieDescriptionClass}" aria-label="Toggle ${title} Cookies" data-t="cookie-label-${section}">
                <span class="cookie-detail-input-text mod-${cookieDescriptionClass}" style="display: block; margin-top: 4px;">${enabledDisabledText}</span>
              </label>
            </div>`
            : `<span class="cookie-detail-input-text mod-enabled">${getI18nByKey('Cookies.AlwaysEnabled')}</span>`
        }
    </div>
    <p class="cookie-detail-section-text">${text}</p>
  </div>`;
};

const getCookieMarkup = (pageTurn: OnlineMag): string => `
  <form class="cookie-form  js-cookie-form" data-t="cookie-modal">
    <h2 style="margin-top: 0;">${getI18nByKey('Cookies.Title')}</h2>
    <div class="cookie-section">
        <span class="cookie-text">${pageTurn.cookieMessage}</span>

        <div class="cookie-detail">
          ${renderCookieSection({
            title: getI18nByKey('Cookies.Necessary.Title'),
            text: getI18nByKey('Cookies.Necessary.Description'),
            section: 'necessary',
            inputChecked: undefined
          })}

          ${renderCookieSection({
            title: getI18nByKey('Cookies.Performance.Title'),
            text: getI18nByKey('Cookies.Performance.Description'),
            section: 'performance',
            inputChecked: pageTurn.cookiePerformanceAccepted
          })}

          ${renderCookieSection({
            title: getI18nByKey('Cookies.Functional.Title'),
            text: getI18nByKey('Cookies.Functional.Description'),
            section: 'functional',
            inputChecked: pageTurn.cookieFunctionalAccepted
          })}

          ${
            pageTurn.documentAnalytics.facebookCode.length > 0
              ? `${renderCookieSection({
                  title: getI18nByKey('Cookies.Advertising.Title'),
                  text: getI18nByKey('Cookies.Advertising.Description'),
                  section: 'advertising',
                  inputChecked: pageTurn.cookieAdvertisingAccepted
                })}`
              : ``
          }

        </div>

        <div class="cookie-detail-buttons">
          <button onclick="this.form.submitted=this.value;" value="accept" class="cookie-button mod-tertiary js-accept-all" type="submit" data-t="cookie-accept-all" aria-label="Accept All Cookies">${getI18nByKey('Cookies.AcceptAll')}</button>
          <button onclick="this.form.submitted=this.value;" value="reject" class="cookie-button mod-tertiary js-toggle-cookie-settings" data-t="cookie-settings" type="submit" aria-label="Reject All Cookies">${getI18nByKey('Cookies.RejectAll')}</button>
          <button class="cookie-button mod-primary" type="submit" data-t="cookie-accept-custom-cookies" aria-label="Accept Choosen Cookies">${getI18nByKey('Cookies.Confirm')}</button>
        </div>
    </div>
</form>
`;

const setupListeners = (pageTurn: OnlineMag): void => {
  /** @type {HTMLInputElement} */
  const advertisingInput: HTMLInputElement = document.querySelector('.js-cookie-input-advertising');
  /** @type {HTMLInputElement} */
  const functionalInput: HTMLInputElement = document.querySelector('.js-cookie-input-functional');
  /** @type {HTMLInputElement} */
  const performanceInput: HTMLInputElement = document.querySelector('.js-cookie-input-performance');
  /** @type {HTMLFormElement} */
  const form: HTMLFormElement = document.querySelector('.js-cookie-form');

  if (form instanceof HTMLFormElement) {
    form.addEventListener('change', e => {
      if (e.target instanceof HTMLInputElement) {
        if (e.target.type === 'checkbox') {
          const label = e.target.nextElementSibling.firstElementChild;
          label.textContent = e.target.checked ? getI18nByKey('Cookies.Enabled') : getI18nByKey('Cookies.Disabled');
          label.classList.remove(e.target.checked ? 'mod-disabled' : 'mod-enabled');
          label.classList.add(e.target.checked ? 'mod-enabled' : 'mod-disabled');
        }
      }
    });

    form.addEventListener('submit', e => {
      e.preventDefault();

      if (e.target instanceof HTMLFormElement) {
        var advertising = false;
        var functional = false;
        var performance = false;

        if (e.target.submitted === 'accept') {
          advertising = true;
          functional = true;
          performance = true;
        } else if (e.target.submitted === 'reject') {
          advertising = false;
          functional = false;
          performance = false;
        } else {
          advertising = advertisingInput ? advertisingInput.checked : false;
          functional = functionalInput.checked;
          performance = performanceInput.checked;
        }

        pageTurn.updateCookiePreferences({ advertising, functional, performance });

        saveCookiePreferences({
          advertising,
          functional,
          performance
        });

        $().ptibox.close();

        $(`#${pageTurn.elementID}`).trigger('focus');
      }
    });
  }
};

export {
  cookieModal,
  saveCookiePreferences,
  saveCookiePreferencesLocalStorage,
  getCookiePreferences
};
