// @ts-nocheck
/* eslint-disable */
import { delay, getDomElement, getDomElements, getI18nByKey, isFunction } from './utils';

/** @typedef {import('./@types/globals').LightboxOptions} LightboxOptions */
/**
 * @typedef {Object} LightboxItem
 * @property {number} frameHeight
 * @property {number} frameWidth
 * @property {string} href
 * @property {string} htmlButton
 * @property {string} htmlContent
 * @property {any} [infoPosition]
 * @property {any} [orig]
 * @property {string} title
 */

const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]):not([aria-hidden]), select:not([disabled]):not([aria-hidden]), textarea:not([disabled]):not([aria-hidden]), button:not([disabled]):not([aria-hidden]), [tabindex="0"]';
const INERT_ELEMENTS = '.js-doc-container, .js-paging-nav, .intercom-launcher';
const LIGHTBOX_CLASS = 'js-lightbox';
const lightboxID = 'ptibox_inner';
const ariaLiveSection = 'js-aria-live-image';
const captionColourClass = 'js-gallary-caption-colour';
const BODY_LOCK_CLASS = 'mod-lightbox-open';
const IMAGE_REGEX = /\.(jpg|gif|png|bmp|jpeg|jfif)(.*)?$/i;
const CHART_REGEX = /<canvas/i;
const chartClass = 'mod-chart';
const imageClass = 'mod-image';

/**
 * @param {boolean} enableInert if true lock focus within the pop-up
 * @param {boolean} toggleDocumentInert If the pop-up HTML has <script> tags we take off the inert
 * we don't want to ever toggle the document inert attribute.
 * @returns {void}
 */
const toggleInert = (enableInert, toggleDocumentInert) => {
  if (toggleDocumentInert) {
    getDomElements(INERT_ELEMENTS).map(el =>
      enableInert ? el.setAttribute('inert', '') : el.removeAttribute('inert')
    );
  }

  getDomElement(`.${LIGHTBOX_CLASS}`).map(el => {
    if (enableInert) {
      el.removeAttribute('inert');
      el.removeAttribute('aria-hidden', 'false');
    } else {
      el.setAttribute('inert', '');
      el.setAttribute('aria-hidden', 'true');
    }
  });
};

/**
 * @returns {void}
 */
const focusOnFirstItem = () => {
  getDomElement(`.${LIGHTBOX_CLASS}`).map(lightbox =>
    lightbox.querySelector(FOCUSABLE_ELEMENTS)?.focus()
  );
};

/**
 * @param {HTMLElement} document - The document element.
 * @param {HTMLImageElement} image - The currently viewed image.
 * @param {number} padding - The user specified padding.
 * @returns {void}
 */
const setImageSize = (document, image, padding) => {
  const margin = getLightboxMargin(padding);
  const [width, height] = getImageDimensions(document, image, margin);

  $('#ptibox_content-inner').css({ height, width });
};

/**
 * @param {HTMLElement} document - The document element
 * @param {HTMLImageElement} image - Image
 * @param {number} margin - The space around the size to give the box room to breathe
 * @returns {number[]} - A "tuple" containing the image width and height
 */
const getImageDimensions = (document, image, margin) => {
  const { clientWidth, clientHeight } = document;
  const maxWidth = Math.min(clientWidth - margin, image.width);
  const maxHeight = Math.min(clientHeight - margin, image.height);
  const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);

  return [image.width * ratio, image.height * ratio];
};

/**
 * Gets the margin / padding needed for the image sizes. 30 is for a little extra padding
 * @param {number} padding - The user specified padding
 * @returns {number} - A "tuple" containing the image width and height
 */
const getLightboxMargin = padding => padding * 2 + 20 * 2 + 10;

/**
 * @returns {void}
 */
const triggerResize = () => {
  if (typeof Event === 'function') {
    window.dispatchEvent(new Event('resize'));
  } else {
    const evt = window.document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  }
};

/* eslint-disable max-lines, max-lines-per-function, init-declarations, max-lines, max-statements, complexity */
// HERE BE MONSTERS. BE CAREFUL!
(function lightbox($) {
  var elem;

  /** @type {LightboxOptions} */
  var opts;
  var busy = false;
  var playing = false;
  var viewedAllItems = false;
  var toggleInfoOpen = false;
  /** @type {?Element} */
  var activeElement;

  $.fn.ptibox = function ptibox(settings) {
    document.body.classList.add(BODY_LOCK_CLASS);
    settings = $.extend({}, $.fn.ptibox.defaults, settings);

    var matchedGroup = this;

    viewedAllItems = false;

    function _initialize() {
      // This is always undefined.....
      elem = this;
      opts = settings;

      _start();
    }

    function _start() {
      if (busy) {
        return;
      }

      if (opts.callbackOnStart) {
        opts.callbackOnStart();
      }

      opts.itemCurrent = 0;
      opts.itemArray = settings.itemArray.length > 0 ? settings.itemArray : [];

      // If there isn't an item array and we go in here we crash because `elem` is undefined.
      if (settings.itemArray.length === 0) {
        var item = {};

        if (!elem.rel || elem.rel == '') {
          var item = {
            href: elem.href,
            title: elem.title
          };

          if ($(elem).children('img:first').length) {
            item.orig = $(elem).children('img:first');
          }

          opts.itemArray.push(item);
        } else {
          var subGroup = $(matchedGroup).filter(`a[rel=${elem.rel}]`);
          var item = {};

          for (var i = 0; i < subGroup.length; i++) {
            item = {
              href: subGroup[i].href,
              title: subGroup[i].title
            };

            if ($(subGroup[i]).children('img:first').length) {
              item.orig = $(subGroup[i]).children('img:first');
            }

            opts.itemArray.push(item);
          }

          while (opts.itemArray[opts.itemCurrent].href != elem.href) {
            opts.itemCurrent++;
          }
        }
      }

      $('#ptibox_overlay, #ptibox_wrap_inner').show();
      $.fn.ptibox.changeItem();

      $('#ptibox_caption').hide();
      $('#ptibox_wrap').removeClass('mod-hidden');

      if (opts.autoPlay) {
        $.fn.ptibox.toggleSlideshow();
      }
    }

    _initialize();

    return this.off('click');
  };

  // Called from view.js
  $.fn.ptibox.options = function options() {
    return opts;
  };

  $.fn.ptibox.changeItem = function changeItem() {
    $(
      `#ptibox_left, #ptibox_right, .js-image-x-of-y, #ptibox_close, #ptibox_info, #ptibox_play, #ptibox_caption`
    ).hide();
    const isVisible = $('#ptibox_outer').is(':visible');
    const $content = $('#ptibox_content');
    const $button = $('#ptibox_button');
    const $contentInner = $('#ptibox_content-inner');
    const $outer = $('#ptibox_outer');
    const currentItem = opts.itemArray[opts.itemCurrent];

    const { href, htmlContent = '', htmlButton, swapContent } = currentItem;
    const width = currentItem.frameWidth === null ? opts.frameWidth : currentItem.frameWidth;
    const height = currentItem.frameHeight === null ? opts.frameHeight : currentItem.frameHeight;

    opts.currentImage = null;
    window.removeEventListener('resize', $.fn.ptibox.resizeHandler);

    if (opts.itemCurrent === opts.itemArray.length - 1) {
      viewedAllItems = true;
    }

    if (!href && !href.match(IMAGE_REGEX)) {
      $contentInner.css({ height: '', width: '' });
    }

    const containsCustomJS = htmlContent.includes('<script>') && htmlContent.includes('</script>');

    if (swapContent) {
      $button.show();
      $.fn.ptibox.swapContent(currentItem);
    } else if (htmlContent) {
      $content.removeClass(imageClass);

      $button.show();
      $.fn.ptibox.setContent(
        `<div id="ptibox_div">
					<div id="ptibox_div_inner" style="padding: ${opts.margin}px;">
						${htmlContent}
						</div>
					</div>`,
        htmlButton,
        width,
        height
      );
    } else if (href && href.match(/#/)) {
      // TODO NEED TO TEST
      // Inline Content
      var target = window.location.href.split('#')[0];
      target = href.replace(target, '');
      target = target.substr(target.indexOf('#'));

      $.fn.ptibox.setContent(
        `<div id="ptibox_div">
					<div id="ptibox_div_inner" style="padding: ${opts.margin}px;">
						${$(target).html()}
					</div>
				</div>`,
        htmlButton,
        width,
        height
      );
    } else if (href && href.match(IMAGE_REGEX)) {
      // Images
      $content.addClass(imageClass);

      $outer.css({ width: '', height: '' });
      $button.hide();
      $.fn.ptibox.showLoading();

      const upcomingImage = new Image();
      upcomingImage.src = href;
      upcomingImage.onload = function onLoad() {
        opts.currentImage = upcomingImage;
        $.fn.ptibox.hideLoading();
        $.fn.ptibox.setImageContent(upcomingImage);
      };
      // TODO: Nicely handle errors.
      upcomingImage.onerror = function onError() {
        $.fn.ptibox.hideLoading();
      };
      window.addEventListener('resize', $.fn.ptibox.resizeHandler);
    } else if (href && href.match('iframe')) {
      // TODO NEED TO TEST
      // Iframe
      $.fn.ptibox.setContent(
        `<iframe id="ptibox_frame"
								 onload="$.fn.ptibox.showIframe()"
								 name="ptibox_iframe${Math.round(Math.random() * 1000)}"
								 frameborder="0"
								 hspace="0"
								 src="${href}">
								 </iframe>`,
        htmlButton,
        width,
        height
      );
    } else if (href && href.match('callback')) {
      // TODO NEED TO TEST
      // Jsonp
      $.fn.ptibox.showLoading();

      $.ajax({
        url: href,
        dataType: 'jsonp'
      }).then(function gotData(data) {
        if (data.returnStatus !== 1) {
          console.warn(`JSON ERROR: ${data.returnMessage}`);

          return;
        }

        $.fn.ptibox.hideLoading();

        $.fn.ptibox.setContent(
          `<div id="ptibox_div">
						<div id="ptibox_div_inner" style="padding: ${opts.margin}px;">
							${data.returnData}
						</div>
					</div>`,
          htmlButton,
          width,
          height
        );
      });
    } else {
      // TODO NEED TO TEST
      // Regular ajax
      $.fn.ptibox.showLoading();

      $.get(href, function (data) {
        $.fn.ptibox.hideLoading();

        $.fn.ptibox.setContent(
          `<div id="ptibox_div">
						<div id="ptibox_div_inner" style="padding: ${opts.margin}px;">
							${data}
						</div>
					</div>`,
          htmlButton,
          width,
          height
        );
      });
    }

    $('#ptibox_content').css({ 'border-width': `${opts.padding}px` });

    $.fn.ptibox.setBorderAndBGColor(opts);

    $('#ptibox_title').text(opts.title);

    // If we are opening the pop-up and not simply changing items
    // Eg. image galleries or replacing content in comps.
    if (!isVisible) {
      activeElement = document.activeElement;
      // containsCustomJS is only present because Tesco have some custom code that doesn't close
      // the lightbox with $.fn.ptibox.close. Therefore, it doesn't toggle the inert value
      // when we need it to.
      if (containsCustomJS) {
        // Don't add inert to the document if we have custom JS as that custom JS can force
        // the lightbox to close without removing inert on the containing document element.
        toggleInert(true, false);
      } else {
        toggleInert(true, true);
      }
    }
  };

  $.fn.ptibox.resizeHandler = function () {
    const { currentImage, padding } = $.fn.ptibox.options();

    setImageSize(document.documentElement, currentImage, padding);
  };

  /**
   * Uses the document and image size.
   * @param {HTMLImageElement} img - Image
   */
  $.fn.ptibox.setImageContent = function setImageContent(img) {
    busy = true;
    $('#ptibox_content').css({ maxHeight: 'none' });

    const $outerElement = $('#ptibox_outer');
    const $ptiContentInner = $('#ptibox_content-inner');
    const { title } = opts.itemArray[opts.itemCurrent];
    const border = getLightboxMargin(opts.padding);
    const [width, height] = getImageDimensions(document.documentElement, img, border);

    $outerElement.show();

    $ptiContentInner.animate({ opacity: '0' }, 400, () => {
      $ptiContentInner.animate({ height, width }, 400, () => {
        $ptiContentInner
          .hide()
          .empty()
          .append(`<img class="lightbox-image" alt="${title}" src="${img.src}">`)
          .show();
      });
      $ptiContentInner.animate({ opacity: '1' }, 400, $.fn.ptibox.finish);
    });
  };

  $.fn.ptibox.preloadNeighborImages = function preloadNeighborImages() {
    if (opts.itemArray.length - 1 > opts.itemCurrent) {
      var { href } = opts.itemArray[opts.itemCurrent + 1];

      if (href.match(IMAGE_REGEX)) {
        var objNext = new Image();
        objNext.src = href;
      }
    }

    if (opts.itemCurrent > 0) {
      var { href } = opts.itemArray[opts.itemCurrent - 1];

      if (href.match(IMAGE_REGEX)) {
        var objNext = new Image();
        objNext.src = href;
      }
    }
  };

  /**
   * @param {LightboxOptions} options
   * @returns {void}
   */
  $.fn.ptibox.setBorderAndBGColor = function (options) {
    const {
      borderColour = '',
      backgroundColour = '',
      cssClass = '',
      infoTextColour = '',
      padding
    } = options;
    const textColour = infoTextColour || '#000';

    $('#ptibox_content').css({
      borderColor: borderColour,
      backgroundColor: backgroundColour
    });

    $('#ptibox_caption').css({
      left: `${padding}px`,
      width: `calc(100% - ${padding * 2}px)`,
      backgroundColor: borderColour
    });

    // $(`.${captionColourClass}`).css('color', textColour);

    $('#ptibox_wrap').removeClass().addClass(cssClass);
  };

  /**
   * This should only be called for the competition lightbox as it
   * sets max-height opposed to height.
   */
  $.fn.ptibox.swapContent = function swapContent(item) {
    busy = true;

    const $ptiOuter = $('#ptibox_outer');
    const $ptiContent = $('#ptibox_content');
    const $ptiContentInner = $('#ptibox_content-inner');
    const { margin, padding } = opts;
    const paddingTotal = padding * 2 + margin * 2;
    const { frameHeight, frameWidth } = item;

    $ptiContent.css({ 'border-width': `${padding}px` });

    $.fn.ptibox.setBorderAndBGColor(opts);

    $ptiContentInner.css({ maxHeight: `${frameHeight}px` });
    $ptiOuter.css({ width: `${frameWidth + paddingTotal}px` }).fadeIn('normal', $.fn.ptibox.finish);
  };

  $.fn.ptibox.setContent = function setContent(value, button, width, height) {
    busy = true;
    const $ptiOuter = $('#ptibox_outer');
    const $ptiContent = $('#ptibox_content');
    const $ptiContentInner = $('#ptibox_content-inner');
    const $newContent = $(value);
    const isVisible = $ptiOuter.is(':visible');
    const { frameHeight, padding } = opts;
    // The 20px padding comes from the CSS
    const paddingTotal = padding * 2 + 20 * 2;

    $ptiContent.css({ 'border-width': `${padding}px` });

    $.fn.ptibox.setBorderAndBGColor(opts);

    // If we're scrolling through images or keeping the box open and switching content
    if (isVisible) {
      const contentAspectRatio = width / height;
      const containerHeight = $ptiContent.height();
      const containerWidth = $ptiContent.width();
      const newHeight = width > containerWidth ? containerWidth / contentAspectRatio : height;
      // TODO make sure newheight is less than the window size
      // const newerHeight = height > newHeight ? height : newHeight;

      // LOCK THE CONTAINER TO THE CURRENT HEIGHT
      $ptiContentInner.css('height', containerHeight);

      // FADE THE CONTENTS OUT,
      $ptiContentInner.animate({ opacity: '0' }, 400, () => {
        $ptiContentInner.empty().append($newContent);

        // ANIMATE TO THE NEW HEIGHT
        $ptiContentInner.animate({ height: `${newHeight}px` }, 400, () => {
          $ptiContentInner.css('height', 'auto');
        });

        if (opts.callbackOnSetContent) {
          opts.callbackOnSetContent();
        }

        $.fn.ptibox.setButton(button);
        $ptiOuter.css({ width: `${width + paddingTotal}px` }).fadeIn('normal', $.fn.ptibox.finish);
        $ptiContentInner.fadeIn('normal').animate({ opacity: '1' }, 400, () => {});
      });
    } else {
      $ptiContentInner
        .hide()
        .empty()
        .append($newContent)
        .css({ maxHeight: `${height}px` })
        .show({
          complete: () => {
            delay(500).then(() => {
              // If we've already got focus within the lightbox, don't re-focus on the first element.
              // This is useful for search and go to page pop-ups.
              const isNotFocusedInLightbox =
                $(document.activeElement).parents(`.${LIGHTBOX_CLASS}`).length === 0;

              if ($.fn.ptibox.isVisible && isNotFocusedInLightbox) {
                focusOnFirstItem();
              }
            });
          },
          duration: 0
        });

      if (opts.callbackOnSetContent) {
        opts.callbackOnSetContent();
      }

      $.fn.ptibox.setButton(button);

      $ptiOuter.css({ width: `${width + paddingTotal}px` }).fadeIn('normal', $.fn.ptibox.finish);
    }
  };

  $.fn.ptibox.setButton = function setButton(button = '') {
    $('#ptibox_button_inner').html(button);
    $('#ptibox_button').css({ display: button ? 'block' : 'none' });
  };

  $.fn.ptibox.setNavigation = function () {
    $('#ptibox_info')
      .off()
      .on('click', () => {
        const $caption = $('#ptibox_caption');
        const options = {
          complete: triggerResize,
          duration: 0
        };

        if ($caption.is(':visible')) {
          $caption.hide(options);
          toggleInfoOpen = false;
        } else {
          $caption.show(options);
          toggleInfoOpen = true;
        }
      });

    // There's a weird race condition bug.
    // When you open and close the lightbox very quickly
    // opts.itemCurrent can be `undefined` and therefore
    // the navigation arrows show, this code prevents that
    // but doesn't solve the underlying issue.
    opts.itemCurrent = opts.itemCurrent ?? 0;

    if (opts.itemCurrent !== 0) {
      $('#ptibox_left')
        .off()
        .on('click', function (e) {
          e.stopPropagation();
          e.originalEvent.target.blur();

          if (playing) {
            $.fn.ptibox.stopSlideshow();
          }

          opts.itemCurrent--;
          $.fn.ptibox.changeItem();

          return false;
        })
        .show();
    }

    if (opts.itemCurrent !== opts.itemArray.length - 1) {
      $('#ptibox_right')
        .off()
        .on('click', function (e) {
          e.stopPropagation();
          e.originalEvent.target.blur();

          if (playing) {
            $.fn.ptibox.stopSlideshow();
          }

          opts.itemCurrent++;
          $.fn.ptibox.changeItem();

          return false;
        })
        .show();
    }

    if (opts.itemArray.length > 1) {
      $('#ptibox_play')
        .off()
        .on('click', function (e) {
          e.stopPropagation();

          $.fn.ptibox.toggleSlideshow();

          return false;
        })
        .show();
    }
  };

  $.fn.ptibox.toggleSlideshow = function () {
    $(`.${ariaLiveSection}`).text(playing ? 'stopped slideshow' : 'started slideshow');
    playing ? $.fn.ptibox.stopSlideshow() : $.fn.ptibox.startSlideshow();
  };

  var intInterval;

  $.fn.ptibox.startSlideshow = function () {
    if (!playing) {
      playing = !playing;

      $('.js-toggle-slideshow').attr('xlink:href', '#svg-pause');

      intInterval = window.setInterval(function () {
        if (opts.itemCurrent == opts.itemArray.length - 1) {
          if (opts.continuous) {
            opts.itemCurrent = 0;
          } else {
            $.fn.ptibox.stopSlideshow();
            $.fn.ptibox.close();

            // Prevent from dropping through to .changeItem()
            return;
          }
        } else {
          opts.itemCurrent++;
        }

        $.fn.ptibox.changeItem();
      }, opts.interval * 1000);
    }
  };

  $.fn.ptibox.stopSlideshow = function () {
    if (playing) {
      playing = !playing;

      $('.js-toggle-slideshow').attr('xlink:href', '#svg-play');
      $(`.${ariaLiveSection}`).text('');

      intInterval = window.clearInterval(intInterval);
    }
  };

  $.fn.ptibox.finish = function () {
    $.fn.ptibox.setNavigation();
    $.fn.ptibox.preloadNeighborImages();
    $.fn.ptibox.hideLoading();

    $(document)
      .off('keydown.box')
      .on('keydown.box', function (e) {

  // if body does not have class mod-lightbox-open, close the modal
    if (!document.body.classList.contains(BODY_LOCK_CLASS)) {
      $.fn.ptibox.stopSlideshow();
      $.fn.ptibox.close();
      return;
    }



        var keyCode = e.keyCode || e.which;
        var arrow = { esc: 27, left: 37, up: 38, right: 39, down: 40 };

        if ($('#ptimessage_outer').is(':visible') === false) {
          if (keyCode == arrow.esc && opts.hideOnEscape) {
            $(document).off('keydown.box');
            $.fn.ptibox.stopSlideshow();
            $.fn.ptibox.close();
          } else if (keyCode == arrow.left && opts.itemCurrent != 0) {
            $(document).off('keydown.box');
            opts.itemCurrent--;
            $.fn.ptibox.changeItem();
          } else if (keyCode == arrow.right && opts.itemCurrent != opts.itemArray.length - 1) {
            $(document).off('keydown.box');
            opts.itemCurrent++;
            $.fn.ptibox.changeItem();
          }
        }
      });

    if (opts.hideOnContentClick) {
      $('#ptibox_wrap').on('click', function (e) {
        e.stopPropagation();
        $.fn.ptibox.stopSlideshow();
        $.fn.ptibox.close();
      });
    }

    if (opts.hideOnOverlayClick) {
      $('#ptibox_wrap_inner')
        .off('click')
        .on('click', function (e) {
          e.stopPropagation();

          if (e.target.id === 'ptibox_wrap_inner') {
            $.fn.ptibox.stopSlideshow();
            $.fn.ptibox.close();
          }
        });
    } else {
      $('#ptibox_wrap_inner').off('click');
    }

    $('#ptibox_close')
      .off()
      .on('click', function (e) {
        e.stopPropagation();
        $.fn.ptibox.stopSlideshow();
        $.fn.ptibox.close();
      });

    opts.showCloseButton ? $('#ptibox_close').show() : $('#ptibox_close').hide();
    opts.showInfo == 0 ? $('#ptibox_info').show() : $('#ptibox_info').hide();

    opts.showPlayButton && opts.itemArray.length > 1
      ? $('#ptibox_play').show()
      : $('#ptibox_play').hide();

    const currentItem = opts.itemArray[opts.itemCurrent];
    const isImageGallery = Boolean(
      currentItem && currentItem.href && currentItem.href.match(IMAGE_REGEX)
    );
    const isChart = Boolean(
      currentItem && currentItem.htmlContent && currentItem.htmlContent.match(CHART_REGEX)
    );

    if (isImageGallery || isChart) {
      const { infoPosition, title } = currentItem;
      const topOrBottom = infoPosition == 0 ? 'top' : 'bottom';
      const $caption = $('#ptibox_caption');
      const textColour = opts.infoTextColour || '#000';

      $caption.removeClass(chartClass).removeClass(imageClass);
      $caption.addClass(isChart ? chartClass : imageClass);
      $('.lightbox-nav-buttons.mod-prev').css('left', `${opts.padding}px`);
      $('.lightbox-nav-buttons.mod-next').css('right', `${opts.padding}px`);

      if (opts.showInfo !== 2) {
        const $lightboxContent = $('.lightbox-content');
        const topClass = 'mod-top';
        const bottomClass = 'mod-bottom';
        const captionMarkup =
          isImageGallery && opts.itemArray.length > 1
            ? `<span class="${captionColourClass}">${getI18nByKey(
                'Gallery.ImageNumberOfImages',
                opts.itemCurrent + 1,
                opts.itemArray.length
              )}</span>${
                title.length > 0
                  ? `<br><span id="ptibox_caption_desc" class="${captionColourClass}">${title}</span>`
                  : ``
              }`
            : `<span id="ptibox_caption_desc" class="${captionColourClass}">${title}</span>`;

        $lightboxContent.css({ display: isChart ? 'flex' : 'block' });
        $('#ptibox_caption_inner').html(captionMarkup);
        $(`.${captionColourClass}`).css('color', textColour);
        $(`.${ariaLiveSection}`).text(`
          ${getI18nByKey(
            'Gallery.DisplayingImageNumberOfImages',
            opts.itemCurrent + 1,
            opts.itemArray.length
          )}
          ${getI18nByKey('Gallery.ImageDescription', title)}`);
        // $(`.${ariaLiveSection}`).text(
        //   `Displaying image ${opts.itemCurrent + 1} of ${
        //     opts.itemArray.length
        //   }. Image description ${title}`
        // );

        $caption.css({ top: '', bottom: '' }).css(topOrBottom, `${opts.padding}px`);

        if (currentItem.infoPosition == 0) {
          // Top
          $caption.addClass(topClass).removeClass(bottomClass);
          $lightboxContent.css({ 'flex-direction': 'column' });
        } else {
          // Bottom
          $caption.addClass(bottomClass).removeClass(topClass);
          $lightboxContent.css({ 'flex-direction': 'column-reverse' });
        }

        if (title.length === 0) {
          $caption.hide();
        }
      }

      // Always show
      if (opts.showInfo == 1 || toggleInfoOpen) {
        $caption.fadeIn();
      }
    }

    if (opts.overlayShow) {
      $('select', $('#ptibox_content-inner')).css('visibility', 'visible');
    }

    if (opts.callbackOnShow) {
      opts.callbackOnShow();
    }

    busy = false;
  };

  $.fn.ptibox.showIframe = function () {
    $.fn.ptibox.hideLoading();
    $('#ptibox_frame').show();
  };

  $.fn.ptibox.showLoading = function () {
    $('#ptibox_loading').show().on('click', $.fn.ptibox.close);
  };

  $.fn.ptibox.hideLoading = function () {
    $('#ptibox_loading').hide();
  };

  $.fn.ptibox.isVisible = function () {
    return $('#ptibox_outer').is(':visible');
  };

  $.fn.ptibox.close = function (callbackOnClose, suppressFocusActiveElement = false) {
    $('#ptibox_title').text('');
    $('#ptibox_caption_inner').html('');
    $(`.${ariaLiveSection}`).text('');

    // Regardless of any custom JS always add back inert on the lightbox and remove inert from the
    // document container.
    toggleInert(false, true);

    delay(40).then(() => {
      // Revert focus back to the original element...
      if (!suppressFocusActiveElement && activeElement && activeElement.focus) {
        activeElement.focus();
      }
    });

    if (opts === undefined) {
      return;
    }

    document.body.classList.remove(BODY_LOCK_CLASS);
    busy = true;
    toggleInfoOpen = false;
    // A max height is set when the images load. Remove this ready for non-image based lightboxes
    $('.lightbox-content').css({ maxHeight: '' });

    window.removeEventListener('resize', $.fn.ptibox.resizeHandler);

    if (isFunction(opts.callbackOnBeforeClose) && opts) {
      opts.callbackOnBeforeClose();
    }

    $.fn.ptibox.stopSlideshow();

    $('.lightbox-content').scrollTop(0);
    $('#ptibox_wrap').addClass('mod-hidden');

    if (callbackOnClose) {
      opts.callbackOnClose = callbackOnClose;
    }

    $(document).off('keydown.box');

    $('#ptibox_overlay, #ptibox_inner, #ptibox_close, #ptibox_info, #ptibox_play').off();

    if (opts.hideOnContentClick) {
      $('#ptibox_wrap').off();
    }

    $(`
      #ptibox_close,
      #ptibox_outer,
      #ptibox_wrap_inner,
      #ptibox_info,
      #ptibox_play,
      .ptibox_loading,
      #ptibox_left,
      #ptibox_right,
      #ptibox_title`).hide();

    var __cleanup = function () {
      $('#ptibox_overlay, #ptibox_outer').hide();
      // Remove the max-height property as gallery items don't use max-height
      $('#ptibox_content-inner').empty().css({ maxHeight: '' });

      if (opts.callbackOnViewedAllItems && viewedAllItems) {
        // Has completed at leat one interation of items
        viewedAllItems = false;
        opts.callbackOnViewedAllItems();
      }

      if (isFunction(opts.callbackOnClose)) {
        opts.callbackOnClose();
      }

      busy = false;
    };

    $('#ptibox_div').scrollTop(0);

    if ($('#ptibox_outer').is(':visible') !== false) {
      if (opts.zoomSpeedOut > 0 && opts.itemArray[opts.itemCurrent].orig !== undefined) {
        var orig_item = opts.itemArray[opts.itemCurrent].orig;

        var itemOpts = {
          // TODO - CAN I REMOVE THIS?
          width: $(orig_item).width(),
          height: $(orig_item).height()
        };

        if (opts.zoomOpacity) {
          itemOpts.opacity = 'hide';
        }

        $('#ptibox_outer')
          .stop(false, true)
          .animate(itemOpts, opts.zoomSpeedOut, opts.easingOut, __cleanup);
      } else {
        $('#ptibox_outer').stop(false, true).fadeOut('slow', __cleanup);
      }
    } else {
      __cleanup();
    }

    return false;
  };

  $.fn.ptibox.showItem = function (objItem) {
    // Clear out the current items and show the one whish is passed.
    opts.itemArray = [objItem];
    $.fn.ptibox.changeItem();
    $.fn.ptibox.hideLoading();
  };

  // New Spinner
  // <div class="ptibox-loading-spinner">
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  // </div>

  $.fn.ptibox.build = function () {
    $(`
      <div class="lightbox-loading" id="ptibox_loading">
        <div class="ptibox_loading_spinner"></div>
      </div>

      <div class="lightbox-overlay" id="ptibox_overlay"></div>
        <div class="lightbox-wrap mod-hidden" id="ptibox_wrap">
        <div id="ptibox_wrap_inner">

        <div class="${ariaLiveSection} visually-hidden" aria-live="polite"></div>

          <div class="lightbox-outer ${LIGHTBOX_CLASS}" id="ptibox_outer" aria-labelledby="ptibox_title" role="dialog" aria-modal="true" inert aria-hidden="true">
            <div class="lightbox-inner" id="${lightboxID}">

              <button class="lightbox-top-buttons mod-close" id="ptibox_close" data-t="modal-close">
                <svg class="lightbox-top-svg no-pointer">
                  <use xlink:href="#svg-close" fill="#000"></use>
                </svg>
                <span class="visually-hidden">${getI18nByKey('Gallery.Buttons.Close')}</span>
              </button>

              <button class="lightbox-top-buttons mod-info" id="ptibox_info" data-t="image-gal-info">
                <svg class="lightbox-top-svg no-pointer">
                  <use xlink:href="#svg-info" fill="#000"></use>
                </svg>
                <span class="visually-hidden">${getI18nByKey('Gallery.Buttons.Information')}</span>
              </button>

              <button class="lightbox-top-buttons mod-play" id="ptibox_play" data-t="image-gal-play">
                <svg class="lightbox-top-svg no-pointer">
                  <use class="js-toggle-slideshow" xlink:href="#svg-play" fill="#000"></use>
                </svg>
                <span class="visually-hidden">${getI18nByKey(
                  'Gallery.Buttons.StartSlideshow'
                )}</span>
              </button>

              <div id="ptibox_left">
                <button class="lightbox-nav-buttons mod-prev" data-t="image-gal-prev">
                  <svg class="lightbox-nav-svg no-pointer">
                    <use xlink:href="#svg-arrow" fill="#000"></use>
                  </svg>
                  <span class="visually-hidden">${getI18nByKey(
                    'Gallery.Buttons.PreviousImage'
                  )}</span>
                </button>
              </div>

              <div id="ptibox_right">
                <button class="lightbox-nav-buttons mod-next" data-t="image-gal-next">
                  <svg class="lightbox-nav-svg no-pointer">
                    <use xlink:href="#svg-arrow" fill="#000"></use>
                  </svg>
                  <span class="visually-hidden">${getI18nByKey('Gallery.Buttons.NextImage')}</span>
                </button>
              </div>

              <div class="lightbox-content" id="ptibox_content" data-t="lightbox">
                <div id="ptibox_caption" data-t="image-gal-caption">
                  <div id="ptibox_caption_inner"></div>
                </div>

                <div id="ptibox_content-inner"></div>

                <div id="ptibox_button">
                  <div class="lightbox-button-inner" id="ptibox_button_inner"></div>
                </div>
              </div>

              <div id="ptibox_title" class="visually-hidden"></div>
            </div>
          </div>

        </div>
      </div>
		</div>`).appendTo('body');
  };

  $.fn.ptibox.defaults = {
    padding: 10,
    borderColour: '',
    margin: 20,
    backgroundColour: '',
    cssClass: '',
    zoomOpacity: false,
    zoomSpeedOut: 0,
    zoomSpeedChange: 300,
    easingIn: 'swing',
    easingOut: 'swing',
    easingChange: 'swing',
    frameWidth: 500,
    frameHeight: 400,
    overlayShow: true,
    overlayOpacity: 0.8,
    hideOnContentClick: false,
    itemArray: [],
    autoPlay: false,
    interval: 3 /* Pause between images (in seconds) */,
    continuous: false /* If true goes to 1st image after last */,
    showInfo: 0 /* OnClick = 0, Always = 1, Never = 2 */,
    infoTextColour: '',
    callbackOnStart: null,
    callbackOnShow: null,
    callbackOnSetContent: null,
    callbackOnBeforeClose: null,
    callbackOnClose: null,
    callbackOnViewedAllItems: null,
    showPlayButton: true,
    showCloseButton: true,
    hideOnEscape: true,
    hideOnOverlayClick: true
  };

  $.fn.ptibox.itemDefaults = function () {
    return {
      htmlContent: '',
      href: '',
      title: '',
      orig: null,
      frameWidth: 500,
      frameHeight: 400,
      infoPosition: 0
    };
  };

  /**
   * @param {string} htmlContent
   * @param {string} htmlButton
   * @param {string} href
   * @param {string} title
   * @param {?HTMLImageElement} orig .
   * @param {number} frameWidth
   * @param {number} frameHeight
   * @param {string|number} infoPosition
   * @param {boolean} swapContent
   * @returns {any}
   */
  $.fn.ptibox.generateItemObject = function (
    htmlContent,
    htmlButton,
    href,
    title,
    orig,
    frameWidth,
    frameHeight,
    infoPosition,
    swapContent = false
  ) {
    return {
      htmlContent,
      htmlButton,
      href,
      title,
      orig,
      frameWidth,
      frameHeight,
      infoPosition,
      swapContent
    };
  };

  $($.fn.ptibox.build);
})($);
