import { getDomElement, getDomElements, htmlEncode, makeHtml, removeElement } from './utils';
import { DESIGNER_MESSAGE_EVENTS } from './constants';

import type { OnlineMag } from './@types/pagetiger';

const { REMOVE_HIGHLIGHT_PANEL, HIGHLIGHT_PANEL } = DESIGNER_MESSAGE_EVENTS;

type DesignerMessageEvent =
  | { type: typeof REMOVE_HIGHLIGHT_PANEL }
  | {
      type: typeof HIGHLIGHT_PANEL;
      panelId: number;
      highlightColour: string;
    };

const selector = 'js-panel-hightlighter';

function makeHighlightPanel({ height, width, left, top }: DOMRect, panelColour: string) {
  return makeHtml(`
    <div
      class="panel-hightlighter ${selector}"
      style="background-color: ${htmlEncode(panelColour)};
      width: ${width}px;
      height: ${height}px;
      left: ${left}px;
      top: ${top}px;">
    </div>`);
}

/**
 * Handle postMessage from Designer for Accessility ReadingOrder
 * Listener used to highlight the panel corresponding to interaction
 * from within Designer
 */
function handlePanelHighlightMessage(panelID: number, panelColour: string): void {
  // Grab the details for the currently hovered layer/element by its panelID
  getDomElement(`[data-panel-id="${panelID}"], [data-id="${panelID}"]`).map(
    (panel: HTMLElement) => {
      const highlightPanel = makeHighlightPanel(panel.getBoundingClientRect(), panelColour);

      document.body.append(highlightPanel);
    }
  );
}

function removePanelHighlight() {
  getDomElements(`.${selector}`).map(removeElement);
}

const setupDesignerMessaging = (pageTurn: OnlineMag) => {
  const adminDomain = pageTurn.host.replace('view.', 'admin.');
  window.addEventListener('message', (e: MessageEvent<DesignerMessageEvent>) => {
    if (!e.origin.startsWith(`https://${adminDomain}`)) {
      return;
    }

    switch (e.data.type) {
      case HIGHLIGHT_PANEL:
        return handlePanelHighlightMessage(e.data.panelId, e.data.highlightColour);

      case REMOVE_HIGHLIGHT_PANEL:
        return removePanelHighlight();
    }
  });
};

export { setupDesignerMessaging };
