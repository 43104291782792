// @ts-check
/* eslint-disable camelcase */
import { isFunction } from './utils';
import { ANALYTICS_EVENTS } from './constants';
import type { DocumentAnalytics } from './@types/pagetiger';
import type { AnalyticsEvents } from './constants';

class Analytics {
  readonly gtag: Gtag.Gtag;
  readonly facebookPixel: facebook.Pixel.Event;
  readonly documentAnalytics: DocumentAnalytics;

  /**
   * A tracking solution for pinging events to Google Analytics
   * and FB Analytics (if enabled).
   * https://developers.facebook.com/docs/facebook-pixel/reference
   * https://developers.google.com/gtagjs/reference/api
   * @param {DocumentAnalytics} documentAnalytics
   * @param {Gtag.Gtag|undefined} gtag
   * @param {facebook.Pixel.Event} facebookPixel
   */
  constructor(
    documentAnalytics: DocumentAnalytics,
    gtag: Gtag.Gtag | undefined,
    facebookPixel: facebook.Pixel.Event | undefined
  ) {
    this.documentAnalytics = documentAnalytics;
    this.gtag = gtag;
    this.facebookPixel = facebookPixel;
  }

  gaActive() {
    return isFunction(this.gtag) && Boolean(this.documentAnalytics.googleAnalyticsCode);
  }

  fbActive() {
    return isFunction(this.facebookPixel) && Boolean(this.documentAnalytics.facebookCode);
  }

  toggleFacebookConsent(permission: boolean): void {
    if (this.fbActive()) {
      this.facebookPixel('consent', `${permission ? 'grant' : 'revoke'}`);
    }
  }

  toggleGoogleConsent(permission: boolean): void {
    if (this.gaActive()) {
      this.gtag('consent', 'update', {
        analytics_storage: permission ? 'granted' : 'denied'
      });
    }
  }

  firePageView(pageTitle: string, pagePath: string): void {
    const { hostname, protocol } = document.location;

    if (this.gaActive()) {
      this.gtag('event', 'page_view', {
        page_location: `${protocol}//${hostname}${pagePath}`,
        page_path: pagePath,
        page_title: pageTitle
      });
    }

    if (this.fbActive()) {
      this.facebookPixel('track', 'PageView', {
        content_name: pageTitle
      });
    }
  }

  fireEvent(eventAction: AnalyticsEvents, eventName: string, eventType: string): void {
    const [fbEventType, fbEventAction] =
      eventAction === ANALYTICS_EVENTS.SUBMITTED
        ? ['trackCustom', eventAction]
        : ['track', 'ViewContent'];

    if (this.gaActive()) {
      this.gtag('event', eventAction, {
        event_category: eventType,
        event_label: eventName
      });
    }

    if (this.fbActive()) {
      this.facebookPixel(fbEventType, fbEventAction, {
        content_category: eventType,
        content_name: eventName
      });
    }
  }

  fireShareEvent(
    sharedPlatform: 'twitter' | 'facebook' | 'linkedin' | 'native',
    sharedPage: string
  ): void {
    const shareMethod =
      sharedPlatform === 'native' ? `Share natively` : `Share on ${sharedPlatform}`;

    if (this.gaActive()) {
      this.gtag('event', 'Share', {
        event_category: sharedPage,
        event_label: shareMethod
      });
    }

    if (this.fbActive()) {
      this.facebookPixel('trackCustom', 'Share', {
        content_type: 'page',
        item_id: sharedPage,
        method: shareMethod
      });
    }
  }
}

export { Analytics };
