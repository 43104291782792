/* eslint-disable complexity, no-shadow, max-statements */
import type {
  ToolbarSettings,
  PopUpButtonOrLink,
  PagingButton,
  ToolbarItem,
  ToolbarLink,
  ToolbarButton
} from './@types/widgets';
import type { ToolbarMenuItem } from './toolbar';

import { getModuleID, htmlEncode } from './utils';
import { MANDATORY_CLASS, MODULES, MODULE_SUB_TYPES, NAV_ARROW_CLASS } from './constants';
import { OnlineMagPageVideo } from './interactivity/video';

const toDataAttr = (str: string): string => str.toLowerCase().replace(/ /g, '-');

const renderPopUpItemButton = ({ action, extraClasses = '', text }: PopUpButtonOrLink): string => `
  <button
    class="toolbar-popup-list-item-button  ${extraClasses ? `${htmlEncode(extraClasses)}` : ``}"
    type="button"
    data-action="${htmlEncode(action)}"
    data-t="menu-item-${htmlEncode(toDataAttr(text))}">
    ${htmlEncode(text)}
  </button>`;

const renderPopUpItemLink = ({ action, extraClasses = '', text }: PopUpButtonOrLink): string => `
  <a
    class="toolbar-popup-list-item-button ${extraClasses ? `${htmlEncode(extraClasses)}` : ``}"
    href="${action}"
    data-t="menu-item-${htmlEncode(toDataAttr(text))}">
    ${htmlEncode(text)}
  </a>`;

const renderToolbarMenu = ({
  buttonMarkup,
  dropdownOptions,
  extraClasses
}: {
  buttonMarkup: string;
  dropdownOptions: ToolbarMenuItem[];
  extraClasses: string;
}): string => {
  const encodedClasses = htmlEncode(extraClasses);
  const items = dropdownOptions
    .map(
      item =>
        `<li class="toolbar-popup-list-item">
          ${item.type === 'link' ? renderPopUpItemLink(item) : renderPopUpItemButton(item)}
        </li>`
    )
    .join('');

  return `
    <div class="toolbar-menu">
    ${buttonMarkup}
    <div class="toolbar-popup ${encodedClasses}" data-t="toolbar-menu" aria-hidden="true">
      <div class="toolbar-popup-arrow ${encodedClasses}"></div>
      <ul class="toolbar-popup-list">
        ${items}
      </ul>
    </div>
  </div>
`;
};

const renderToolbarButton = ({ action, extraClasses = '', svgID, text }: ToolbarButton) => `
  <button class="toolbar-button ${htmlEncode(extraClasses)}"
          type="button"
          aria-hidden="true"
          data-action="${htmlEncode(action)}"
          data-t="toolbar-arrow"
          data-tooltip="${htmlEncode(text)}">
    <span class="no-pointer visually-hidden">
      ${htmlEncode(text)}
    </span>
    <svg class="mobile-menu-button-svg no-pointer">
      <use xlink:href="#svg-${htmlEncode(svgID)}"></use>
    </svg>
  </button>`;

const renderToolbarLink = ({ extraClasses, href, newWindow, svgID, text }: ToolbarLink): string => {
  return `
    <a class="toolbar-button ${htmlEncode(extraClasses) || ``}"
        ${newWindow ? `target="_blank" rel="noopener noreferrer"` : ``}
        href="${href}"
        data-tooltip="${htmlEncode(text)}">
    <span class="no-pointer visually-hidden">${htmlEncode(text)}</span>
    <svg class="mobile-menu-button-svg no-pointer">
      <use xlink:href="#svg-${htmlEncode(svgID)}"></use>
    </svg>
  </a>`;
};

const renderToolbarLogo = ({ extraClasses, svgID, text }) => `
  <div class="toolbar-button ${htmlEncode(extraClasses) || ``}"
        data-tooltip="${htmlEncode(text)}">
    <span class="no-pointer visually-hidden">${htmlEncode(text)}</span>
    <svg class="mobile-menu-button-svg no-pointer">
      <use xlink:href="#svg-${htmlEncode(svgID)}"></use>
    </svg>
  </div>`;

const renderToolbarItem = (toolbarItem: ToolbarItem): string => {
  switch (toolbarItem.type) {
    case 'markup':
      return toolbarItem.markup;
    case 'link':
      return renderToolbarLink(toolbarItem);
    case 'button':
      return renderToolbarButton(toolbarItem);
    case 'menu':
      return renderToolbarMenu(toolbarItem);
  }
};

const renderToolBar = ({
  accountPopup,
  navClass,
  position,
  pageTigerLogo,
  toolbarPlacement,
  toolbarLeft,
  toolbarCenter
}: ToolbarSettings): string => `
    <nav
        class="toolbar ${htmlEncode(navClass)}"
        aria-label="${htmlEncode(position)} navigation"
        data-position="${htmlEncode(position)}"
        data-toolbar-placement="${htmlEncode(toolbarPlacement)}">
      <div class="toolbar-left">
       ${toolbarLeft.map(renderToolbarItem).join(' ')}
      </div>

      <div class="toolbar-center">
        ${toolbarCenter.map(renderToolbarItem).join(' ')}
      </div>

      <div class="toolbar-right">
        ${accountPopup}
        ${pageTigerLogo}
      </div>
    </nav>
  `;

/**
 * @param {PagingButton} pagingButton
 * @returns {string}
 */
const pagingButton = ({
  action,
  ariaHidden,
  extraClasses,
  modClass,
  text
}: PagingButton): string => `
  <button
    class="side-nav ${htmlEncode(modClass)} ${htmlEncode(extraClasses)} ${NAV_ARROW_CLASS}"
    data-action="${htmlEncode(action)}"
    data-t="side-nav-${htmlEncode(modClass)}"
    type="button"
    aria-hidden="${htmlEncode(ariaHidden)}">
    <span class="visually-hidden">${htmlEncode(text)}</span>
    <svg class="side-nav-svg ${htmlEncode(modClass)} no-pointer">
      <use xlink:href="#svg-arrow"></use>
    </svg>
  </button>
`;

type Button = {
  item: any;
  style: string;
  noGlowClass: string;
  isOffPage: boolean;
};

const renderInteractivityButton = ({ item, style, noGlowClass, isOffPage }: Button) => {
  const tabIndexAttr = isOffPage ? `tabindex="-1"` : ``;
  const encodedId = htmlEncode(item.id);
  const mandatoryClass = item.mandatory && !item.isComplete ? MANDATORY_CLASS : ``;
  const elementId = htmlEncode(getModuleID(item));

  return `
    <button id="${elementId}"
            class="interactivity-item ${htmlEncode(noGlowClass)} ${htmlEncode(mandatoryClass)}"
            ${tabIndexAttr}
            data-interactivity-action="ptiLinkClicked"
            data-t="interactivity-id-${encodedId}"
            data-id="${encodedId}"
            data-module-type="${htmlEncode(item.moduleType)}"
            style="${htmlEncode(style)}"
            title="${htmlEncode(item.tooltip)}">
      <span class="visually-hidden">${htmlEncode(item.onPageName)}</span>
    </button>`;
};

type Link = {
  item: any;
  isOffPage: boolean;
  mandatoryClass: string;
  newTab: string;
  noGlowClass: string;
  style: string;
};

const renderInteractivityLink = ({
  item,
  isOffPage,
  mandatoryClass,
  newTab,
  noGlowClass,
  style
}: Link) => {
  const tabIndexAttr = isOffPage ? `tabindex="-1"` : ``;
  const encodedId = htmlEncode(item.id);
  const moduleType = htmlEncode(item.moduleType);

  return `
    <a id="interactive-module-${moduleType}-${encodedId}"
       class="interactivity-item ${htmlEncode(mandatoryClass)} ${htmlEncode(
    noGlowClass
  )} js-link-clicked"
       data-t="interactivity-id-${encodedId}"
       ${htmlEncode(newTab)}
       data-id="${encodedId}"
       data-module-type="${moduleType}"
       onclick="clickLinkHandler(event)"
       href="${item.url}"
       style="${htmlEncode(style)}"
       ${tabIndexAttr}
       title="${htmlEncode(item.tooltip)}">
      <span class="visually-hidden">${htmlEncode(item.onPageName)}</span>
    </a>`;
};

const renderVideoElement = ({
  video,
  scale,
  pageIndex,
  style
}: {
  video: OnlineMagPageVideo;
  scale: number;
  pageIndex: number;
  style: string;
}) => {
  const isIphone = navigator.platform === 'iPhone';
  const shouldBeMuted = video.muted || (video.autoPlay && isIphone);
  const shouldPlayInline = video.playsInline || (video.autoPlay && isIphone);
  const encodedGuid = htmlEncode(video.moduleGuid);
  const encodedId = htmlEncode(video.id);

  return `<video
    id="video${encodedId}"
    class="video-on-page"
    disablePictureInPicture
    data-module-guid="${encodedGuid}"
    data-video-id="${encodedId}"
    data-page-index="${pageIndex}"
    data-t="${encodedGuid}"
    style="${htmlEncode(style)}"
    preload="${video.autoPlay ? 'auto' : 'metadata'}"
    ${video.autoPlay && video.subType != 'Play video file in popup' ? 'autoplay' : ''}
    ${video.showControls ? 'controls' : ''}
    ${video.loop ? 'loop' : ''}
    ${shouldBeMuted ? 'muted' : ''}
    ${shouldPlayInline ? 'playsinline' : ''}
    ${video.posterImageUrl ? `poster="${video.posterImageUrl}"` : ''}
    controlsList="${video.preventDownload ? 'nodownload' : ''}"
    crossorigin="use-credentials"
    height="${Math.round(video.height * scale)}"
    width="${Math.round(video.width * scale)}">
      <source src="${video.urlH264}" type="video/mp4">
      ${
        video.urlVtt && video.showSubtitles
          ? `<track label="English" kind="subtitles" srclang="en" src="${htmlEncode(
              video.urlVtt
            )}" crossorigin="use-credentials" default>`
          : ''
      }
  </video>`;
};

const renderChartElement = ({
  chart,
  pageIndex,
  style
}: {
  chart: any;
  pageIndex: number;
  style: string;
}): string => {
  const isOnPage = chart.subType === MODULE_SUB_TYPES.CHART;
  const encodedId = htmlEncode(chart.id);
  const extraClass = isOnPage ? 'js-chart-element' : '';
  const backgroundColour =
    isOnPage && chart.backgroundColour !== ''
      ? `background-color: ${htmlEncode(chart.backgroundColour)};`
      : '';
  const extraAttr = isOnPage
    ? `data-chart-init="false"
        data-chart-guid="${htmlEncode(chart.moduleGuid)}" data-page-index="${pageIndex}"`
    : '';

  return `
    <div class="chart" data-t="interactivity-chart-${encodedId}"
      style="${htmlEncode(style)} ${backgroundColour}"
      title="${htmlEncode(chart.tooltip)}">
      <canvas class="chart-canvas js-chart-${encodedId} ${extraClass}" ${extraAttr} role="img"
      aria-label="${htmlEncode(chart.chartData.options.title.text)}"></canvas>
    </div>`;
};

const getPollModuleMarkUp = (polls: any[], pageHeight: number, pageWidth: number): string => {
  const pollTitle = polls[0]?.pollTitle ?? '';
  const pollItems = polls.reduce((markup, poll) => {
    const scale = pageWidth / 800;
    const left = ((poll.posLeft * scale) / pageWidth) * 100;
    const top = ((poll.posTop * scale) / pageHeight) * 100;
    const width = ((poll.width * scale) / pageWidth) * 100;
    const height = ((poll.height * scale) / pageHeight) * 100;
    const style = `position: absolute; display: block; left: ${left}%; top: ${top}%; height: ${height}%; width: ${width}%;`;
    const isOffPage = left >= 100 || top >= 100 || top + height <= 0 || left + width <= 0;

    return `${markup}${renderInteractivityButton({
      item: poll,
      noGlowClass: '',
      style,
      isOffPage
    })}`;
  }, '');

  return pollItems.length > 0
    ? `
    <div role="group" aria-label="${htmlEncode(pollTitle)}">
      ${pollItems}
    </div>`
    : ``;
};

const getModuleMarkUp = (
  mod: any,
  pageHeight: number,
  pageWidth: number,
  pageIndex: number,
  clickableStyle: string
): string => {
  const scale = pageWidth / 800;
  const left = ((mod.posLeft * scale) / pageWidth) * 100;
  const top = ((mod.posTop * scale) / pageHeight) * 100;
  const width = ((mod.width * scale) / pageWidth) * 100;
  const height = ((mod.height * scale) / pageHeight) * 100;
  const style = `position: absolute; display: block; left: ${left}%; top: ${top}%; height: ${height}%; width: ${width}%;`;
  const mandatoryClass = mod.mandatory && !mod.isComplete ? MANDATORY_CLASS : '';
  const noGlowClass = clickableStyle === 'none' ? 'mod-hide' : '';
  const isOffPage = left >= 100 || top >= 100 || top + height <= 0 || left + width <= 0;

  if (mod.moduleType === MODULES.CHART && mod.subType === MODULE_SUB_TYPES.CHART) {
    return renderChartElement({ chart: mod, pageIndex, style });
  } else if (mod.moduleType === MODULES.LINK) {
    const urlContainsJS = mod.url.toLowerCase().trim().startsWith('javascript:');
    const newTab =
      mod.target === '_blank' && !urlContainsJS ? 'target="_blank" rel="noopener noreferrer"' : '';

    return renderInteractivityLink({
      item: mod,
      isOffPage,
      mandatoryClass,
      newTab,
      noGlowClass,
      style
    });
  } else if (mod.moduleType === MODULES.VIDEO && mod.subType === MODULE_SUB_TYPES.VIDEO) {
    const borderColour = mod.popupBorderColour === '' ? 'transparent' : mod.popupBorderColour;
    const borderWidth = Math.min(80, mod.popupBorderSize);
    const videoStyle = `${style} border-width: ${borderWidth}px; border-color: ${borderColour};`;
    return renderVideoElement({ pageIndex, scale, style: videoStyle, video: mod });
  }

  return renderInteractivityButton({ item: mod, noGlowClass, style, isOffPage });
};

/**
 * We set SameSite=None to ensure that the analytics will work when
 * a customer embeds their document in an iFrame
 */
const googleAnalyticsMarkUp = (trackingCode: string, permissionGranted: boolean): string => `
  <script async src="https://www.googletagmanager.com/gtag/js?id=${htmlEncode(
    trackingCode
  )}"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: '${permissionGranted ? 'granted' : 'denied'}',
    });

    gtag('config', '${htmlEncode(trackingCode)}', {
      allow_google_signals: false,
      anonymize_ip: true,
      cookie_domain: '${document.location.hostname}',
      cookie_expires: 5184000,
      cookie_flags: 'SameSite=None;Secure',
      cookie_update: true
    });
  </script>
`;

const facebookAnalyticsMarkUp = (trackingCode: string, permissionGranted: boolean): string => `
  <script>
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('consent', '${permissionGranted ? 'grant' : 'revoke'}');
    fbq('set', 'autoConfig', false, '${htmlEncode(trackingCode)}');
    fbq('init', '${htmlEncode(trackingCode)}');
    fbq('track', 'PageView');
  </script>
`;

export {
  getModuleMarkUp,
  getPollModuleMarkUp,
  googleAnalyticsMarkUp,
  facebookAnalyticsMarkUp,
  pagingButton,
  renderVideoElement,
  renderInteractivityButton,
  renderInteractivityLink,
  renderChartElement,
  renderToolBar,
  renderToolbarLink,
  renderToolbarMenu,
  renderToolbarLogo,
  renderToolbarItem
};
