/* eslint-disable sort-keys, import/no-unassigned-import, max-params, no-underscore-dangle */
// @ts-check
import 'unfetch/polyfill/index';

const MAX_ERRORS = 5;

type StringOrRegex = string | RegExp;

type ErrorInformation = {
  col: number;
  line: number;
  message: string;
  stack: string;
  type:
    | 'SyntaxError'
    | 'TypeError'
    | 'InternalError'
    | 'ReferenceError'
    | 'RangeError'
    | 'URIError'
    | 'EvalError'
    | 'Error';
  url?: string;
};

/**
 * Checks whether given value's type is an regexp
 */
const isRegExp = (val: StringOrRegex): val is RegExp =>
  Object.prototype.toString.call(val) === '[object RegExp]';

const htmlElementAsString = (elem: HTMLElement): string => {
  const tagName = elem.tagName.toLowerCase();
  const elemID = elem.id ? `#${elem.id}` : '';
  const classList = elem.classList
    ? Array.from(elem.classList).reduce((acc, className) => `${acc}.${className}`, '')
    : '';
  return `${tagName}${elemID}${classList}`;
};

const isCrawler = (userAgent: string): boolean =>
  [
    /Googlebot/i,
    /AdsBot-Google/i,
    /bingbot/i,
    /Baiduspider/i,
    /Ask Jeeves/i,
    /facebookexternalhit/i,
    /Twitterbot/i
  ].some(bot => userAgent.match(bot));

const isMatchingPattern = (value: string, pattern: StringOrRegex): boolean => {
  if (isRegExp(pattern)) {
    return pattern.test(value);
  }

  if (typeof pattern === 'string') {
    return value.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
  }

  return false;
};

type State = {
  apiVersion: string;
  currentPageIndex: number;
  emailAddress: string | null;
  breadcrumbs: string[];
  onlineMagID: number | null;
  onlineMagIssueID: number | null;
  url: string;
  userAgent: string;
};

class ErrorReporter {
  blacklistURLs: StringOrRegex[];
  blacklistErrors: StringOrRegex[];
  errorsSent: number;
  disableErrorReporting: boolean;
  state: State;

  /**
   * @param disableErrorReporting - A string representation of a dom element
   * @param extraBlacklistURLs - A string urls to ignore
   * @param extraBlacklistErrors - An array of errors to ignore
   */
  constructor(
    disableErrorReporting: boolean = false,
    extraBlacklistURLs: string[] = [],
    extraBlacklistErrors: string[] = []
  ) {
    this.captureError = this.captureError.bind(this);
    this.addBreadCrumb = this.addBreadCrumb.bind(this);
    this.sendError = this.sendError.bind(this);
    this.updateData = this.updateData.bind(this);

    this.captureClicks();
    // @ts-ignore
    window.onerror = this.captureError;
    this.blacklistURLs = [...extraBlacklistURLs, /^extension:/];

    this.blacklistErrors = [
      /facebook/gi,
      // Safe to remove once this document gets fixed
      // https://view.pagetiger.com/access-hr/resignation-offboarding-guide/page17.htm?ptit=00022919F7490A30F0FCA&ptidp=y
      /RegisterSod/gi,
      // This is caused by this document https://view.pagetiger.com/career-frame
      /'FontFace' is undefined at Global code/gi,
      // Caused by the HeyTap browser
      /getReadModeConfig/gi,
      /getReadModeRender/gi,
      /getReadModeExtract/gi,
      `reference at moveW`,
      `ptcTabPanel`,
      `ptiCollapsiblePanel`,
      `refreshLayout`,
      `Cannot redefine property: googletag`,
      `property 'chrome'`,
      'zaloJSV2',
      `'FontFace' is undefined at Global code`,
      'ResizeObserver loop limit exceeded',
      ...extraBlacklistErrors
    ];

    this.errorsSent = 0;
    this.disableErrorReporting = disableErrorReporting;

    this.state = {
      apiVersion: window.API_VERSION ?? "unknown",
      currentPageIndex: 0,
      emailAddress: null,
      breadcrumbs: [],
      onlineMagID: null,
      onlineMagIssueID: null,
      url: document.location.href,
      userAgent: navigator.userAgent
    };
  }

  /**
   * @param crumb - A string representation of a dom element
   */
  addBreadCrumb(crumb: string): void {
    if (this.state.breadcrumbs.length >= 10) {
      const [, ...rest] = this.state.breadcrumbs;

      this.state.breadcrumbs = [...rest, crumb];
    } else {
      this.state.breadcrumbs = [...this.state.breadcrumbs, crumb];
    }
  }

  updateData(extraData: Partial<State>): void {
    this.state = { ...this.state, ...extraData };
  }

  /**
   * @param msg - The full error message - `TypeError: something went wrong`
   * @param url - The url source of the error
   * @param line - The line number of the error
   * @param col - The column of the error
   * @param error
   */
  captureError(msg: string, url: string, line: number, col: number, error: Error): void {
    this.sendError({
      col,
      line,
      message: error ? error.message : msg || '',
      stack: error ? error.stack : 'No Stack',
      type: error ? (error.name as ErrorInformation['type']) : 'Error',
      url
    });
  }

  captureClicks() {
    document.addEventListener(
      'click',
      (mouseEvent: MouseEvent) => {
        const target = <HTMLElement>mouseEvent.target;

        this.addBreadCrumb(htmlElementAsString(target));
      },
      true
    );
  }

  /**
   * @param info - The full error message - `TypeError: something went wrong`
   */
  sendError(info: ErrorInformation): void {
    const failedUrlValidation = this.blacklistURLs.some(pattern =>
      isMatchingPattern(info.url, pattern)
    );

    const failedErrorValidation = this.blacklistErrors.some(pattern => {
      return isMatchingPattern(info.message, pattern) || isMatchingPattern(info.stack, pattern);
    });

    if (
      this.errorsSent >= MAX_ERRORS ||
      this.disableErrorReporting ||
      failedUrlValidation ||
      failedErrorValidation ||
      isCrawler(this.state.userAgent)
    ) {
      return;
    }

    this.errorsSent = this.errorsSent + 1;

    fetch('/api/v1/unexpectedlog', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        error: {
          ...info,
          url: info.url || null
        },
        ...this.state
      })
    }).catch(console.error);
  }

  _getState() {
    return this.state;
  }
}

export { ErrorReporter, htmlElementAsString, isCrawler, isMatchingPattern };
