// @ts-check
import { COOKIE_MANAGERS } from './constants';
import { cookieModal, getCookiePreferences, saveCookiePreferences } from './cookie';

import type { CookieManagers } from './constants';
import type { OnlineMag } from './@types/pagetiger';
import { parseJson } from './utils';

interface CookiePreferences {
  advertising: boolean;
  functional: boolean;
  performance: boolean;
}

export interface CookieManager<T> {
  brand: CookieManagers;
  setup: (pageTurn: OnlineMag) => void;
  openCookieModal: () => void;
  handleCookieUpdate: (param0: T) => void;
}

const defaultCookies: CookiePreferences = {
  advertising: false,
  functional: false,
  performance: false
};

/*
-  --------
-  ONETRUST
-  --------
*/

/**
 * Accenture's OneTrust cookie values (from window.OnetrustActiveGroups) returns
 * an array of comma delimited ints. We map these to the key names of our CookiePreferences
 */
const oneTrustCookieMapping = {
  2: 'functional',
  3: 'performance',
  4: 'advertising'
} as const;

/**
 * Takes a customevent with the data of array of string (which are ints) `["1", "2", "3"]`.
 */
const handleOneTrustCookieUpdate = (event: CustomEvent<string[]>): void => {
  window.ptiGetInstance().updateCookiePreferences(parseOneTrustCookieSettings(event.detail));
};

/**
 * Takes char of a int e.g. '1' or '2'
 */
const isValidOneTrustKey = (char: string): boolean => char === '2' || char === '3' || char === '4';

/**
 * Takes in a comma delimited string or an array of strings eg. `,1,2,3,` or ['1', '2', '3'].
 */
const parseOneTrustCookieSettings = (rawCookieInfo: string | string[]): CookiePreferences => {
  const cookieArray = Array.isArray(rawCookieInfo) ? rawCookieInfo : rawCookieInfo.split(',');

  return cookieArray.reduce(
    (cookiePrefs, item) => {
      if (isValidOneTrustKey(item)) {
        const key = oneTrustCookieMapping[item];
        cookiePrefs[key] = true;
      }

      return cookiePrefs;
    },
    { ...defaultCookies }
  );
};

const oneTrustCookieManager: CookieManager<CustomEvent<string[]>> = {
  brand: 'onetrust',
  setup: pageTurn => {
    // OneTrust loads asyc and therefore we cannot guarenttee that it's setup and ready when we
    // execute this code. If it's not present, then we'll wait until it's loaded and they can
    // call us via `OptanonWrapper`.
    const isOneTrustPresent = Boolean(window.OneTrust) && Boolean(window.OnetrustActiveGroups);
    const setupOneTrust = () => {
      window.OneTrust.OnConsentChanged(handleOneTrustCookieUpdate);

      const cookiePrefs = parseOneTrustCookieSettings(window.OnetrustActiveGroups);

      pageTurn.cookiePerformanceAccepted = cookiePrefs.performance;
      pageTurn.cookieFunctionalAccepted = cookiePrefs.functional;
      pageTurn.cookieAdvertisingAccepted = cookiePrefs.advertising;
    };

    if (isOneTrustPresent) {
      setupOneTrust();
    } else {
      window.OptanonWrapper = setupOneTrust;
    }
  },
  // Lazily referencing this as they may not have loaded by the time this executes.
  openCookieModal: () => window.OneTrust?.ToggleInfoDisplay(),
  handleCookieUpdate: handleOneTrustCookieUpdate
};

/*
  ---------
  PAGETIGER
  ---------
*/

/**
 * Takes in a JSON represenation of the CookiePreferences.
 */
const parsePageTigerCookieSettings = (cookieString: string): CookiePreferences =>
  parseJson(cookieString).either(
    () => ({ ...defaultCookies }),
    (cookiePrefs: CookiePreferences) => {
      return cookiePrefs;
    }
  );

const pageTigerCookieManager: CookieManager<CookiePreferences> = {
  brand: 'pagetiger',
  setup: pageTurn => {
    const cookiePrefs = parsePageTigerCookieSettings(getCookiePreferences());

    pageTurn.cookiePerformanceAccepted = cookiePrefs.performance;
    pageTurn.cookieFunctionalAccepted = cookiePrefs.functional;
    pageTurn.cookieAdvertisingAccepted = cookiePrefs.advertising;
  },
  handleCookieUpdate: saveCookiePreferences,
  openCookieModal: cookieModal
};

/**
 * Returns the required cookie manager
 */
const getCookieManager = (cookieManager: string) => {
  if (cookieManager.toLowerCase() === COOKIE_MANAGERS.ONETRUST) {
    return oneTrustCookieManager;
  }

  return pageTigerCookieManager;
};

export { getCookieManager, handleOneTrustCookieUpdate, parseOneTrustCookieSettings };
