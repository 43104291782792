import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import * as constants from '../constants';
import { htmlEncode, isTrue, rewriteToUseSSL } from '../utils';
import { OnlineMagPage } from '../mag-page';

const { ANALYTICS_EVENTS, COLUMN_SEPARATOR,
   EVENT_TYPES, MODULES, MODULE_TYPES, ROW_SEPARATOR } =
  constants;

import type { ModuleTypes } from '../constants';
import { Guid, isGuid } from '../guid';

class OnlineMagPageGalleries {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageGallery[];
  length: number;

  constructor(page: OnlineMagPage, galleries: string, galleryPhotos: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (galleries.length == 0) {
      return;
    }

    const galleryData = galleries.split(ROW_SEPARATOR);

    for (let idx = 0; idx < galleryData.length; ++idx) {
      // Only add Galleries to this page
      if (galleryData[idx].split(COLUMN_SEPARATOR)[3] === this.onlineMagPage.id) {
        const gallery = new OnlineMagPageGallery(this, galleryData[idx], galleryPhotos);

        // If the gallery doesn't contain any images then ignore this module.
        if (gallery.ptiboxItems.length > 0) {
          this.items.push(gallery);
          this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageGalleries.push(gallery);
        }
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageGallery {
  onlineMagPageGalleries: OnlineMagPageGalleries;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  interval: number;
  displayInfo: number;
  infoTextColour: string;
  autoPlay: boolean;
  continuous: boolean;
  onClose: string;
  cssClass: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  popupWidthHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  onComplete: () => void;
  disableClose: boolean;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  hidden: boolean;
  chain: any[];
  isComplete: boolean;
  ptiboxItems: unknown[];
  focusOutlineColour: string;

  constructor(galleries: OnlineMagPageGalleries, gallery: string, galleryPhotos: string) {
    this.onlineMagPageGalleries = galleries;

    if (gallery.length === 0) {
      return;
    }

    const arData = gallery.split(COLUMN_SEPARATOR);
    const pageTurn = this.onlineMagPageGalleries.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.GALLERY;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = htmlEncode(arData[4]);
    this.onPageName = arData[30];
    this.tooltip = htmlEncode(arData[5]);
    this.interval = parseInt(arData[6]);
    this.displayInfo = parseInt(arData[7]);
    this.infoTextColour = arData[8];
    this.autoPlay = isTrue(arData[9]);
    this.continuous = isTrue(arData[10]);
    this.onClose = arData[11];
    this.cssClass = arData[12];
    this.width = arData[13];
    this.height = arData[14];
    this.posLeft = arData[15];
    this.posTop = arData[16];
    this.popupWidthHeight = parseInt(arData[17]);
    this.popupBorderSize = parseInt(arData[18]);
    this.popupBorderColour = arData[19];
    this.popupMarginSize = parseInt(arData[20]);
    this.popupBackgroundColour = arData[21];
    this.onComplete = null;

    const strOnComplete = arData[22];

    if (strOnComplete.length > 0) {
      this.onComplete = function onComplete() {
        window.setTimeout(strOnComplete, 100);
      };
    }

    this.disableClose = isTrue(arData[23]);
    this.important = isTrue(arData[24]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[25]);
    this.mandatoryMessage = arData[26];
    this.hidden = isTrue(arData[27]);
    this.focusOutlineColour = arData[31];

    this.chain = parseChainData(arData[28]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.GALLERY}-${this.id}`);
    const arPhotoData = galleryPhotos.split(ROW_SEPARATOR);

    this.ptiboxItems = [];

    for (let intPhotoIndex = 0; intPhotoIndex < arPhotoData.length; ++intPhotoIndex) {
      // Only add Galleries to this page
      const arPhoto = arPhotoData[intPhotoIndex].split(COLUMN_SEPARATOR);

      if (arPhoto[1] == this.id) {
        this.ptiboxItems.push(
          $.fn.ptibox.generateItemObject(
            '',
            '',
            rewriteToUseSSL(arPhoto[2]),
            htmlEncode(arPhoto[3]),
            null,
            null,
            null,
            parseInt(arPhoto[4]),
            false
          )
        );
      }
    }
  }
  clicked() {
    var objOnlineMagIssue = this.onlineMagPageGalleries.onlineMagPage.onlineMagPages.onlineMagIssue;
    var strContainerElementID = objOnlineMagIssue.containerElementID;
    var strOnClose = this.onClose;
    var gallery = this;

    cancelAndRemoveEvents();
    fireEvents(EVENT_TYPES.ON_SHOW, gallery.chain);

    // @ts-ignore
    $().ptibox({
      itemArray: this.ptiboxItems,
      padding: this.popupBorderSize,
      borderColour:
        this.popupBorderColour.length > 0
          ? this.popupBorderColour
          : objOnlineMagIssue.onlineMagDefaultPopupBorderColour,
      margin: this.popupMarginSize,
      backgroundColour: this.popupBackgroundColour,
      cssClass: this.cssClass,
      interval: this.interval,
      showInfo: this.displayInfo,
      infoTextColour: this.infoTextColour,
      autoPlay: this.autoPlay,
      continuous: this.continuous,
      callbackOnClose() {
        cancelAndRemoveEvents();
        fireEvents(EVENT_TYPES.ON_CLOSE, gallery.chain);
        window.ptiHookupLeftRightArrows(strContainerElementID);

        if (strOnClose.length > 0) {
          window.setTimeout(strOnClose, 100);
        }
      },
      callbackOnViewedAllItems: this.onComplete,
      showCloseButton: !this.disableClose,
      hideOnEscape: !this.disableClose,
      hideOnOverlayClick: !this.disableClose
    });

    const url = `https://${objOnlineMagIssue.host + window.ptiWS}?M=9&VID=${
      objOnlineMagIssue.onlineMagIssueVisitID
    }&GID=${this.id}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    });

    this.isComplete = true;

    objOnlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    objOnlineMagIssue.analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageGallery, OnlineMagPageGalleries };
