import { object, string, array, Infer, is, number, boolean } from 'superstruct';
import { htmlEncode, forceReload, getI18nByKey } from './utils';
import type { OnlineMag } from './@types/pagetiger';

/** Types  */
const Matches = object({
  exact: boolean(),
  value: string()
});

const Results = object({
		content: string(),
		onlineMagPageID: number(),
		pageIndex: number(),
		pageThumbnailUrl: string(),
		pageTitle: string(),
		matches: array(Matches)
});

const SearchResult = object({
	isCurrentDocument: boolean(),
	menuItem: string(),
	onlineMagIssueID: number(),
	onlineMagIssueName: string(),
	onlineMagIssueUrl: string(),
	results: array(Results)
})

const SearchResults = array(SearchResult);

export type SearchResults = Infer<typeof SearchResults>;

/** Functions */

/**
 * Take the parsed JSON and attempt to map it to our search results type
 */
const parseSearchResults = (searchResults: unknown): Promise<SearchResults> => {
  if (is(searchResults, SearchResults)) {
    return Promise.resolve(searchResults);
  }

  return Promise.reject('Unvalid Search Request');
};

/**
 * Reformat Page 0001 to Page 1, is the page title is in the format of Page [0-9]{,4}
 */
const formatPageTitle = (rawPageTitle: string, pageNumber: number): string => {
  // Define the regular expression to match "Page" followed by one or more spaces and then 1 to 4 digits.
  // The regex is anchored to ensure that "Page N" must be the entire string.
  const pageNumberRegex = /^Page\s+([0-9]{1,4})$/i;

  // Execute the regex to test if rawPageTitle matches the pattern
  const match = pageNumberRegex.exec(rawPageTitle);

  // If a match is found and the matched number corresponds to the pageNumber
  if (match && parseInt(match[1], 10) === pageNumber) {
    // Return the formatted page title with the given pageNumber
    return `Page ${pageNumber}`;
  }

  // If it does not match or numbers don't correspond, return the original page title
  return rawPageTitle;
};


/**
 * Take an array of search results and returns the string based mark-up.
 */
const getSearchResultsMarkup = (pageTurn: OnlineMag, searchResults: SearchResults): string => {
  return searchResults
    .map(item => {
      const { thumbnailHeight, thumbnailWidth } = pageTurn;
      const heightAndWidthStyle = `height: ${thumbnailHeight}px; width: ${thumbnailWidth}px; `;      
      const sameIssue = pageTurn.onlineMagIssueID === item.onlineMagIssueID;      
      const resultCount = item.results.length || 0;
      const issueName = item.isCurrentDocument ? 'current document' : item.onlineMagIssueName;
      const cite = item.menuItem ? `${htmlEncode(item.menuItem)}` : `${htmlEncode(issueName)}`;
      const resultTitle = resultCount === 1
        ? `${getI18nByKey(
            'Search.WithinMenu.ResultCount.Single',
            `${resultCount}`,
            `${htmlEncode(cite)}`
          )}`
        : `${getI18nByKey(
            'Search.WithinMenu.ResultCount.Multiple',
            `${resultCount}`,
            `${htmlEncode(cite)}`
          )}`;

      let html = `
        <section class="search-results-section">
        <span class="search-results-text mod-show">${resultTitle}</span>
      `;

      item.results.map((result) => {
        const { content, pageThumbnailUrl, pageIndex, pageTitle } = result;
        const displayNumber = (pageIndex + 1).toString();
        const formattedPageTitle = formatPageTitle(pageTitle, pageIndex + 1);
        const href = sameIssue ?
        `javascript: ptiCloseSearchGotoPage('', ${pageIndex});` :
        item.onlineMagIssueUrl;
    
        html = `${html}
          <div class="search-result-item" data-t="search-result">
            <div class="search-image" aria-hidden="true">
              <a tabindex="-1" aria-hidden="true" href="${href}">
                <div style="border: 1px solid #cbcbcb; background: url(${htmlEncode(
                  pageThumbnailUrl
                )}); ${heightAndWidthStyle}"
                role="img"
                aria-label="${getI18nByKey('Page')} ${htmlEncode(displayNumber)}"
                ></div>
              </a>
              <span id="thumbnail-label" class="search-image-number" aria-hidden="true">${getI18nByKey('Page')} ${htmlEncode(displayNumber)}</span>
            </div>

            <div>
              <a
                href="${href}"
                class="search-link"
                data-t="search-page-${pageIndex}">
                ${htmlEncode(formattedPageTitle)}
                <span class="search-link-issue-name">${htmlEncode(item.onlineMagIssueName)}</span>
              </a>
              <blockquote class="search-text">${content}</blockquote>
            </div>
        </div>`
      });

      // <span>${htmlEncode(item.onlineMagIssueName)}</span>
      // <br>


      html = `${html}</section>`;

      return html;

    })
    .join('');
  }

const getSearchResults = (pageTurn: OnlineMag, searchTerm: string, menuID: number): Promise<SearchResults> => {
  const { guid, host, proofingToken } = pageTurn;
  const { referralUrl } = pageTurn.config;
  const errorMessage = 'There was a problem searching this document.';
  const url = new URL(`https://${host}/api/v1/search`);

  url.searchParams.set('Q', encodeURIComponent(searchTerm));
  url.searchParams.set('PT', proofingToken);
  url.searchParams.set('HN', document.location.hostname);
  url.searchParams.set('RU', referralUrl);
  url.searchParams.set('IGUID', guid);

  if (menuID) {
    url.searchParams.set('MID', menuID.toString());
  }

  return fetch(url.href, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors'
  })
    .then(res => {
      if (res.status === 401) {
        forceReload();
        return Promise.reject();
      }

      if (res.ok) {
        return res;
      }

      return Promise.reject(errorMessage);
    })
    .then(res => res.json())
    .then(parseSearchResults);
};

export { getSearchResults, getSearchResultsMarkup };
