/* eslint-disable max-lines, no-underscore-dangle, max-classes-per-file, no-param-reassign, func-style, max-statements, prefer-destructuring, complexity, max-lines-per-function */
// @ts-check
import $ from 'jquery';
import Maybe from 'crocks/Maybe';
import { debounce } from 'throttle-debounce';
import { cancelAndRemoveEvents, fireEvents, parseChainData } from '../events';
import * as constants from '../constants';
import { renderVideoElement } from '../widgets';
import { getDomElement, playVideo, isTrue, rewriteToUseSSL } from '../utils';
import { isGuid } from '../guid';

const {
  ANALYTICS_EVENTS,
  COLUMN_SEPARATOR,
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  MODULE_SUB_TYPES,
  ROW_SEPARATOR
} = constants;

import type { OnlineMagPage } from '../mag-page';
import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPageVideos {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageVideo[];
  length: number;

  constructor(page: OnlineMagPage, videos: string, subtitles: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (videos.length == 0) {
      return;
    }

    const arData = videos.split(ROW_SEPARATOR);

    for (let videoIndex = 0; videoIndex < arData.length; ++videoIndex) {
      // Only add Videos to this page
      if (arData[videoIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageVideo(this, arData[videoIndex], subtitles);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageVideos.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageVideo {
  onlineMagPageVideos: OnlineMagPageVideos;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  url: string;
  onClose: string;
  cssClass: string;
  width: number;
  height: number;
  posLeft: string;
  posTop: string;
  autoPlay: boolean;
  showSubtitles: boolean;
  urlH264: string;
  urlForPopup: string;
  onComplete: string;
  popupWidth: number;
  popupHeight: number;
  popupBorderSize: number;
  popupBorderColour: string;
  popupMarginSize: number;
  popupBackgroundColour: string;
  disableClose: boolean;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  loop: boolean;
  muted: boolean;
  posterImageUrl: string;
  playsInline: boolean;
  showControls: boolean;
  preventDownload: boolean;
  preventScrubbing: boolean;
  preventFullscreen: boolean;
  hidden: boolean;
  urlVtt: string;
  forceCurrentTime: string;
  chain: any[];
  maxCurrentTimeProgress: number;
  hasBeenWatched: boolean;
  isComplete: boolean;
  currentTime: number;
  isPlaying: boolean;
  onlineMagPageVideoSubtitles: OnlineMagPageVideoSubtitles;
  // This is the scale value that is similar to what designer does
  scale: number;
  focusOutlineColour: string;

  constructor(videos: OnlineMagPageVideos, video: string, subtitles: string) {
    this.onlineMagPageVideos = videos;
    this.videoPlayed = debounce(1500, this.videoPlayed);

    const { previousSession } = videos.onlineMagPage.onlineMagPages.onlineMagIssue;

    if (video.length === 0) {
      return;
    }

    const arData = video.split(COLUMN_SEPARATOR);
    const guid = arData[1];
    const pageTurn = this.onlineMagPageVideos.onlineMagPage.onlineMagPages.onlineMagIssue;

    if (!isGuid(guid)) {
      return;
    }

    this.videoPlayed = debounce(1500, this.videoPlayed.bind(this));

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.VIDEO;
    this.subType = arData[2];
    this.moduleType = MODULES.VIDEO;
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[41];
    this.tooltip = arData[5];
    this.url = rewriteToUseSSL(arData[6]);
    this.onClose = arData[7];
    this.cssClass = arData[8];
    // Float coordinates converted to integers to ensure flash version displays inpage videos
    this.width = parseInt(arData[9]);
    this.height = parseInt(arData[10]);
    this.posLeft = parseFloat(arData[11]).toFixed(0);
    this.posTop = parseFloat(arData[12]).toFixed(0);
    this.autoPlay = isTrue(arData[13]);
    this.showSubtitles = isTrue(arData[14]);
    this.urlH264 = rewriteToUseSSL(arData[15]);
    this.urlForPopup = rewriteToUseSSL(arData[16]) || '';
    this.onComplete = arData[17];
    this.popupWidth = parseInt(arData[18]);
    this.popupHeight = parseInt(arData[19]);
    this.popupBorderSize = parseInt(arData[20]);
    this.popupBorderColour = arData[21];
    this.popupMarginSize = parseInt(arData[22]);
    this.popupBackgroundColour = arData[23];
    this.disableClose = arData[24] === 'true';
    this.important = arData[26] === 'true';
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[27]);
    this.mandatoryMessage = arData[28];
    this.loop = isTrue(arData[29]);
    this.muted = isTrue(arData[30]);
    this.posterImageUrl = arData[31];
    this.playsInline = isTrue(arData[32]);
    this.showControls = isTrue(arData[33]);
    this.preventDownload = isTrue(arData[34]);
    this.preventScrubbing = isTrue(arData[35]);
    this.preventFullscreen = isTrue(arData[36]);
    this.hidden = isTrue(arData[37]);
    this.urlVtt = rewriteToUseSSL(arData[39]);
    this.forceCurrentTime = null;
    // 800 is some magical number
    this.scale = this.onlineMagPageVideos.onlineMagPage.width / 800;
    this.focusOutlineColour = arData[42];

    this.chain = parseChainData(arData[40]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.maxCurrentTimeProgress = 0;
    this.hasBeenWatched = false;
    this.isComplete = previousSession.includes(`${MODULES.VIDEO}-${this.id}`);
    this.currentTime = 0;
    this.isPlaying = false;

    this.onlineMagPageVideoSubtitles = new OnlineMagPageVideoSubtitles(this, subtitles);
  }

  isPopUp() {
    return (
      this.subType === constants.VIDEO_SUB_TYPES.MP4_POPUP ||
      this.subType === constants.VIDEO_SUB_TYPES.YOUTUBE ||
      this.subType === constants.VIDEO_SUB_TYPES.VIMEO
    );
  }

  clicked() {
    // Show video in popup
    var objOnlineMagIssue = this.onlineMagPageVideos.onlineMagPage.onlineMagPages.onlineMagIssue;
    var strHTML = '';
    var strContainerElementID = objOnlineMagIssue.containerElementID;
    var video = this;
    var strOnClose = this.onClose;
    var intPopupWidth = this.popupWidth;
    var intPopupHeight = this.popupHeight;
    var intPopupBorderSize = this.popupBorderSize;
    var strPopupBorderColour =
      this.popupBorderColour.length > 0
        ? this.popupBorderColour
        : objOnlineMagIssue.onlineMagDefaultPopupBorderColour;
    var intPopupMarginSize = this.popupMarginSize;
    var strPopupBackgroundColour =
      this.popupBackgroundColour.length > 0 ? this.popupBackgroundColour : '#000000';
    var strCssClass = this.cssClass;
    var blnDisableClose = this.disableClose;
    const { pageIndex } = this.onlineMagPageVideos.onlineMagPage;
    const lightBoxOuter = document.querySelector('#ptibox_outer.lightbox-outer') as HTMLElement;
    const lightBoxContent = document.querySelector('#ptibox_outer .lightbox-content');
    const maxHeightCSSClass = 'mod-no-max-height';
    const aspectRatio = MODULE_SUB_TYPES.VIDEO_POP_UP ? intPopupWidth / intPopupHeight : 16 / 9;

    lightBoxContent.classList.add(maxHeightCSSClass);
    cancelAndRemoveEvents();
    fireEvents(EVENT_TYPES.ON_SHOW, video.chain);

    window.closePopUpVideo = function closePopUpVideo() {
      window.ptiHookupLeftRightArrows(strContainerElementID);
      video.forceCurrentTime = null; 

      if (strOnClose.length > 0) {
        window.setTimeout(strOnClose, 100);
      }
    };

    const videoResizer = () => {
      getDomElement('#ptibox_div_inner .responsive-container').map(containerEl => {
        containerEl.style.display = 'block';
        let height = Math.min(window.innerHeight, intPopupHeight);
        let width = Math.min(window.innerWidth, intPopupWidth);

        if (height > width / aspectRatio) {
          height = width / aspectRatio;
        } else if (width > height * aspectRatio) {
          width = height * aspectRatio;
        }

        lightBoxOuter.style.width = `${width}px`;
        containerEl.style.height = `${height - 20}px`;
      });
    };

    if (this.subType === MODULE_SUB_TYPES.VIDEO_POP_UP) {
      strHTML = `
      <div class="responsive-container responsive-video-popup" style="display: none;" data-t="mp4-video">
        ${renderVideoElement({
          video,
          pageIndex,
          scale: 0,
          style:
            'position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important;'
        })}
      </div>`;

      // @ts-ignore
      $().ptibox({
        backgroundColour: strPopupBackgroundColour,
        borderColour: strPopupBorderColour,
        callbackOnClose() {
          window.removeEventListener('resize', videoResizer);
          lightBoxContent.classList.remove(maxHeightCSSClass);

          //we need to be able to pass something to this function, to ignore events of type "OnComplete"
          cancelAndRemoveEvents(EVENT_TYPES.ON_COMPLETE);
          fireEvents(EVENT_TYPES.ON_CLOSE, video.chain);

          window.closePopUpVideo();
        },
        callbackOnShow() {
          if (video.autoPlay) {
            playVideo(video.moduleGuid, 0);
          }

          window.addEventListener('resize', videoResizer);
          videoResizer();
        },
        cssClass: `${strCssClass} pop-up-video`,
        hideOnEscape: !blnDisableClose,
        hideOnOverlayClick: !blnDisableClose,
        itemArray: [
          $.fn.ptibox.generateItemObject(
            strHTML,
            '',
            '',
            this.title,
            null,
            intPopupWidth + intPopupMarginSize * 2,
            intPopupHeight + intPopupMarginSize * 2,
            null,
            false
          )
        ],
        margin: intPopupMarginSize,
        padding: intPopupBorderSize,
        showCloseButton: !blnDisableClose,
        showInfo: 2,
        showPlayButton: false,
        title: this.title
      });
    } else if (this.subType === MODULE_SUB_TYPES.YOUTUBE) {
      // YouTube
      const autoPlayProperty = this.urlForPopup && this.autoPlay ? 'autoplay;' : '';
      const attrs = `allow="accelerometer; ${autoPlayProperty} encrypted-media; gyroscope; picture-in-picture" allowfullscreen`;

      strHTML = `
      <div class="responsive-container" style="display: none;" data-t="youtube-video">
        <iframe class="responsive-item" src="${this.urlForPopup}${
        this.autoPlay ? `?autoplay=1` : ``
      }" ${attrs} frameborder="0"></iframe>
      </div>`;

      // @ts-ignore
      $().ptibox({
        backgroundColour: strPopupBackgroundColour,
        borderColour: strPopupBorderColour,
        callbackOnClose() {
          window.removeEventListener('resize', videoResizer);
          lightBoxContent.classList.remove(maxHeightCSSClass);

          cancelAndRemoveEvents();
          fireEvents(EVENT_TYPES.ON_CLOSE, video.chain);

          window.closePopUpVideo();
        },
        callbackOnShow() {
          // TODO: DO I NEED THIS?
          if (video.autoPlay) {
            playVideo(video.moduleGuid, 0);
          }
          window.addEventListener('resize', videoResizer);
          videoResizer();
        },
        cssClass: `${strCssClass} pop-up-video`,
        hideOnEscape: !blnDisableClose,
        hideOnOverlayClick: !blnDisableClose,
        itemArray: [
          $.fn.ptibox.generateItemObject(
            strHTML,
            '',
            '',
            this.title,
            null,
            intPopupWidth + intPopupMarginSize * 2,
            intPopupHeight + intPopupMarginSize * 2,
            null,
            false
          )
        ],
        margin: intPopupMarginSize,
        padding: intPopupBorderSize,
        showInfo: 2,
        showPlayButton: false,
        showCloseButton: !blnDisableClose
      });
    } else if (this.subType === MODULE_SUB_TYPES.VIMEO) {
      // Vimeo
      const autoPlayProperty = this.urlForPopup && this.autoPlay ? 'autoplay;' : '';
      const attrs = `allow="${autoPlayProperty} fullscreen"`;

      strHTML = `
      <div class="responsive-container" style="display: none;" data-t="vimeo-video">
      <iframe class="responsive-item" src="${this.urlForPopup}" ${attrs} frameborder="0" allowfullscreen></iframe>
      </div>`;

      // @ts-ignore
      $().ptibox({
        backgroundColour: strPopupBackgroundColour,
        borderColour: strPopupBorderColour,
        padding: intPopupBorderSize,
        margin: intPopupMarginSize,
        cssClass: `${strCssClass} pop-up-video`,
        hideOnEscape: !blnDisableClose,
        hideOnOverlayClick: !blnDisableClose,
        itemArray: [
          $.fn.ptibox.generateItemObject(
            strHTML,
            '',
            '',
            this.title,
            null,
            intPopupWidth + intPopupMarginSize * 2,
            intPopupHeight + intPopupMarginSize * 2,
            null,
            false
          )
        ],
        showCloseButton: !blnDisableClose,
        showInfo: 2,
        showPlayButton: false,
        title: this.title,
        callbackOnShow() {
          window.addEventListener('resize', videoResizer);
          videoResizer();
        },
        callbackOnClose() {
          window.removeEventListener('resize', videoResizer);
          lightBoxContent.classList.remove(maxHeightCSSClass);

          cancelAndRemoveEvents();
          fireEvents(EVENT_TYPES.ON_CLOSE, video.chain);

          window.closePopUpVideo();
        }
      });
    }

    // Log with reporting
    this.videoPlayed();
  }

  videoPlayed() {
    const { onlineMagIssue } = this.onlineMagPageVideos.onlineMagPage.onlineMagPages;
    const { analytics, baseUrl, onlineMagIssueVisitID } = onlineMagIssue;
    const url = `${baseUrl}?M=7&VID=${onlineMagIssueVisitID}&LID=${this.id}`;

    if (this.subType === MODULE_SUB_TYPES.VIDEO) {
      fireEvents(EVENT_TYPES.ON_SHOW, this.chain);
    }

    $.ajax({
      url,
      dataType: 'jsonp'
    });

    this.isComplete = true;

    onlineMagIssue.onModuleComplete(this);
    analytics.fireEvent(ANALYTICS_EVENTS.PLAYED, this.title, this.subType);
  }
}

class OnlineMagPageVideoSubtitles {
  onlineMagPageVideo: OnlineMagPageVideo;
  items: OnlineMagPageVideoSubtitle[];
  length: number;

  constructor(video: OnlineMagPageVideo, subtitles: string) {
    this.onlineMagPageVideo = video;
    this.items = [];
    this.length = 0;

    if (subtitles.length === 0) {
      return;
    }

    const arData = subtitles.split(ROW_SEPARATOR);

    for (let intSubtitleIndex = 0; intSubtitleIndex < arData.length; ++intSubtitleIndex) {
      // Only add Subtitles to this Video
      if (arData[intSubtitleIndex].split(COLUMN_SEPARATOR)[1] == this.onlineMagPageVideo.id) {
        this.items[this.items.length] = new OnlineMagPageVideoSubtitle(
          this,
          arData[intSubtitleIndex]
        );

        this.onlineMagPageVideo.onlineMagPageVideos.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageVideoSubtitles.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageVideoSubtitle {
  onlineMagPageVideoSubtitles: OnlineMagPageVideoSubtitles;
  id: string;
  onlineMagPageVideoID: string;
  subtitle: string;
  seconds: number;

  constructor(subtitles: OnlineMagPageVideoSubtitles, subtitle: string) {
    this.onlineMagPageVideoSubtitles = subtitles;

    if (subtitle.length === 0) {
      return;
    }

    const arData = subtitle.split(COLUMN_SEPARATOR);

    this.id = arData[0];
    this.onlineMagPageVideoID = arData[1];
    this.subtitle = arData[2];
    this.seconds = parseInt(arData[3]);
  }
}

export { OnlineMagPageVideo, OnlineMagPageVideos };
