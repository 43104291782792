import {
  DEVICE_DESKTOP,
  DEVICE_MOBILE,
  PAGE_TURN,
  HTML_PAGE_TURN,
  HIGH_CONTRAST,
  STACKABLE,
  SMALL_SCREEN_WIDTH,
  ANIMATION_MODES
} from './constants';

import type { OnlineMag, DocumentSettings, Config } from './@types/pagetiger';
import type { AnimationMode } from './constants';

type Viewing = typeof DEVICE_MOBILE | typeof DEVICE_DESKTOP;

type ViewingDevice = Readonly<{
  type: Viewing;
  mode: OnlineMag['viewingInterface'];
}>;

const getViewingMode = (
  animationType: AnimationMode,
  accessibleInterface: Config['accessibleInterface']
): OnlineMag['viewingInterface'] => {
  const animationLowered = animationType.toLowerCase().trim();
  const isPageTurn =
    animationLowered === ANIMATION_MODES.BOOK || animationLowered === ANIMATION_MODES.PAGE_TURN;
  const viewInterface = (isPageTurn ? PAGE_TURN : STACKABLE) as OnlineMag['viewingInterface'];

  if (accessibleInterface === 'H') {
    return HTML_PAGE_TURN;
  }

  if (accessibleInterface === 'C') {
    return HIGH_CONTRAST;
  }

  return viewInterface;
};

const getViewInterface = (
  docSettings: DocumentSettings,
  accessibleInterface: Config['accessibleInterface'],
  clientWidth: number
): ViewingDevice => {
  const isMobile = clientWidth < SMALL_SCREEN_WIDTH;

  return {
    type: isMobile ? DEVICE_MOBILE : DEVICE_DESKTOP,
    mode: isMobile
      ? getViewingMode(docSettings.pageAnimationMobile, accessibleInterface)
      : getViewingMode(docSettings.pageAnimationPC, accessibleInterface)
  };
};

export { getViewInterface };
