/* eslint-disable max-lines, require-unicode-regexp, import/max-dependencies, no-param-reassign */
// @ts-check
import $ from 'jquery';
import Cookies from 'js-cookie';
import Maybe from 'crocks/Maybe';
import safe from 'crocks/Maybe/safe';
import Result from 'crocks/Result';
import isNumber from 'crocks/predicates/isNumber';
import clamp from 'ramda/src/clamp';
import compose from 'ramda/src/compose';
import curry from 'ramda/src/curry';
import isNil from 'ramda/src/isNil';
import ifElse from 'ramda/src/ifElse';
import not from 'ramda/src/not';
import pipe from 'ramda/src/pipe';
import invoker from 'ramda/src/invoker';

import { isAuthModalActive } from './auth';
import { isOnLastPage } from './page-helpers';
import * as constants from './constants';
import { DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE } from './constants';

/** @typedef {import('./@types/pagetiger').Page} Page  */
/** @typedef {import('./@types/charts').ChartModule} ChartModule  */
/** @typedef {import('./@types/pagetiger').FullModule} FullModule  */
/** @typedef {import('./@types/pagetiger').OnlineMag} OnlineMag  */
/** @typedef {import('./constants').Layouts} Layouts  */

const { LAYOUTS } = constants;
const { Just, Nothing } = Maybe;
const { Ok, Err } = Result;

/** @returns {void} */
const noOp = () => {};

// -- $$ : String -> undefined | HTMLElement
const $$ = document.querySelector.bind(document);

// -- $$$ : String -> NodeList
const $$$ = document.querySelectorAll.bind(document);

// -- fromNullable : a -> Maybe a
const fromNullable = ifElse(isNil, Nothing, Just);

/**
 * @param {string} selector
 * @returns {any}
 */
const getDomElement = selector => compose(fromNullable, $$)(selector);

/**
 * @template {HTMLElement} [T=HTMLElement]
 * @param {string} selector
 * @return {T[]}
 */
const getDomElements = selector => compose(xs => Array.prototype.slice.call(xs), $$$)(selector);

/**
 * @param {HTMLElement} el
 * @returns {void}
 */
const removeElement = el => {
  el.parentNode.removeChild(el);
};

// -- removeListener : String -> Fn -> HTMLElement
const removeListener = curry((type, fn, el) => el.removeEventListener(type, fn));

// -- addListener : String -> Fn -> HTMLElement
const addListener = curry((type, fn, el) => el.addEventListener(type, fn));

// -- setText : String -> HTMLElement
const setText = curry((text, el) => {
  el.textContent = text;
});

/**
 * @template T
 * @param {string} label
 * @returns {(value: T) => T}
 */
const log = label => value => {
  console.log(`${label}:`, value);

  return value;
};

// -- isNotNil : a -> Boolean
const isNotNil = pipe(isNil, not);

// -- getCookie : String -> Maybe String
const getCookie = compose(fromNullable, Cookies.get);

function tryCatch(fn) {
  return function () {
    try {
      return Ok(fn.apply(null, arguments));
    } catch (e) {
      return Err(e);
    }
  };
}

/**
 * @param {string} url
 * @returns
 */
const parseUrl = url => {
  try {
    return Just(new URL(url));
  } catch (e) {
    return Nothing();
  }
};

/**
 * @param {string} json
 * @returns {any}
 */
const parseJson = json => {
  try {
    return Ok(JSON.parse(json));
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Failed to Parse JSON';

    return Err(errorMessage);
  }
};

/**
 * @param {string} url
 * @returns {Err<string>|Ok<true>}
 */
const sendBeacon = url => {
  try {
    return Ok(navigator.sendBeacon(url));
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Failed to call SendBeacon';

    return Err(errorMessage);
  }
};

/**
 * @param {unknown} maybeStr
 * @returns {boolean}
 */
const isNilOrBlank = maybeStr => maybeStr === '' || maybeStr === null || maybeStr === undefined;

// -- pause : DomNode -> ()
const pause = invoker(0, 'pause');

// -- pause : DomNode -> ()
const play = invoker(0, 'play');

// -- safeNumberFromString : a -> Maybe Number
const safeNumberFromString = pipe(parseInt, safe(isNumber));

/**
 * @param {string} containerElementID - DOM selector
 * @param {string} guid - The guid of the page
 * @returns {void}
 */
const goToPageGuid = (containerElementID, guid, suppressChainOnPageShow = false) => {
  const pageTurn = window.ptiGetInstance();
  const pageIndex = pageTurn.onlineMagPages.items.findIndex(page => page.guid === guid);

  if (pageIndex > -1) {
    window.ptiGotoPage(containerElementID, pageIndex, suppressChainOnPageShow);
  }
};

/**
 * @param {Document} doc - document
 * @returns {boolean}
 */
const isSecure = doc => doc.location.protocol === 'https:';

/**
 * @param {string} url - A URL
 * @returns {string}
 */
const rewriteToUseSSL = (url = '') => {
  const isOverHTTPS = isSecure(document);

  if (isOverHTTPS && url.toLowerCase().indexOf('http:') === 0) {
    return `https:${url.substr(5)}`;
  }

  return url;
};

/**
 * @param {string} htmlString
 * @returns {ChildNode}
 */
const makeHtml = htmlString => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');

  return doc.body.firstChild;
};

/**
 * @returns {void}
 */
const forceReload = () => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete('ptit');

  window.location.assign(currentUrl.href);
};

/**
 * @param {string} htmlString
 * @returns {HTMLCollection}
 */
const parseHtmlString = htmlString => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');

  return doc.body.children;
};

/**
 * @param {string} str
 * @returns {string}
 */
const htmlEncode = str => $('<div></div>').text(str).html();

/**
 * @param {string} str
 * @returns {boolean}
 */
const containsHtml = str => {
  const htmlRegex = /(<([^>]+)>)/i;

  return htmlRegex.test(str);
};

/**
 * @param {number} delayMS - Time delay in ms
 * @returns {Promise<void>}
 */
const delay = delayMS => new Promise(res => setTimeout(() => res(), delayMS));

/**
 * Takes a string on @font-face's and adds them to the document head.
 * @param {string} fontStyles
 */
const outputFontsStyles = fontStyles => {
  const style = document.createElement('style');
  style.innerHTML = fontStyles;
  document.head.appendChild(style);
};
/**
 * Get a page property
 * @param {boolean} isSinglePage
 * @param {string} pageProperty
 * @returns {number} The page detail
 */
const getPageDetails = (isSinglePage, pageProperty) => {
  const { sizes } = window.ptiGetInstance();
  const pageType = isSinglePage ? 'singlePage' : 'doublePage';

  return sizes[pageType][pageProperty];
};

/**
 * @param {string} host - host
 * @param {string} content - markup
 * @returns {string}
 */
const rewriteLinksToUseSSL = (host, content) => {
  if (isSecure(document)) {
    return content.replace(new RegExp(`http://${host}/`, 'g'), `https://${host}/`);
  }

  return content;
};

/**
 * @template {HTMLElement} [T=HTMLElement]
 * @param {number} threshold
 * @returns {(elem: T) => boolean}
 */
const percentageInViewport = threshold => elem => {
  // Threshold is the percentage of the element we want in view
  const pixelsInView = numberOfPixelsInViewport(elem);
  const { height } = elem.getBoundingClientRect();
  const percentageInView = Math.floor((pixelsInView / height) * 100);

  return percentageInView >= threshold;
};

/**
 * @param {HTMLElement} elem - The element we want to track in the viewport
 * @returns {number} The number of pixels that the video is in the viewport
 */
const numberOfPixelsInViewport = elem => {
  const { bottom, height, top } = elem.getBoundingClientRect();
  const { clientHeight } = document.documentElement;
  const topY = Math.min(height, clientHeight - top);
  const bottomY = bottom < clientHeight ? bottom : clientHeight;
  const pixelsInViewport = Math.max(0, top > 0 ? topY : bottomY);

  return pixelsInViewport;
};

/**
 *
 * @param {HTMLVideoElement} video
 * @returns {boolean}
 */
const isVideoPlaying = video =>
  Boolean(video.currentTime > 0 && !video.paused && !video.ended && video.readyState);

/**
 * @param {HTMLVideoElement} vidEl
 * @returns {void}
 */
const pauseVideo = vidEl => {
  vidEl.pause();
};

/**
 * @param {string} guid - The video GUID
 * @param {number | undefined} playFrom - The number of seconds we should play from
 * @returns {void}
 */
const playVideo = (guid, playFrom) => {
  getDomElement(`[data-module-guid="${guid}"]`).map(video => {
    const cachedMuted = video.muted;

    // Set the current time for IE11, as `.play()` does not return a promise.
    video.currentTime = playFrom ?? video.currentTime;

    const playPromise = video.play();

    // IE11 doesn't return a promise
    if (playPromise) {
      playPromise
        .then(() => {
          // Safari doesn't respect the video time set above, so it's set here as well.
          video.currentTime = playFrom ?? video.currentTime;
        })
        .catch(() => {
          // It's possible that Safari on iOS will refuse to play
          // the video in it's current state, therefore we will
          // try and put the video in a state where it will play
          if (navigator.platform === 'iPhone') {
            video.muted = true;
            video.play();
          }
        })
        .catch(err => {
          window.errorReporter.sendError({
            col: 0,
            line: 0,
            message: err.message ?? 'Video Play Error',
            stack: err.stack ?? 'No Stack',
            type: 'VideoPlayError'
          });

          if (navigator.platform === 'iPhone') {
            video.muted = cachedMuted;
          }
        });
    }
  });
};

/**
 * @param {string} str - Where we going?
 * @returns {string}
 */
const urlEncodeIfNecessary = str => {
  var regex = /[\\\"<>\.;]/;
  var hasBadChars = regex.exec(str) !== null;

  return hasBadChars ? encodeURIComponent(str) : str;
};

/**
 * @param {string} url - Where we going?
 * @param {'_self' | '_blank'} target
 * @returns {void}
 */
const openWindow = (url, target) => {
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('target', target);

  if (target === '_blank') {
    link.setAttribute('rel', 'noopener');
  }

  link.click();
};

/**
 * @param {object} object
 * @returns {boolean}
 */
const isFunction = object => {
  return Boolean(object && object.constructor && object.call && object.apply);
};

/**
 * @param {Window} win - The Window object
 * @returns {String}
 */
const getPageNumberFromURL = win => {
  const url = new URL(win.location.href);
  const pageNumberInPath = win.location.pathname.match(/page(\d{1,4})/i);
  const pageNumberInQueryString = url.searchParams.get('ptip');

  if (pageNumberInPath) {
    return pageNumberInPath[1];
  } else if (pageNumberInQueryString) {
    return pageNumberInQueryString;
  }

  return '0';
};

const getRequestedInitialPage = compose(
  // At some point, it'd be nice to pass in number of pages
  clamp(0, 9999),
  int => int - 1,
  str => parseInt(str),
  getPageNumberFromURL
);

/**
 * @param {number} pageShadowValue - 0 is off
 * @param {boolean} reduceMotion
 * @returns {any} - Maybe String
 */
const getPageShadow = (pageShadowValue, reduceMotion) => {
  if (reduceMotion) {
    return Nothing();
  } else if (pageShadowValue === 1) {
    return Just('light-center');
  } else if (pageShadowValue === 2) {
    return Just('dark-center');
  } else if (pageShadowValue === 3) {
    return Just('light-center-highlight');
  } else if (pageShadowValue === 4) {
    return Just('dark-center-highlight');
  }

  return Nothing();
};

/**
 * @returns {"portrait" | "landscape"}
 */
const getOrientation = () =>
  window.innerHeight > window.innerWidth ? constants.PORTRAIT : constants.LANDSCAPE;

/* eslint-disable complexity, no-nested-ternary */
/**
 * @param {OnlineMag} doc
 * @returns {Layouts}
 */
const getLayout = doc => {
  /** @type {Layouts} */
  var layout = LAYOUTS.AUTO;

  if (doc.config.accessibleInterface === constants.ACCESSIBILITY_MODE.HIGH_CONTRAST) {
    return LAYOUTS.SINGLE;
  }

  if (doc.availableLayouts === LAYOUTS.SINGLE_AND_DOUBLE) {
    if (doc.isSmallScreen && getOrientation() === constants.LANDSCAPE) {
      layout = LAYOUTS.DOUBLE;
    } else if (doc.isSmallScreen) {
      layout = doc.onlineMagDefaultLayoutMobile;
    } else {
      layout = doc.onlineMagDefaultLayoutPC;
    }
  } else {
    layout = doc.availableLayouts;
  }

  return layout === LAYOUTS.AUTO ? (doc.isSmallScreen ? LAYOUTS.SINGLE : LAYOUTS.DOUBLE) : layout;
};
/* eslint-enable complexity, no-nested-ternary */

/**
 * @param {Page|null} leftPage - Left Page Index
 * @param {Page|null} rightPage - Right Page Index
 * @param {Number} totalPageCount - Total Page Count
 * @returns {Boolean[]}
 */
const hideLeftRightArrows = (leftPage, rightPage, totalPageCount) => {
  const hideLeftArrow = leftPage?.pageIndex === 0 || rightPage?.pageIndex === 0;
  const hideRightArrow =
    leftPage?.pageIndex === totalPageCount || rightPage?.pageIndex === totalPageCount;

  return [hideLeftArrow, hideRightArrow];
};

/**
 * @param {OnlineMag} pageTurn
 * @returns {number}
 */
const lowestVisiblePageIndex = ({ currentPageIndex, config, onlineMagPages, zoom }) => {
  const isSinglePageMode = zoom === true;
  const isFirstPage = currentPageIndex === 0;
  const isLastPage = currentPageIndex === onlineMagPages.length - 1;
  const isDoublePageAndAlwaysOpen = zoom === false && config.alwaysOpened === true;
  const clampToDocument = clamp(0, onlineMagPages.length);

  if (isSinglePageMode || isFirstPage || isLastPage || isDoublePageAndAlwaysOpen) {
    return clampToDocument(currentPageIndex);
  }

  // CurrentPageIndex will be the right hand page, so we need to take one off to get the lowest index
  return clampToDocument(currentPageIndex - 1);
};

/**
 * @param {string} id - The moduleID
 * @returns {string} - A DOM Selector
 */
const prependHash = id => `#${id}`;

/**
 * @param {{ moduleType: number, id: string}} mod - The module
 * @returns {string} - A DOM Selector
 */
const getModuleID = mod => `interactive-module-${mod.moduleType}-${mod.id}`;

// -- getModuleSelector :: String -> String
const getModuleSelector = compose(prependHash, getModuleID);

/**
 * @param {any} mod - An Interactive Module
 * @returns {boolean}
 */
const moduleIsRequiredAndIncomplete = mod => mod.mandatory && !mod.isComplete;

/**
 * @param {any} pageTurn - The page turn object
 * @param {string} message - Html markup for the modals content
 * @param {string} moduleSelectors - Html markup for the modals content
 * @param {HTMLAnchorElement|HTMLButtonElement|null} focusableModule - Html markup for the modals content
 * @returns {void}
 */
const showRequiredInteractivityModal = (pageTurn, message, moduleSelectors, focusableModule) => {
  $.fn.ptimessage.showAlert(
    `<div class="popup mod-more-info">${message}</div>`,
    pageTurn.onlineMagDefaultPopupButtonBGColour,
    pageTurn.onlineMagDefaultPopupButtonTextColour,
    500,
    200,
    null,
    function onClose() {
      window.ptiHookupLeftRightArrows(pageTurn.containerElementID);
      pageTurn.ui.glow(moduleSelectors);
    },
    pageTurn.onlineMagDefaultPopupBorderColour,
    focusableModule
  );
};

/**
 * @param {number[]} visiblePageIndexes - Left Page Index
 * @param {any} pages - Left Page Index
 * @returns {any[]}
 */
const getRequiredModulesForCurrentPages = (visiblePageIndexes, pages) =>
  pages
    .filter((_page, index) => visiblePageIndexes.includes(index))
    .reduce(
      (acc, page) => {
        const incompleteModules = page.modules.filter(moduleIsRequiredAndIncomplete);

        return incompleteModules.length
          ? [
              ...acc,
              {
                modules: incompleteModules.sort((mod1, mod2) => mod1.posTop - mod2.posTop),
                pageMessage: page.mandatoryInteractivityMessage
              }
            ]
          : acc;
      },

      []
    );

/**
 * @param {OnlineMag["onlineMagPages"]["items"]} pages - Left Page Index
 * @returns {number}
 */
const getFirstPageIndexWithIncompleteRequiredModules = pages =>
  pages.findIndex(page => page.modules.filter(moduleIsRequiredAndIncomplete).length > 0);

/**
 * @param {any} pages - Left Page Index
 * @returns {any}
 */
const getFirstPageWithIncompleteRequiredModules = pages =>
  pages.find(page => page.modules.filter(moduleIsRequiredAndIncomplete).length > 0);

const getRequiredModuleMessageAndSelectors = page => {
  const { mandatoryInteractivityMessage: pageMessage, modules } = page;
  // By working on the first index we're always getting the correct message to display. If
  // there's a left and right page, the first index is left. If there is only required
  // modules on the right page, that will still be in the first index.

  const allUnfinishedModules = modules.filter(moduleIsRequiredAndIncomplete);

  const moduleMessages = allUnfinishedModules
    .filter((mod, index, arr) => arr.findIndex(el => el.pollID === mod.pollID) === index)
    .reduce(
      (acc, mod) =>
        mod.mandatoryMessage.trim() === '' ? acc : `${acc}<p>${mod.mandatoryMessage}</p>`,
      ''
    );

  const message =
    pageMessage === '' && moduleMessages === ''
      ? getI18nByKey(
          DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE,
          getI18nByKey('Required.Interactions.Title'),
          getI18nByKey('Required.Interactions.Message')
        )
      : pageMessage + moduleMessages;

  const unfinishedModuleSelectors = allUnfinishedModules.map(getModuleSelector).join(', ');

  return { message, unfinishedModuleSelectors };
};

/* eslint-disable-next-line eqeqeq */
/**
 * @param {'id'|'title'} propertyName - Usually `id` or `title`
 * @returns {any|null}
 */
const getBy = propertyName => (items, value) => items.find(item => item[propertyName] == value);

/**
 * @param {boolean} arrowsHidden - Are the side nav arrows displayed.
 * @param {boolean} isSmallScreen - Is viewport width narrow
 * @param {string} orientation - In landscape or portrait
 * @returns {Number}
 */
const getLeftRightMarginSize = (arrowsHidden, isSmallScreen, orientation) => {
  const smallMargin = 5;
  const largeMargin = 53;

  if (arrowsHidden) {
    return smallMargin;
  } else if (!arrowsHidden && isSmallScreen && orientation === constants.LANDSCAPE) {
    return largeMargin;
  } else if (!arrowsHidden && !isSmallScreen) {
    return largeMargin;
  }

  return smallMargin;
};

/**
 * @param {string} elementID - DOM selector
 * @param {number} type - Module Type ID
 * @param {number} moduleId - Module ID
 * @returns {void}
 */
const closePopupLinkClicked = (elementID, type, moduleId) => {
  $().ptibox.close(function closeModal() {
    window.setTimeout(() => {
      window.ptiLinkClicked(elementID, type, moduleId);
    }, 500);
  });
};

/**
 * @param {Date} baseDateTime
 * @param {number} secondsToAdd
 * @returns {Date}
 */
const addSeconds = (baseDateTime, secondsToAdd) => {
  const baseTime = baseDateTime.getTime();

  return new Date(baseTime + secondsToAdd * 1000);
};

/* eslint-disable max-statements */

/**
 * @param {string} containerElementID - DOM selector
 * @returns {void}
 */
const attractModeNextPage = containerElementID => {
  const objPageTurn = window.ptiGetInstance();

  if (isAuthModalActive()) {
    return;
  }

  if (attractModeStillActive(containerElementID)) {
    if (objPageTurn.config.attractModeWaitForVideos) {
      let blnVideosPlaying = false;
      let intSecondsRemaining = 0;

      $('video').each(function vidFn() {
        if (this instanceof HTMLVideoElement) {
          if (this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2) {
            blnVideosPlaying = true;

            if (this.duration - this.currentTime > intSecondsRemaining) {
              intSecondsRemaining = this.duration - this.currentTime;
            }
          }
        }
      });

      if (blnVideosPlaying) {
        // Check back in a second
        const intSeconds =
          intSecondsRemaining < objPageTurn.config.attractModePageEverySeconds
            ? objPageTurn.config.attractModePageEverySeconds + intSecondsRemaining
            : objPageTurn.config.attractModePageEverySeconds;

        window.setTimeout(() => {
          attractModeNextPage(containerElementID);
        }, intSeconds * 1000);

        return;
      }
    }

    if (isOnLastPage(objPageTurn)) {
      window.ptiGotoPage(containerElementID, 0);
    } else {
      window.ptiNextPage(containerElementID);
    }

    window.setTimeout(() => {
      attractModeNextPage(containerElementID);
    }, objPageTurn.config.attractModePageEverySeconds * 1000);
  } else {
    const ms =
      window.ptiAttractModeLastInteraction.getTime() -
      new Date().getTime() +
      objPageTurn.config.attractModeStartAfterSeconds * 1000;

    window.ptiAttractModeStarted = false;

    window.setTimeout(() => {
      attractModeStart(containerElementID);
    }, ms);
  }
};

/**
 * @param {string} elementID - DOM selector
 * @returns {void}
 */
const attractModeStart = elementID => {
  const objPageTurn = window.ptiGetInstance();
  const { attractModeStartAfterSeconds } = objPageTurn.config;

  if (isAuthModalActive()) {
    return;
  }

  const objNextPageFunction = () => {
    objPageTurn.attractModeActive = true;
    window.ptiAttractModeStarted = true;

    attractModeNextPage(elementID);
  };

  if (!window.ptiAttractModeStarted) {
    if (objPageTurn.config.attractModeWaitForVideos) {
      let blnVideosPlaying = false;
      let intSecondsRemaining = 0;

      getDomElement('video').map(video => {
        if (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) {
          blnVideosPlaying = true;

          if (video.duration - video.currentTime > intSecondsRemaining) {
            intSecondsRemaining = video.duration - video.currentTime;
          }
        }
      });

      if (blnVideosPlaying) {
        // Check back in a second
        const intSeconds =
          intSecondsRemaining < attractModeStartAfterSeconds
            ? attractModeStartAfterSeconds + intSecondsRemaining
            : attractModeStartAfterSeconds;

        window.setTimeout(() => {
          attractModeStart(elementID);
        }, intSeconds * 1000);

        return;
      }
    }

    if (
      addSeconds(window.ptiAttractModeLastInteraction, attractModeStartAfterSeconds) <
      addSeconds(new Date(), 1)
    ) {
      if ($().ptibox.isVisible()) {
        $().ptibox.close(objNextPageFunction);
      } else {
        objNextPageFunction();
      }
    } else {
      window.setTimeout(() => {
        attractModeStart(elementID);
      }, window.ptiAttractModeLastInteraction.getTime() - new Date().getTime() + attractModeStartAfterSeconds * 1000);
    }
  }
};
/* eslint-enable max-statements */

/**
 * @param {string} _containerElementID - DOM selector
 * @returns {boolean}
 */
const attractModeStillActive = _containerElementID => {
  const { config } = window.ptiGetInstance();

  if (
    addSeconds(window.ptiAttractModeLastInteraction, config.attractModePageEverySeconds) <
      new Date() &&
    !$().ptibox.isVisible()
  ) {
    return true;
  }

  return false;
};

/**
 * @param {any} options - The chart options
 * @param {number} fontSize - The base font size for the chart
 * @param {number} paddingDivisor - How much to reduce the padding by
 * @returns {any}
 */
const getCustomChartSizes = (options, fontSize, paddingDivisor) => ({
  ...options,
  layout: {
    padding: options.layout.padding / paddingDivisor
  },
  legend: {
    ...options.legend,
    labels: {
      ...options.legend.labels,
      boxWidth: 10,
      fontSize: fontSize - 2
    }
  },
  // Scales can be null for pie, doughnuts etc.
  scales: options.scales
    ? {
        ...options.scales,
        xAxes: [
          ...options.scales.xAxes.map(axe => ({
            ...axe,
            scaleLabel: {
              ...axe.scaleLabel,
              fontSize
            },
            ticks: {
              ...axe.ticks,
              fontSize
            }
          }))
        ],
        yAxes: [
          ...options.scales.yAxes.map(axe => ({
            ...axe,
            scaleLabel: {
              ...axe.scaleLabel,
              fontSize
            },
            ticks: {
              ...axe.ticks,
              fontSize
            }
          }))
        ]
      }
    : null,
  title: {
    ...options.title,
    fontSize,
    padding: 5
  }
});

/**
 * @param {number} chartWidth - The width of the chart container
 * @param {FullModule} chart - The chart object
 * @returns {any}
 */
const getChartOptions = (chartWidth, chart) => {
  let options = getChartOptionsForWidth(chartWidth, chart);

  if (chart.highContrast) {
    setChartHighContrastOptions(options);
  }

  return options;
};

/**
 * @param {ChartOptions} options - Options to set high contrast on
 * @returns {any}
 */
const setChartHighContrastOptions = (options) => {
  const black = "#000000";

  options.title.fontColor = black;
  options.legend.fontColor = black;
  options.legend.labels.fontColor = black;
  options.scales.ticks.fontColor = black;

  options.scales.xAxes.forEach(xAxis => {
    xAxis.gridLines.color = black;
    xAxis.scaleLabel.fontColor = black;
    xAxis.ticks.fontColor = black;
  });

  options.scales.yAxes.forEach(yAxis => {
    yAxis.gridLines.color = black;
    yAxis.scaleLabel.fontColor = black;
    yAxis.ticks.fontColor = black;
  });
}

/**
 * @param {number} chartWidth - The width of the chart container
 * @param {FullModule} chart - The chart object
 * @returns {any}
 */
const getChartOptionsForWidth = (chartWidth, chart) => {
  if (chartWidth < constants.CHART_WIDTHS.XX_SMALL) {
    return chart.chartData.xxSmallOptions;
  } else if (chartWidth < constants.CHART_WIDTHS.X_SMALL) {
    return chart.chartData.xsmallOptions;
  } else if (chartWidth < constants.CHART_WIDTHS.SMALL) {
    return chart.chartData.smallOptions;
  }

  return chart.chartData.defaultOptions;
};

/**
 * @param {FullModule} chartModule - The chart object
 * @returns {void}
 */
const destroyChart = chartModule => {
  chartModule.instance.map(chartInstance => chartInstance.destroy());
  chartModule.instance = Nothing();
};

/**
 * @param {ChartModule[]} charts - The chart object
 * @returns {void}
 */
const destroyCharts = charts => {
  charts.map(destroyChart);
};

/**
 * @param {string} token
 * @returns {boolean}
 */
const isProofingToken = token => {
  const proofingTokenRegex = /^[0-9a-f]{10,100}$/i;

  return proofingTokenRegex.test(token);
};

/**
 * @param {string} token
 * @returns {string}
 */
const getProofingToken = token => (isProofingToken(token) ? token : '');

/**
 * @param {string} email - Users email
 * @returns {boolean}
 */
const validateEmail = email => {
  const emailRegex =
    /^(['_a-z0-9-+]+)(\.['_a-z0-9-]+)*@([a-z0-9-]+)(\.[a-z0-9-]+)*(\.[a-z]{2,10})$/;

  return emailRegex.test(email.toLowerCase());
};

/**
 * @param {string} email
 * @returns {string}
 */
const validateAndDecodeEmailAddress = email => {
  const decodedEmail = decodeURIComponent(email);

  if (validateEmail(decodedEmail)) {
    return decodedEmail;
  }

  return '';
};

/**
 * @returns {boolean}
 */
const hasVirtualKeyboard = () => Boolean(navigator.userAgent.match(/tigerplayerkeyboard/gi));

/**
 * @returns {boolean}
 */
const prefersReducedMotion = () => window.matchMedia('(prefers-reduced-motion: reduce)').matches;

/**
 * @param {string} emailFromQueryString
 * @param {string} emailFromCookie
 * @param {string} emailFromConfig
 * @returns {[string, boolean]}
 */
const getValidatedEmail = (emailFromQueryString, emailFromCookie, emailFromConfig) => {
  if (emailFromConfig) {
    return [emailFromConfig, false];
  }

  const validatedEmailFromQueryString = validateAndDecodeEmailAddress(emailFromQueryString);
  const validatedEmailFromCookie = validateAndDecodeEmailAddress(emailFromCookie);

  if (validatedEmailFromQueryString) {
    return [validatedEmailFromQueryString, true];
  } else if (validatedEmailFromCookie) {
    return [validatedEmailFromCookie, false];
  }

  return ['', false];
};

/**
 * @param {OnlineMag['viewingInterface']} viewingInterface
 * @returns {boolean}
 */
const isJpgView = viewingInterface => {
  return viewingInterface === constants.PAGE_TURN || viewingInterface === constants.STACKABLE;
};

/**
 * @param {OnlineMag['viewingInterface']} viewingInterface
 * @returns {string}
 */
const getTestSuffix = viewingInterface => {
  switch (viewingInterface) {
    case constants.HIGH_CONTRAST:
      return 'hc';
    case constants.HTML_PAGE_TURN:
      return 'html';
    case constants.HTML_STACKABLE:
      return 'htmls';
    case constants.PAGE_TURN:
      return 'pt';
    case constants.STACKABLE:
      return 's';

    default:
      return 'pt';
  }
};

/**
 * @param {OnlineMag['viewingInterface']} viewingInterface
 * @returns {boolean}
 */
const isPageTurnMode = viewingInterface => {
  return viewingInterface === constants.PAGE_TURN || viewingInterface === constants.HTML_PAGE_TURN;
};

/**
 * @param {KeyboardEvent} e
 * @returns {void}
 */
const preventPageTurnWithinPolls = e => {
  // Stop the event from bubbling up and triggering a page turn
  if (e.target instanceof HTMLInputElement && e.target.classList.contains('js-poll-item')) {
    e.stopPropagation();
  }
};

/**
 * OliJSON Helper
 * @param {string} s
 * @returns {boolean}
 */
const isTrue = s => s === 'true';

/**
 * OliJSON Helper
 * @param {string|undefined} str
 * @returns {string}
 */
const defaultToEmptyString = str => (str === undefined ? '' : str);

/**
 * Lang String Helper
 * @param {string|undefined} key
 * @param {object[]|undefined} replacements?
 * @returns {string}
 */
const getI18nByKey = (key = '', /** @type {string[]} */ ...replacements) => {
  const langs = window?.langs;
  if (!langs) return '';
  if (!key) return '';

  const inputString = langs[key] || key;

  // return langs[key];
  return inputString
    .replace(
      /\{\{(\d+)\}\}/g,
      (/** @type {string} */ match, /** @type {string | number} */ index) => {
        return replacements[index] || match;
      }
    )
    .trim()
    .replace(/\s+/g, ' ');
};

/**
 * Handles invalid form elements by setting custom validation messages.
 * @param {Event} event - The event object.
 * @returns { any } - An empty string if `langs` is falsy.
 */
const handleInvalidFormElement = event => {
  /**
   * The input element that triggered the event.
   * @type {HTMLInputElement}
   */
  const inputElement = event.target;

  const langs = window?.langs;

  if (!langs) {
    return '';
  }

  /**
   * Returns an object containing error messages for form validation.
   * @returns {{
   *   MissingValue: Object.<string, string>,
   *   OutOfRange: Object.<string, string>,
   *   PatternMismatch: Object.<string, string>,
   *   WrongLength: Object.<string, string>
   * }}
   */
  function errorMessageParser() {
    return Object.entries(langs).reduce(
      (acc, [key, value]) => {
        if (key.includes('FormErrorMessages')) {
          const keys = key.split('.');
          const errorType = keys[1];
          const errorSubType = keys[2];

          if (!acc[errorType]) {
            acc[errorType] = {};
          }

          acc[errorType][errorSubType] = value;
        }
        return acc;
      },
      {
        MissingValue: {},
        OutOfRange: {},
        PatternMismatch: {},
        WrongLength: {}
      }
    );
  }

  const errorMessages = errorMessageParser();

  /**
   * Replaces placeholders in a string with the provided values.
   * @param {string} inputString - The string to modify.
   * @param {(string | number)[]} replacements - The values to replace the placeholders with.
   * @returns {string} - The modified string.
   */
  const varReplacer = (inputString = '', ...replacements) => {
    return inputString
      .replace(
        /\{\{(\d+)\}\}/g,
        (/** @type {string} */ match, /** @type {string | number} */ index) => {
          return replacements[index] || match;
        }
      )
      .trim()
      .replace(/\s+/g, ' ');
  };

  // Destructure validity object. Grab each error type.
  const {
    patternMismatch,
    tooLong,
    tooShort,
    rangeOverflow,
    rangeUnderflow,
    typeMismatch,
    valid,
    valueMissing
  } = inputElement.validity;

  // Grab each validation attribute from the input
  /**
   * The minimum length of the input element's value.
   * @type {string|number}
   */
  const minLength = inputElement.minLength;

  /**
   * The minimum value of the input element.
   * @type {string|number}
   */
  const min = inputElement.min;

  /**
   * The maximum length of the input element's value.
   * @type {string|number}
   */
  const maxLength = inputElement.maxLength;

  /**
   * The maximum value of the input element.
   * @type {string|number}
   */
  const max = inputElement.max;

  /**
   * The value of the input element.
   * @type {string|number}
   */
  const value = inputElement.value;

  /**
   * Capitalizes the first letter of a string.
   * @param {string} str - The string to capitalize.
   * @returns {string} - The capitalized string.
   */
  function capitalize(str) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Capitalize the type of input - .net capitalises everything... the browser api camelCases everything
  const type = capitalize(inputElement.type);

  inputElement.setCustomValidity(''); // reset our validation message to default

  // now the fun bit - check each error type and set the custom validity message
  // we check for the most specific error first, then work our way down to the most generic
  // we use an object to store the error messages, so we can easily add new ones in the future
  // by looking up the error type and sub type we reduce the amount of cases we need to check.
  if (patternMismatch) {
    inputElement.setCustomValidity(
      errorMessages.PatternMismatch[type] || errorMessages.PatternMismatch.Default
    );
  } else if (tooLong) {
    inputElement.setCustomValidity(
      varReplacer(errorMessages.WrongLength.Over, maxLength, value.length)
    );
  } else if (tooShort) {
    console.log(errorMessages.WrongLength.Under);
    console.log(varReplacer(errorMessages.WrongLength.Under, minLength, value.length));
    inputElement.setCustomValidity(
      varReplacer(errorMessages.WrongLength.Under, minLength, value.length)
    );
  } else if (rangeOverflow) {
    inputElement.setCustomValidity(varReplacer(errorMessages.OutOfRange.Over, max));
  } else if (rangeUnderflow) {
    inputElement.setCustomValidity(varReplacer(errorMessages.OutOfRange.Under, min));
  } else if (typeMismatch) {
    inputElement.setCustomValidity(
      errorMessages.PatternMismatch[type] || errorMessages.PatternMismatch.Default
    );
  } else if (valid) {
    inputElement.setCustomValidity(''); // if valid, reset to ''
  } else if (valueMissing) {
    inputElement.setCustomValidity(
      (errorMessages.MissingValue && errorMessages.MissingValue[type]) ||
        errorMessages.MissingValue.Default
    );
  } else {
    inputElement.setCustomValidity('');
  }
};

export {
  addSeconds,
  attractModeStart,
  attractModeStillActive,
  closePopupLinkClicked,
  containsHtml,
  addListener,
  delay,
  defaultToEmptyString,
  destroyChart,
  destroyCharts,
  fromNullable,
  getChartOptions,
  getCookie,
  getCustomChartSizes,
  getDomElement,
  getDomElements,
  getBy,
  getI18nByKey,
  getLayout,
  getModuleID,
  getModuleSelector,
  getPageDetails,
  getPageShadow,
  getProofingToken,
  getRequestedInitialPage,
  getRequiredModulesForCurrentPages,
  getFirstPageIndexWithIncompleteRequiredModules,
  getFirstPageWithIncompleteRequiredModules,
  getLeftRightMarginSize,
  getOrientation,
  getRequiredModuleMessageAndSelectors,
  getTestSuffix,
  getValidatedEmail,
  goToPageGuid,
  hasVirtualKeyboard,
  handleInvalidFormElement,
  hideLeftRightArrows,
  htmlEncode,
  isFunction,
  isJpgView,
  isPageTurnMode,
  isNotNil,
  isNilOrBlank,
  isSecure,
  isTrue,
  isVideoPlaying,
  lowestVisiblePageIndex,
  log,
  makeHtml,
  noOp,
  numberOfPixelsInViewport,
  openWindow,
  outputFontsStyles,
  parseHtmlString,
  parseJson,
  parseUrl,
  pause,
  percentageInViewport,
  preventPageTurnWithinPolls,
  forceReload,
  play,
  playVideo,
  pauseVideo,
  prefersReducedMotion,
  removeElement,
  rewriteLinksToUseSSL,
  removeListener,
  rewriteToUseSSL,
  setText,
  sendBeacon,
  safeNumberFromString,
  showRequiredInteractivityModal,
  tryCatch,
  urlEncodeIfNecessary,
  validateEmail
};
