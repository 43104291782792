/* eslint-disable sort-keys, import/max-dependencies, id-length, complexity, max-statements, array-callback-return, max-lines-per-function */
import $ from 'jquery';
import Async from 'crocks/Async';
import Maybe from 'crocks/Maybe';
import safe from 'crocks/Maybe/safe';
import getProp from 'crocks/Maybe/getProp';
import F from 'ramda/src/F';
import pipe from 'ramda/src/pipe';
import defaultTo from 'ramda/src/defaultTo';
import map from 'ramda/src/map';
import evolve from 'ramda/src/evolve';

import { delay, goToPageGuid, isNotNil, parseJson, playVideo, safeNumberFromString } from './utils';
import { currentVisiblePageIndexes } from './page-helpers';
import * as constants from './constants';

import type { Guid } from './guid';
import type { OnlineMagPageVideo } from './interactivity/video';
import { EventTypes, MODULE_SUB_TYPES } from './constants';

const { ACTION_TYPES, MODULE_TYPES } = constants;

//
// OVERVIEW
//

/*

  1. Each piece of interactivity will be initialised with an array of events (also known as chains).
  2. At a "activiation point" such as opening or closing a popup, completing a competition
     we call `fireEvents` with the array of events. Here the events are either fired instantly
     or a timer is set for when the event should be fired (this delay amount is the `delayMs`
     property on an event)
  3. When an event fires, it's pushed into the `handleChainActionEvent` function which switches on
     the  `actionTypeID` to execute the correct action.
  4. Any delayed events can be cancelled. For example, an event to go page 15 with a delay of 10
     seconds can be cancelled if the user manually turns the page within those 10 seconds. If this
     happens then `handleCancelAndRemoveEvents` is called to cancel the time and remove the event
     from the array of active events.

 */

//
// TYPES
//

type EventBase = {
  actionModuleGuid: any;
  alwaysFire: boolean;
  cancelFn: any;
  delayMs: number;
  eventArgs: any;
  eventTypeID: EventTypes;
  sourceModuleGuid: Guid;
  sourceModuleType: 'MODULE' | 'PAGE';
  pageIndex: number;
};

type GoToPageIndexEvent = {
  actionTypeID: typeof ACTION_TYPES.GO_TO_PAGE_INDEX;
  actionArgs: [boolean, number];
} & EventBase;

type GoToPageGuidEvent = {
  actionTypeID: typeof ACTION_TYPES.GO_TO_PAGE_GUID;
  actionArgs: [boolean];
} & EventBase;

type GoToPrevPageEvent = {
  actionTypeID: typeof ACTION_TYPES.GO_TO_PREV_PAGE;
  actionArgs: [boolean];
} & EventBase;

type GoToNextPageEvent = {
  actionTypeID: typeof ACTION_TYPES.GO_TO_NEXT_PAGE;
  actionArgs: [boolean];
} & EventBase;

type ActivateModuleEvent = {
  actionTypeID: typeof ACTION_TYPES.ACTIVATE_MODULE;
  actionArgs: [boolean, boolean];
} & EventBase;

type CloseModuleEvent = {
  actionTypeID: typeof ACTION_TYPES.CLOSE_MODULE;
  actionArgs: [];
} & EventBase;

type PlayVideoEvent = {
  actionTypeID: typeof ACTION_TYPES.PLAY_VIDEO;
  actionArgs: [boolean, boolean, number];
} & EventBase;

export type Event =
  | GoToPageIndexEvent
  | GoToPageGuidEvent
  | GoToPrevPageEvent
  | GoToNextPageEvent
  | ActivateModuleEvent
  | CloseModuleEvent
  | PlayVideoEvent;

type ActionArgs = Event['actionArgs'];

type NestedEventData = {
  chainData: {
    chains: Event[];
  };
};

//
// EVENT HELPERS
//

/**
 * Processes a pipe delimited string ("true|false|3|true") and returns
 * an array of numbers and booleans ([true, false, 3, true])
 */
const parsePipeDelimitedString = (str: string) => {
  return str.split('|').reduce((acc, item) => {
    if (item === '') {
      return acc as ActionArgs;
    }

    if (item.toLowerCase() === 'true' || item.toLowerCase() === 'false') {
      return [...acc, item.toLowerCase() === 'true'] as ActionArgs;
    }

    return [...acc, Number(item)] as ActionArgs;
  }, [] as ActionArgs);
};

/**
 * Applies a variety of functions to ensure our event is setup with the correct values
 */
const eventTransforms = {
  actionArgs: pipe(defaultTo(''), parsePipeDelimitedString),
  actionModuleGuid: safe(isNotNil),
  eventArgs: safe(isNotNil),
  blockFire: F
};

// @ts-ignore
const transformEvent = (cs: Event[]): Event[] => map(evolve(eventTransforms), cs);

/**
 * Parse the incoming JSON and apply the correct transformations to the events
 */
const parseChainData = (eventJson: string): Event[] => {
  return parseJson(eventJson)
    .either(Maybe.Nothing, Maybe.Just)
    .chain(getProp('chains'))
    .map(transformEvent)
    .option([]);
};

/**
 * Used for the competition events which are nested
 */
const parseChainDataFromParsedJson = (eventData: NestedEventData): Event[] => {
  return getProp('chains', eventData).map(transformEvent).option([]);
};

/**
 * Force a delayed execution of a an event
 */
const delayEvent = (event: Event): any =>
  Async((_reject, resolve) => {
    const token = setTimeout(() => resolve(event), event.delayMs);

    return () => {
      clearTimeout(token);
    };
  });

/**
 * Called at the point of time events need to be triggered
 */
const fireEvents = (eventType: Event['eventTypeID'], events: Event[]): any[] => {
  return events
    .filter(event => event.eventTypeID === eventType)
    .map(event => (event.delayMs > 0 ? delayEvent(event) : Async.of(event)))
    .map((asyncEvent, index) => {

        const event = events[index];

        if (event.delayMs > 0) 
        {
          let isDuplicate = window.ptiGetInstance().activeEvents.some(existingEvent =>
              event.actionTypeID === existingEvent.actionTypeID &&
              event.delayMs === existingEvent.delayMs &&
              event.sourceModuleGuid === existingEvent.sourceModuleGuid &&
              event.sourceModuleType === existingEvent.sourceModuleType &&
              event.pageIndex === existingEvent.pageIndex);
  
          if(!isDuplicate)
          {
            const cancelFn = asyncEvent.fork(() => {}, handleChainActionEvent);
            addEvent({ ...event, cancelFn: Maybe.Just(cancelFn) });
          }
        }
        else
        {
          asyncEvent.fork(() => {}, handleChainActionEvent);
        }

    });
};

/**
 * @param {string} guid
 * @returns {void}
 */
const activateModule = (guid: Guid): void => {
  const { onlineMagPages } = window.ptiGetInstance();
  const [module] = onlineMagPages.items
    .flatMap(page => page.modules)
    .filter(mod => mod.moduleGuid === guid);

  if (module) {
    window.ptiLinkClicked(null, module.moduleType, module.id);
  }
};

/**
 *
 */
const goToPageByModuleGuid = (moduleGuid: Guid, suppressChainOnPageShow: boolean): void => {
  const pageTurn = window.ptiGetInstance();
  const page = pageTurn.pages.find(page => page.modules.find(mod => mod.moduleGuid === moduleGuid));

  if (page && Number.isInteger(page.pageIndex)) {
    delay(10).then(() => window.ptiGotoPage(null, page.pageIndex, suppressChainOnPageShow, true));
  }
};

/**
 * @param {string} moduleGuid
 * @returns {boolean}
 */
const isModuleCurrentlyVisible = (moduleGuid: Guid): boolean => {
  const pageTurn = window.ptiGetInstance();
  const page = pageTurn.pages.find(page => page.modules.find(mod => mod.moduleGuid === moduleGuid));
  
  return page ? currentVisiblePageIndexes(pageTurn).includes(page.pageIndex) : false;
};

/**
 * @param {string} moduleGuid
 * @returns {any}
 */
 const getModuleByModuleGuid = (moduleGuid: Guid): any => {
  const pageTurn = window.ptiGetInstance();
  const page = pageTurn.pages.find(page => page.modules.find(mod => mod.moduleGuid === moduleGuid));
  const module = page?.modules.find(mod => mod.moduleGuid === moduleGuid);
  
  return module;
};


//
// EVENT HANDLERS
//

/**
 * Called when an event needs to be actioned.
 */
const handleChainActionEvent = (event: Event): Promise<void | (() => void)> => {
  const pageTurn = window.ptiGetInstance();
  pageTurn.activeEvents = removeActiveEvent(pageTurn.activeEvents, event);

  switch (event.actionTypeID) {
    case ACTION_TYPES.GO_TO_PAGE_INDEX: {
      const [suppressChainOnPageShow = false, pageIndex] = event.actionArgs;

      return delay(10).then(() => window.ptiGotoPage(null, pageIndex, suppressChainOnPageShow));
    }

    case ACTION_TYPES.GO_TO_PAGE_GUID: {
      const [suppressChainOnPageShow = false] = event.actionArgs;

      return event.actionModuleGuid.map((guid: Guid) => {
        delay(10).then(() => goToPageGuid(null, guid, suppressChainOnPageShow));
      });
    }

    case ACTION_TYPES.GO_TO_PREV_PAGE: {
      const [suppressChainOnPageShow = false] = event.actionArgs;

      return delay(10).then(() => window.ptiPreviousPage(null, suppressChainOnPageShow));
    }

    case ACTION_TYPES.GO_TO_NEXT_PAGE: {
      const [suppressChainOnPageShow = false] = event.actionArgs;

      return delay(10).then(() => window.ptiNextPage(null, suppressChainOnPageShow));
    }

    case ACTION_TYPES.ACTIVATE_MODULE: {
      event.actionModuleGuid.map((actionModuleGuid: Guid) => {
        const [suppressChainOnPageShow = false, jumpToPage = true] = event.actionArgs;
        const shouldGoToPage = jumpToPage && !isModuleCurrentlyVisible(actionModuleGuid);
        const showDelay = shouldGoToPage ? 500 : 110;
        const module = getModuleByModuleGuid(actionModuleGuid);
        const moduleSubType = module?.subType;
        const isOnPageVideoOrChart = (moduleSubType === MODULE_SUB_TYPES.CHART || moduleSubType === MODULE_SUB_TYPES.VIDEO);

        if (shouldGoToPage || isOnPageVideoOrChart) {
          goToPageByModuleGuid(actionModuleGuid, suppressChainOnPageShow);
        }

        if (!isOnPageVideoOrChart) {
          delay(showDelay).then(() => window.activateModule(actionModuleGuid));
        }
      });

      return null;
    }

    case ACTION_TYPES.PLAY_VIDEO: {
      event.actionModuleGuid.map((actionModuleGuid: Guid) => {
        const [suppressChainOnPageShow = false, jumpToPage = false, videoTime] = event.actionArgs;
        const shouldGoToPage = jumpToPage && !isModuleCurrentlyVisible(actionModuleGuid);
        const playDelay = shouldGoToPage ? 1500 : 100;

        if (shouldGoToPage) {
          goToPageByModuleGuid(actionModuleGuid, suppressChainOnPageShow);
        }

        delay(playDelay).then(() => {
          const pt = window.ptiGetInstance();
          const video = pt.allOnlineMagPageVideos.find(
            (vid: OnlineMagPageVideo) => vid.moduleGuid === actionModuleGuid
          ) as OnlineMagPageVideo | null;

          if (video && video.isPopUp()) {
            if (videoTime) {
              video.forceCurrentTime = videoTime.toString();
            }

            delay(playDelay).then(() => window.activateModule(actionModuleGuid));
          } else {
            event.actionModuleGuid.map((moduleGuid: Guid) => playVideo(moduleGuid, videoTime));
          }
        });
      });

      return null;
    }

    case ACTION_TYPES.CLOSE_MODULE: {
      cancelAndRemoveEvents(MODULE_TYPES.MODULE);

      if ($.fn.ptibox.isVisible()) {
        delay(10).then(window.closePopUp);
      }

      return null;
    }
  }
};

/**
 * Once an event has been fired we need to remove it from the array of activeEvents
 */
const removeActiveEvent = (event: Event[], eventToRemove: Event) => {
  let events = [
    ...event.filter(
      currentEvent =>
        !(
          currentEvent.actionTypeID === eventToRemove.actionTypeID &&
          currentEvent.eventTypeID === eventToRemove.eventTypeID &&
          currentEvent.delayMs === eventToRemove.delayMs &&
          currentEvent.sourceModuleGuid === eventToRemove.sourceModuleGuid &&
          currentEvent.pageIndex === eventToRemove.pageIndex
        )
    )
  ]

  return events ;
};


/**
 * Called from moving to a new page or closing a module. 
 * This calls the cancel function of any events that are in the supplied events collection that match the event type (module/page)
 * Unless that event is of an excluded type.
 * Returns an array of events that are still delayed (active)
 */
const handleCancelAndRemoveEvents = (
  events: Event[],
  excludeEventType: EventTypes = null
) => {
 
  let eventsToCancel = [...events.filter(event => event.eventTypeID !== excludeEventType && !event.alwaysFire)];
  let eventsToNotCancel = events.filter(x => !eventsToCancel.includes(x));
 
  eventsToCancel.map(event => event.cancelFn.map(cancelFn => cancelFn()));
  return eventsToNotCancel;
};



const addEvent = (event: Event) => {
  window.ptiGetInstance().activeEvents.push(event);
};

/**
 * Removes all events by the source type.
 */
const cancelAndRemoveEvents = (excludeEventType: EventTypes = null) => {
  const pageTurn = window.ptiGetInstance();
  pageTurn.activeEvents = handleCancelAndRemoveEvents(pageTurn.activeEvents, excludeEventType);
};

window.activateModule = activateModule;

export {
  activateModule,
  addEvent,
  delayEvent,
  parsePipeDelimitedString,
  cancelAndRemoveEvents,
  fireEvents,
  handleCancelAndRemoveEvents,
  handleChainActionEvent,
  parseChainData,
  parseChainDataFromParsedJson,
  safeNumberFromString,
  transformEvent
};
