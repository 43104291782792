// @ts-nocheck
/* eslint-disable */

import {
  delay,
  getDomElement,
  getDomElements,
  isFunction,
  getFirstPageIndexWithIncompleteRequiredModules, getI18nByKey
} from './utils';

import { MANDATORY_CLASS } from './constants';

/** @constant
    @type {string}
*/
const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]):not([aria-hidden]), select:not([disabled]):not([aria-hidden]), textarea:not([disabled]):not([aria-hidden]), button:not([disabled]):not([aria-hidden]), [tabindex="0"]';

/** @constant
    @type {string}
*/
const INERT_ELEMENTS = '.js-doc-container, .js-paging-nav, .intercom-launcher';

/** @constant
    @type {string}
*/
const BODY_LOCK_CLASS = 'mod-lightbox-open';

/** @constant
    @type {string}
*/
const LIGHTBOX_CLASS = 'js-lightbox-message';

/**
 * @param {boolean} enabledDocInert
 * @returns {void}
 */
const toggleInert = enabledDocInert => {
  getDomElements(INERT_ELEMENTS).map(el =>
    enabledDocInert ? el.setAttribute('inert', '') : el.removeAttribute('inert')
  );

  getDomElement(`.${LIGHTBOX_CLASS}`).map(el => {
    if (enabledDocInert) {
      el.removeAttribute('inert');
      el.removeAttribute('aria-hidden', 'false');
    } else {
      el.setAttribute('inert', '');
      el.setAttribute('aria-hidden', 'true');
    }
  });
};

/**
 * @returns {void}
 */
const focusOnFirstItem = () => {
  delay(20).then(() => {
    toggleInert(true);
    getDomElement(`.${LIGHTBOX_CLASS}`).map(lightbox =>
      lightbox.querySelector(FOCUSABLE_ELEMENTS)?.focus()
    );
  });
};

(function ($) {
  var opts;
  var busy = false;
  /** @type {?Element} */
  var activeElement;

  $.fn.ptimessage = function (settings) {
    document.body.classList.add(BODY_LOCK_CLASS);
    settings = $.extend({}, $.fn.ptimessage.defaults, settings);

    function _initialize() {
      opts = settings;

      _start();
    }

    function _start() {
      if (busy) {
        return;
      }

      // disableBodyScroll(lightboxID);

      if (typeof opts.callbackOnStart === 'function') {
        opts.callbackOnStart();
      }

      opts.itemArray = settings.itemArray || [];
      opts.itemCurrent = 0;

      if (opts.overlayShow) {
        $('#ptimessage_overlay').css('opacity', opts.overlayOpacity.toString()).show();
      }

      $.fn.ptimessage.changeItem();
      $('#ptimessage_caption').hide();
    }

    _initialize();

    return this.off('click');
  };

  $.fn.ptimessage.changeItem = function () {
    activeElement = document.activeElement;
    $('#ptimessage_close, #ptimessage_caption').hide();

    var htmlContent = opts.itemArray[opts.itemCurrent].htmlContent || '';
    var htmlButton = opts.itemArray[opts.itemCurrent].htmlButton || '';
    var width = opts.frameWidth;
    var height = opts.frameHeight;

    if (opts.itemArray[opts.itemCurrent].frameWidth != null) {
      width = opts.itemArray[opts.itemCurrent].frameWidth;
    }

    if (opts.itemArray[opts.itemCurrent].frameHeight != null) {
      height = opts.itemArray[opts.itemCurrent].frameHeight;
    }

    $.fn.ptimessage.setContent(
      `<div id="ptimessage_div">
				<div class="class="responsive-container" id="ptimessage_div_inner" style="padding: ${opts.margin}px;">
					${htmlContent}
				</div>
			</div>`,
      htmlButton,
      width,
      height
    );

    $('#ptimessage_title').text(opts.title);
    getDomElement(`.${LIGHTBOX_CLASS}`).map(el => el.removeAttribute('inert'));
    focusOnFirstItem();
  };

  $.fn.ptimessage.setButton = function setButton(button = '', buttonHeight) {
    $('#ptimessage_button_inner').html(button);
    $('#ptimessage_button').css({ display: button ? 'block' : 'none' });

    if (button) {
      $('#ptimessage_div').addClass('mod-button');
    }
  };

  $.fn.ptimessage.setBorderAndBGColor = function (options) {
    const {
      borderColour = '',
      backgroundColour = '',
      cssClass = '',
      infoTextColour = ''
    } = options;
    const border = borderColour ? borderColour : '#fff';
    const background = backgroundColour ? backgroundColour : 'trasparent';

    $('#ptimessage_inner').css({
      borderWidth: '10px',
      borderColor: border,
      backgroundColor: background
    });

    $('#ptimessage_wrap').removeClass().addClass(cssClass);
  };

  $.fn.ptimessage.setContent = function (value, button, width, height) {
    busy = true;
    const $ptiOuter = $('#ptimessage_outer');
    const $ptiContent = $('#ptimessage_content');
    const $ptiInner = $('#ptimessage_inner');
    const isVisible = $ptiOuter.is(':visible');
    const { easingChange, padding, zoomSpeedChange } = opts;
    const buttonHeight = button ? 58 : 0;
    // The 20px padding comes from the CSS
    const paddingTotal = padding * 2 + 20 * 2;
    const isResponsiveItem = Boolean(value.match(/responsive-container/));

    $ptiContent.css({ padding: `${padding}px` });

    var widthWithResponsiveNess =
      width > document.documentElement.clientWidth ? '100%' : `${width + paddingTotal}px`;
    var heightWithResponsiveNess =
      height > document.documentElement.clientHeight
        ? '100%'
        : `${height + buttonHeight + paddingTotal}px`;

    var boxSize = {
      // If we have a iframe or video, then we need to set the height to auto.
      height: isResponsiveItem ? 'auto' : heightWithResponsiveNess,
      width: widthWithResponsiveNess
    };

    $.fn.ptimessage.setBorderAndBGColor(opts);

    if (isVisible) {
      const height = $ptiInner.height() + 'px';
      $ptiInner.css({ height });

      $ptiContent.fadeOut('normal', function () {
        $ptiContent.empty();
        $ptiOuter.animate(boxSize, zoomSpeedChange, easingChange, function () {
          $ptiInner.css({ height: 'auto' });
          $ptiContent.append($(value));

          if (opts.callbackOnSetContent) {
            opts.callbackOnSetContent();
          }

          $.fn.ptimessage.setButton(button, buttonHeight);
          $ptiContent.fadeIn('normal', $.fn.ptimessage.finish);
        });
      });
    } else {
      $ptiContent.hide().empty().append($(value)).show();

      if (opts.callbackOnSetContent) {
        opts.callbackOnSetContent();
      }

      $.fn.ptimessage.setButton(button, buttonHeight);

      $ptiOuter.css(boxSize).css('display', 'block').fadeIn('normal', $.fn.ptimessage.finish);
    }
  };

  $.fn.ptimessage.finish = function () {
    $(document)
      .off('keydown.message')
      .on('keydown.message', function (e) {
        var keyCode = e.keyCode || e.which;
        var arrow = { esc: 27, left: 37, up: 38, right: 39, down: 40 };

        if (keyCode == arrow.esc && opts.hideOnEscape) {
          $(document).off('keydown.message');
          $.fn.ptimessage.close();
        }
      });

    if (opts.hideOnContentClick) {
      $('#ptimessage_wrap').on('click', $.fn.ptimessage.close);
    }

    if (opts.hideOnOverlayClick) {
      $('#ptimessage_overlay').off('click').on('click', $.fn.ptimessage.close);
    } else {
      $('#ptimessage_overlay').off('click');
    }

    $('#ptimessage_close').off().on('click', $.fn.ptimessage.close);

    if (opts.showCloseButton) {
      $('#ptimessage_close').show();
    } else {
      $('#ptimessage_close').hide();
    }

    if (isFunction(opts.callbackOnShow)) {
      opts.callbackOnShow();
    }

    busy = false;
  };

  $.fn.ptimessage.showAlert = function (
    message,
    buttonBGColour,
    buttonTextColour,
    width = 400,
    height = 200,
    callbackOnOK,
    callbackOnClose,
    borderColour,
    focusableModule
  ) {
    const button = `
      <button id="ptimessage_ok" class="lightbox-button-bg" data-t="modal-ok">
				<span class="lightbox-button-bg-span">${getI18nByKey('Buttons.Ok')}</span>
			</button>`;

    $().ptimessage({
      itemArray: [
        $.fn.ptimessage.generateItemObject(message, button, '', '', null, width, height, null)
      ],
      borderColour: borderColour,
      showInfo: 2,
      showCloseButton: true,
      hideOnEscape: true,
      hideOnOverlayClick: true,
      showPlayButton: false,
      callbackOnOK: callbackOnOK,
      callbackOnShow: function callbackOnShow() {
        $('#ptimessage_ok').on('click', function () {
          typeof callbackOnOK === 'function' && callbackOnOK();

          $().ptimessage.close();
        });

        $('#ptimessage_ok').get(0).focus();
      },
      callbackOnClose: callbackOnClose,
      title: 'Alert',
      focusableModule
    });
  };

  $.fn.ptimessage.showYesNo = function (
    message,
    width = 400,
    height = 200,
    callbackOnYes,
    callbackOnNo,
    callbackOnClose
  ) {
    const button = `
      <button id="ptimessage_yes" class="lightbox-button-bg">
        <span class="lightbox-button-bg-span">${getI18nByKey('Buttons.Yes')}</span>
      </button>
      <button id="ptimessage_no" class="lightbox-button-bg">
        <span class="lightbox-button-bg-span">${getI18nByKey('Buttons.No')}</span>
      </button>`;

    $().ptimessage({
      itemArray: [
        $.fn.ptimessage.generateItemObject(message, button, '', '', null, width, height, null)
      ],
      borderColour: '#fff',
      showInfo: 2,
      showCloseButton: false,
      hideOnEscape: false,
      hideOnOverlayClick: false,
      showPlayButton: false,
      callbackOnYes: callbackOnYes,
      callbackOnNo: callbackOnNo,
      callbackOnShow: function callbackOnShow() {
        $('#ptimessage_yes').on('click', function () {
          typeof callbackOnYes === 'function' && callbackOnYes();

          $().ptimessage.close();
        });

        $('#ptimessage_no').on('click', function () {
          typeof callbackOnNo === 'function' && callbackOnNo();

          $().ptimessage.close();
        });

        $('#ptimessage_yes').get(0).focus();
      },
      callbackOnClose: callbackOnClose,
      title: ''
    });
  };

  $.fn.ptimessage.isVisible = function () {
    return $('#ptimessage_outer').is(':visible');
  };

  $.fn.ptimessage.close = function (pobjCallbackOnClose) {
    document.body.classList.remove(BODY_LOCK_CLASS);

    toggleInert(false);

    delay(50).then(() => {
      // Revert focus back to the original element...
      if (!opts.focusableModule && activeElement && activeElement.focus) {
        activeElement.focus();
      }

      if (opts.focusableModule && opts.focusableModule.focus) {
        opts.focusableModule.focus();
      }
    });

    busy = true;

    $('.lightbox-content').scrollTop(0);
    $('#ptimessage_wrap').addClass('mod-hidden');

    if (isFunction(opts.callbackOnBeforeClose) && opts) {
      opts.callbackOnBeforeClose();
    }

    if (pobjCallbackOnClose != null) {
      opts.callbackOnClose = pobjCallbackOnClose;
    }

    $(document).off('keydown.message');
    $('#ptimessage_overlay, #ptimessage_inner, #ptimessage_close').off();

    if (opts.hideOnContentClick) {
      $('#ptimessage_wrap').off();
    }

    $('#ptimessage_close').hide();
    $('#ptimessage_div').scrollTop(0);

    $('#ptimessage_overlay, #ptimessage_outer').hide();
    $('#ptimessage_content').empty();

    if (isFunction(opts.callbackOnClose)) {
      opts.callbackOnClose();
    }

    busy = false;
  };

  $.fn.ptimessage.build = function () {
    $(`
      <div class="lightbox-overlay mod-message" id="ptimessage_overlay"></div>

      <div class="lightbox-wrap mod-hidden" id="ptimessage_wrap">

      <div id="ptimessage_wrap_inner">

        <div class="lightbox-outer mod-message ${LIGHTBOX_CLASS}" id="ptimessage_outer" aria-labelledby="ptibox_title" role="dialog" aria-modal="true" inert aria-hidden="true">
          <div id="ptimessage_title" class="visually-hidden"></div>
          <div class="lightbox-inner" id="ptimessage_inner">
            <div class="lightbox-close" id="ptimessage_close"></div>
            <div id="ptimessage_bg"></div>
            <div class="lightbox-content" id="ptimessage_content" data-t="lightbox-message"></div>

            <div id="ptimessage_button" class="lightbox-button">
              <div class="lightbox-button-inner" id="ptimessage_button_inner"></div>
            </div>

          </div>
        </div>
      </div>

      </div>
    `).appendTo('body');
  };

  $.fn.ptimessage.defaults = {
    padding: 10,
    borderColour: '',
    margin: 20,
    backgroundColour: '',
    cssClass: '',
    zoomOpacity: false,
    zoomSpeedOut: 0,
    zoomSpeedChange: 300,
    easingIn: 'swing',
    easingOut: 'swing',
    easingChange: 'swing',
    frameWidth: 500,
    frameHeight: 400,
    overlayShow: true,
    overlayOpacity: 0.8,
    hideOnContentClick: false,
    itemArray: [],
    autoPlay: false,
    interval: 3 /* pause between images (in seconds) */,
    continuous: false /* if true goes to 1st image after last */,
    showInfo: 0 /* OnClick = 0, Always = 1, Never = 2 */,
    infoTextColour: '',
    callbackOnStart: null,
    callbackOnShow: null,
    callbackOnSetContent: null,
    callbackOnBeforeClose: null,
    callbackOnClose: null,
    showPlayButton: true,
    showCloseButton: true,
    hideOnEscape: true,
    hideOnOverlayClick: true
  };

  $.fn.ptimessage.itemDefaults = function () {
    return {
      htmlContent: '',
      href: '',
      title: '',
      orig: null,
      frameWidth: 500,
      frameHeight: 400,
      infoPosition: 0
    };
  };

  $.fn.ptimessage.generateItemObject = function (
    htmlContent,
    htmlButton,
    href,
    title,
    orig,
    frameWidth,
    frameHeight,
    infoPosition
  ) {
    return {
      htmlContent: htmlContent,
      htmlButton: htmlButton,
      href: href,
      title: title,
      orig: orig,
      frameWidth: frameWidth,
      frameHeight: frameHeight,
      infoPosition: infoPosition
    };
  };

  $($.fn.ptimessage.build);
})($);
