import Maybe from 'crocks/Maybe';
import getProp from 'crocks/Maybe/getProp';
import $ from 'jquery';
import { object, boolean, number, string, Infer, is } from 'superstruct';

import { AUTH_REQUIRED } from './constants';
import { assoc } from './basic-utils';
import {
  addListener,
  getDomElement,
  getDomElements,
  isVideoPlaying,
  pauseVideo,
  playVideo,
  removeElement,
  removeListener,
  setText
} from './utils';

import type { OnlineMag } from './@types/pagetiger';

/////////////
/// TYPES ///
/////////////

const AuthRawResponse = object({
  authenticated: boolean(),
  timeoutSeconds: number()
});

const AuthResponse = object({
  authenticated: boolean(),
  timeoutMilliseconds: number()
});

const ErrorResponse = object({
  errorMessage: string()
});

type AuthRawResponse = Infer<typeof AuthRawResponse>;
type AuthResponse = Infer<typeof AuthResponse>;
type ErrorResponse = Infer<typeof ErrorResponse>;

type AuthPath = null | 'Reader' | 'ReaderV2' | 'EmployeeNo' | 'sso';

type AuthFn = () => void;

type AuthNotRequired = Readonly<{
  [AUTH_REQUIRED]: false;
}>;

type AuthRequired = Readonly<{
  [AUTH_REQUIRED]: true;
  authFn: AuthFn;
  path: AuthPath;
  cancelToken: number | null;
  countdownToken: number | null;
}>;

export type Auth = AuthNotRequired | AuthRequired;

///////////////
/// GLOBALS ///
///////////////

const AUTH_WRAPPER_CLASS = 'js-auth-wrapper';
const ERROR_NOTIFICATION_CLASS = 'js-renew-error-notification';
const REMAIN_SIGNED_IN_CLASS = 'js-remain-signed-button';
const SHOW_CLASS = 'mod-show';
const COUNTDOWN_SELECTOR = 'js-count-down';
const TWO_MINS = 120_000;
const TIMEOUT_THRESHOLD = 5000;
const head = getProp(0);

const FETCH_OPTIONS: RequestInit = {
  cache: 'no-cache',
  credentials: 'include',
  method: 'GET',
  mode: 'cors'
};

/////////////////
/// FUNCTIONS ///
/////////////////

const formatSeconds = (timeRemaining: number): string => {
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const minutePlural = minutes === 1 ? `` : `s`;
  const minuteText = minutes === 0 ? `` : `${minutes} minute${minutePlural}`;
  const secondsText = seconds === 1 ? `${seconds} second` : `${seconds} seconds`;

  if (minutes === 1 && seconds === 0) {
    return '60 seconds';
  }

  return `${minuteText} ${secondsText}`.trim();
};

const renderTimeRemaining = (containerEl: any, time: number): void => {
  containerEl.map(el => {
    el.textContent = formatSeconds(time);
  });
};

const startCounter = (pageTurn: OnlineMag, displayTimeSeconds: number): void => {
  const timerEl = getDomElement(`.${COUNTDOWN_SELECTOR}`);

  var countdownToken = window.setInterval(() => {
    displayTimeSeconds = displayTimeSeconds - 1;

    if (displayTimeSeconds <= 0) {
      pageTurn.auth = clearAuthInterval(pageTurn.auth);
    } else {
      renderTimeRemaining(timerEl, displayTimeSeconds);
    }
  }, 1000);

  pageTurn.auth = updateAuthRequired(pageTurn.auth, {
    countdownToken: countdownToken
  });
};

const processResponse = (response: Response): Promise<AuthResponse> =>
  new Promise((resolve, reject) => {
    response.json().then((data: unknown) => {
      if (is(data, AuthRawResponse)) {
        return resolve({
          authenticated: data.authenticated,
          timeoutMilliseconds: data.timeoutSeconds * 1000
        });
      }

      return reject({
        errorMessage: is(data, ErrorResponse)
          ? data.errorMessage
          : 'Unexpected response from the server'
      });
    });
  });

const renewAuth =
  (pageTurn: OnlineMag): (() => void) =>
  () => {
    const errorMessageElement = getDomElement(`.${ERROR_NOTIFICATION_CLASS}`);

    errorMessageElement.map((el: HTMLDivElement) => el.classList.remove(SHOW_CLASS));

    fetch(`https://${pageTurn.host}/AuthRenew.aspx`, FETCH_OPTIONS)
      .then(processResponse)
      .then(() => {
        $.fn.ptimessage.close();
        authCheck(pageTurn);
      })
      .catch(error => {
        if (error instanceof Error) {
          window.errorReporter.sendError({
            col: 0,
            line: 0,
            message: `Renew: ${error.message}`,
            stack: error.stack,
            type: error.name
          });
        }

        errorMessageElement.map((el: HTMLDivElement) => {
          setText('There was a problem renewing your session. Please try again.', el);
          el.classList.add(SHOW_CLASS);
        });
      });
  };

const handleTimeoutWarning = (pageTurn: OnlineMag, timeRemainingMs: number): void => {
  var videoReference = Maybe.Nothing();
  var displayTimeSeconds = (timeRemainingMs - TIMEOUT_THRESHOLD) / 1000;
  const renewAuthCallback = renewAuth(pageTurn);

  if (isAuthModalActive()) {
    // The modal is already active we just need to
    startCounter(pageTurn, displayTimeSeconds);
  } else {
    // @ts-ignore
    $.fn.ptimessage({
      callbackOnClose() {
        videoReference.map(video =>
          playVideo(video.getAttribute('data-module-guid'), video.currentTime)
        );

        pageTurn.auth = clearAuthInterval(pageTurn.auth);
        getDomElement(`.${REMAIN_SIGNED_IN_CLASS}`).map(removeListener('click', renewAuthCallback));
        window.ptiHookupLeftRightArrows('');
      },
      callbackOnShow() {
        videoReference = head(
          getDomElements('video').filter(x => isVideoPlaying(x as HTMLVideoElement))
        );
        videoReference.map(pauseVideo);

        startCounter(pageTurn, displayTimeSeconds);

        getDomElement(`.${REMAIN_SIGNED_IN_CLASS}`).map(addListener('click', renewAuthCallback));
      },
      hideOnEscape: false,
      hideOnOverlayClick: false,
      itemArray: [
        $.fn.ptimessage.generateItemObject(
          `<p class="form-error ${ERROR_NOTIFICATION_CLASS}" aria-live="polite"></p>
            <h2 class="session-warning-heading ${AUTH_WRAPPER_CLASS}" data-t="session-expire-heading">Your session will expire in
              <br>
              <span class="session-warning-time ${COUNTDOWN_SELECTOR}">${formatSeconds(
            displayTimeSeconds
          )}</span>
            </h2>`,
          `<button class="ptibox_buttonBG lightbox-button-bg ${REMAIN_SIGNED_IN_CLASS}" data-t="remain-signed-in">
            <span class="lightbox-button-bg-span">Remain Logged In</span>
          </button>`,
          '',
          'Session Timeout',
          null,
          550,
          280,
          null
        )
      ],
      showCloseButton: false,
      showInfo: 2
    });
  }
};

const handleLogout = (pageTurn: OnlineMag): void => {
  getDomElement('[aria-label="paging navigation"]').map(removeElement);
  getDomElement(`#${pageTurn.containerElementID}`).map(removeElement);
  $().ptibox.close();

  // @ts-ignore
  $.fn.ptimessage({
    hideOnEscape: false,
    hideOnOverlayClick: false,
    itemArray: [
      $.fn.ptimessage.generateItemObject(
        `<h2 class="session-warning-heading ${AUTH_WRAPPER_CLASS}" data-t="session-logged-out-heading">You've been logged out.</h2>`,
        `<a class="ptibox_buttonBG lightbox-button-bg" href="" data-t="sign-in-link">
          <span class="lightbox-button-bg-span">Login</span>
        </a>`,
        '',
        'Session Timeout',
        null,
        550,
        280,
        null
      )
    ],
    showCloseButton: false,
    showInfo: 2
  });
};

/**
 * Calculates the next time to call authCheck.
 */
const getNextCheckTime = (timeoutMs: number): number =>
  timeoutMs > TWO_MINS ? timeoutMs - TWO_MINS : timeoutMs - (TIMEOUT_THRESHOLD + 1);

const authCheck = (pageTurn: OnlineMag): void => {
  if (navigator.onLine) {
    fetch(`https://${pageTurn.host}/AuthQuery.aspx`, FETCH_OPTIONS)
      .then(processResponse)
      // @ts-ignore
      .then((data: AuthResponse) => {
        pageTurn.auth = clearAuthTimeout(pageTurn.auth);

        const sessionRemaining = data.timeoutMilliseconds;
        const nextCheckTime = getNextCheckTime(sessionRemaining);

        if (sessionRemaining <= TIMEOUT_THRESHOLD || data.authenticated === false) {
          // The user isn't going to be able to achieve much in 5 seconds, to
          // avoid potential issues, consider them logged out with less than
          // 5 seconds to go.
          handleLogout(pageTurn);

          return;
        }

        if (sessionRemaining >= TIMEOUT_THRESHOLD && sessionRemaining <= TWO_MINS) {
          handleTimeoutWarning(pageTurn, sessionRemaining);
        }

        pageTurn.auth = updateAuthRequired(pageTurn.auth, {
          cancelToken: window.setTimeout(() => authCheck(pageTurn), nextCheckTime)
        });
      })
      .catch((error: ErrorResponse | Error) => {
        pageTurn.auth = clearAuthTimeout(pageTurn.auth);

        sendAuthError(error);
      });
  }
};

const sendAuthError = (error: ErrorResponse | Error) => {
  let message = '';
  let stack = 'No Stack';
  let type = 'Error';

  if (error instanceof Error) {
    /* eslint-disable prefer-destructuring */
    message = error.message;
    stack = error.stack;
    type = error.name;
    /* eslint-enable prefer-destructuring */
  } else {
    message = error.errorMessage;
  }

  window.errorReporter.sendError({
    col: 0,
    line: 0,
    message: `Query: ${message}`,
    stack,
    type
  });
};

function createNoAuth(): AuthNotRequired {
  return {
    [AUTH_REQUIRED]: false
  };
}

function createAuth(authFn: AuthFn, authPath: AuthPath): AuthRequired {
  return {
    [AUTH_REQUIRED]: true,
    authFn: authFn,
    cancelToken: null,
    countdownToken: null,
    path: authPath
  };
}

/**
 * Returns true if the document requires authentication
 */
function isAuthRequired(auth: Auth): auth is AuthRequired {
  return auth[AUTH_REQUIRED];
}

/**
 * Update the key with the provided value assuming the Auth type is AuthRequired.
 */
function updateAuthRequired(auth: Auth, updateObj: Partial<AuthRequired>): Auth {
  if (isAuthRequired(auth)) {
    return assoc(auth, updateObj);
  }

  return auth;
}

/**
 * Takes an Auth clears the interval and returns a new Auth with the token null'ed out.
 */
function clearAuthTimeout(auth: Auth): Auth {
  if (isAuthRequired(auth)) {
    window.clearTimeout(auth.cancelToken);

    return updateAuthRequired(auth, { cancelToken: null });
  }

  return auth;
}

/**
 * Takes an Auth clears the interval and returns a new Auth with the token null'ed out.
 */
function clearAuthInterval(auth: Auth): Auth {
  if (isAuthRequired(auth)) {
    window.clearInterval(auth.countdownToken);

    return updateAuthRequired(auth, { countdownToken: null });
  }

  return auth;
}

function cancelAllTimers(auth: Auth): Auth {
  if (isAuthRequired(auth)) {
    return clearAuthTimeout(clearAuthInterval(auth));
  }

  return auth;
}

/**
 * Returns the neccessary auth object.
 */
function getAuth(pageTurn: OnlineMag): Auth {
  const { captureEmail, employeeNoRequired, pwdRequired, readerLoginRequired, readerLoginVersion, ssoRequired } =
    pageTurn.config;
  const emailLoginRequired =
    (captureEmail > 0 && pageTurn.emailAddress.length === 0) || pwdRequired;

  if (emailLoginRequired) {
    return createAuth(pageTurn.promptForEmailAndOrPassword, null);
  } else if (readerLoginRequired) {
    // Does this branch of logic actually make sense anymore
    if (readerLoginVersion === 2) {
      return createAuth(pageTurn.checkReaderLoggedIn, 'ReaderV2');
    }
    else {
      return createAuth(pageTurn.checkReaderLoggedIn, 'Reader');
    }
  } else if (employeeNoRequired) {
    return createAuth(pageTurn.checkEmployeeLoggedIn, 'EmployeeNo');
  } else if (ssoRequired) {
    return createAuth(pageTurn.checkSsoLoggedIn, 'sso');
  }

  return createNoAuth();
}

/**
 * Returns the auth path
 */
function getAuthPath(auth: Auth): AuthPath {
  return isAuthRequired(auth) ? auth.path : null;
}

function isAuthModalActive(): boolean {
  return Boolean($.fn.ptimessage.isVisible() && document.querySelector('.js-auth-wrapper'));
}

/**
 * Does something
 */
const handleVisibilityChange = (pageTurn: OnlineMag) => (_e: Event) => {
  if (document.visibilityState === 'visible') {
    authCheck(pageTurn);
  } else {
    pageTurn.auth = cancelAllTimers(pageTurn.auth);
  }
};

export {
  authCheck,
  clearAuthInterval,
  clearAuthTimeout,
  getAuth,
  getAuthPath,
  handleVisibilityChange,
  isAuthRequired,
  isAuthModalActive,
  updateAuthRequired
};
