// @ts-ignore
import { Elm } from './ResponsiveMenu.elm';

const responsiveMenu = (mainWebsite: string, showPTLogo: boolean, accountDropdown = []): void => {
  const app = Elm.ResponsiveMenu.init({
    flags: {
      accountDropdown: accountDropdown.map(item => ({ href: item.action, text: item.text })),
      backgroundColour: window.menu.backgroundColour,
      mainWebsite: `https://${mainWebsite}`,
      menu: window.menu.subMenus || [],
      separatorColour: window.menu.seperatorColour,
      showPTLogo,
      textColour: window.menu.textColour
    },
    node: document.querySelector('.js-responsive-menu-mount')
  });

  window.toggleNav = app.ports.toggleNav.send;

  app.ports.lockBody.subscribe((shouldLockBody: boolean): void => {
    const className = 'mod-slideout-menu-open';

    if (shouldLockBody) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  });

  // This is dirty and dangerous. We're executing unknown JavaScript, but that's the system.
  app.ports.functionCall.subscribe(eval);
};

export { responsiveMenu };
