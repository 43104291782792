import { object, string, array, Infer, is } from 'superstruct';
import { htmlEncode, forceReload, getI18nByKey } from './utils';
import type { OnlineMag } from './@types/pagetiger';

const Issue = object({
  title: string(),
  url: string(),
  thumbnailUrl: string(),
  date: string()
});

const IssueList = array(Issue);

type Issue = Infer<typeof Issue>;

export type IssueList = Infer<typeof IssueList>;

const parseIssueList = (issueList: unknown): Promise<IssueList> => {
  if (is(issueList, IssueList)) {
    return Promise.resolve(issueList);
  }

  return Promise.reject('Invalid Issue List');
};

const getIssueListMarkup = (
  { thumbnailHeight, thumbnailWidth }: OnlineMag,
  issues: Issue[]
): string => {
  // This should never happen, as we shouldn't ever  request for the other versions if there
  // aren't any.
  if (issues.length === 0) {
    return `<h1 style="font-size: 20px;">${getI18nByKey('OtherVersions.None')}</h1>`;
  } else {
    return issues
      .map(
        item => `
      <div class="version-list">
        <a class="version-list-image-link" href="${item.url}">
          <img class="version-list-image" src="${
            item.thumbnailUrl
          }" height="${thumbnailHeight}px" width="${thumbnailWidth}px" alt="Document cover">
        </a>

        <div>
          <a class="version-list-heading-link" href="${item.url}">
            <h2 class="version-list-heading">
              ${htmlEncode(item.title)}
            </h2>
          </a>
          <span class="version-list-date">${htmlEncode(item.date)}</span>
        </div>
      </div>`
      )
      .join('');
  }
};

const getIssueVersions = (pageTurn: OnlineMag): Promise<IssueList> => {
  const { guid, host, proofingToken } = pageTurn;
  const { referralUrl } = pageTurn.config;
  const errorMessage = 'There was a problem getting the version list.';

  const url = new URL(`https://${host}/api/v1/other-versions`);
  url.searchParams.set('PT', proofingToken);
  url.searchParams.set('HN', document.location.hostname);
  url.searchParams.set('RU', referralUrl);
  url.searchParams.set('IGUID', guid);

  return fetch(url.href, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors'
  })
    .then(res => {
      if (res.status === 401) {
        forceReload();
        return Promise.reject();
      }

      if (res.ok) {
        return res;
      }

      return Promise.reject(errorMessage);
    })
    .then(res => res.json())
    .then(parseIssueList);
};

export { getIssueVersions, parseIssueList, getIssueListMarkup };
