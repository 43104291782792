import { h } from 'preact';
import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';
import { ClickOutside } from './ClickOutside';

import type { ToolbarLink } from '../@types/widgets';

const ESCAPE = 'Escape';

/**
 * Returns a function that returns a bool to inform you if the
 * component has mounted.
 */
function useDidMount() {
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
  }, []);

  return () => mountRef.current;
}

type AccountProps = {
  backgroundColour: string;
  dropdowns: ToolbarLink[];
};

function AccountDropDown({ backgroundColour, dropdowns }: AccountProps) {
  const [isOpen, setIsOpen] = useState(false);
  const firstItemRef = useRef<HTMLAnchorElement>();
  const menuToggleRef = useRef<HTMLButtonElement>();
  const hasMounted = useDidMount();

  useLayoutEffect(() => {
    if (!hasMounted()) {
      return;
    }

    const elementToFocus = isOpen ? firstItemRef.current : menuToggleRef.current;

    elementToFocus?.focus();
  }, [isOpen]);

  return (
    <div data-t="account-menu" className="toolbar-menu">
      <ClickOutside handleClick={() => setIsOpen(false)}>
        <button
          data-t="account-menu-button"
          id="toolbar-menu-button"
          class="toolbar-menu-button"
          aria-haspopup="true"
          aria-expanded={isOpen}
          ref={menuToggleRef}
          onClick={() => setIsOpen(prevState => !prevState)}
        >
          <span class="no-pointer visually-hidden">My Account</span>
          <svg class="mobile-menu-button-svg no-pointer">
            <use xlinkHref="#svg-account"></use>
          </svg>
        </button>

        <div
          class="toolbar-popup mod-account mod-menu"
          data-t="toolbar-menu"
          aria-hidden={isOpen ? false : true}
          style={{ backgroundColor: backgroundColour }}
        >
          <div
            class="toolbar-popup-arrow mod-account mod-menu"
            style={{ borderColor: backgroundColour }}
          ></div>
          <ul
            class="toolbar-popup-list"
            onKeyDown={e => {
              e.stopPropagation();

              e.key === ESCAPE && setIsOpen(false);
            }}
          >
            {dropdowns.map((dropdown, index) => (
              <li class="toolbar-popup-list-item" key={index}>
                <a
                  ref={index === 0 ? firstItemRef : null}
                  class="toolbar-popup-list-item-button"
                  href={dropdown.href}
                  data-t={`menu-item-my-account-${index}`}
                >
                  {dropdown.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </ClickOutside>
    </div>
  );
}

export { AccountDropDown };
