import { A11Y_PARAM_NAME, ACCESSIBILITY_MODE } from './constants';

import type { OnlineMag } from './@types/pagetiger';
import type { Config } from './@types/pagetiger';
import { getI18nByKey } from './utils';

type SkipLink = {
  predicate: (pageTurn: OnlineMag) => boolean;
  url: string;
};

const { HIGH_CONTRAST, HTML, LEGACY, NONE } = ACCESSIBILITY_MODE;

/**
 * 'N' - normal view
 * 'Y' - old accessible
 * 'H' - html view
 * 'C' - high contrast view
 */
function parseAccessibilityMode(s: string): Config['accessibleInterface'] {
  switch (s.toUpperCase()) {
    case LEGACY:
      return LEGACY;

    case HTML:
      return HTML;

    case HIGH_CONTRAST:
      return HIGH_CONTRAST;

    default:
      return NONE;
  }
}

function setQueryParam(url: URL, queryParam: string, queryValue): URL {
  url.searchParams.set(queryParam, queryValue);

  return url;
}

/**
 *
 * @param location The current location of the document
 * @param pageTurn
 * @returns A list of appropriate skip links
 */
function getSkipLinks(location: string, pageTurn: OnlineMag): string[] {
  const skipLinkClass = 'skip-link-accessible';
  const defaultViewLink = setQueryParam(new URL(location), A11Y_PARAM_NAME, NONE);
  const highContrastLink = setQueryParam(new URL(location), A11Y_PARAM_NAME, HIGH_CONTRAST);
  const a11yViewLink = setQueryParam(new URL(location), A11Y_PARAM_NAME, HTML);
  const a11yOldViewLink = setQueryParam(new URL(location), A11Y_PARAM_NAME, LEGACY);

  const skiplinks: SkipLink[] = [
    {
      predicate: _pt => true,
      url: `<a class="${skipLinkClass}" href="#main">${ getI18nByKey('SkipToContent') }</a>`
    },
    {
      predicate: ({ config }) => config.accessibleOptions.includes(HTML),
      url: `<a class="${skipLinkClass}" href="${a11yViewLink.href}">${ getI18nByKey('Menu.AccessibleView') }</a>`
    },
    {
      predicate: ({ config }) => config.accessibleOptions.includes(LEGACY),
      url: `<a class="skip-link-accessible" href="${a11yOldViewLink.href}">${ getI18nByKey('Menu.AccessibleView') }</a>`
    },
    {
      predicate: ({ config }) => config.accessibleOptions.includes(HIGH_CONTRAST),
      url: `<a class="${skipLinkClass}" href="${highContrastLink.href}">${ getI18nByKey('Menu.HighContrastView') }</a>`
    },
    {
      predicate: ({ config }) => config.accessibleOptions.includes(NONE),
      url: `<a class="${skipLinkClass}" href="${defaultViewLink.href}">${ getI18nByKey('Menu.DefaultView') }</a>`
    }
  ];

  return skiplinks.filter(link => link.predicate(pageTurn)).map(link => link.url);
}

export { getSkipLinks, parseAccessibilityMode };
