/* eslint-disable max-statements, prefer-destructuring, max-classes-per-file, complexity, max-lines-per-function */
/* @ts-check */
import $ from 'jquery';
import Maybe from 'crocks/Maybe';

import {
  ANALYTICS_EVENTS,
  COLUMN_SEPARATOR,
  EVENT_TYPES,
  MODULES,
  MODULE_TYPES,
  ROW_SEPARATOR
} from '../constants';
import { fireEvents, parseChainData } from '../events';
import { OnlineMagPage } from '../mag-page';
import { isTrue } from '../utils';
import { isGuid } from '../guid';

import type { ModuleTypes } from '../constants';
import type { Guid } from '../guid';

class OnlineMagPageLinks {
  onlineMagPage: OnlineMagPage;
  items: OnlineMagPageLink[];
  length: number;

  constructor(page: OnlineMagPage, links: string) {
    this.onlineMagPage = page;
    this.items = [];
    this.length = 0;

    if (links.length === 0) {
      return;
    }

    const arData = links.split(ROW_SEPARATOR);

    for (let intLinkIndex = 0; intLinkIndex < arData.length; ++intLinkIndex) {
      // Only add links to this page
      if (arData[intLinkIndex].split(COLUMN_SEPARATOR)[3] == this.onlineMagPage.id) {
        this.items[this.items.length] = new OnlineMagPageLink(this, arData[intLinkIndex]);

        this.onlineMagPage.onlineMagPages.onlineMagIssue.allOnlineMagPageLinks.push(
          this.items[this.items.length - 1]
        );
      }
    }

    this.length = this.items.length;
  }
}

class OnlineMagPageLink {
  onlineMagPageLinks: OnlineMagPageLinks;
  id: string;
  moduleGuid: Guid;
  moduleType: ModuleTypes;
  subType: string;
  onlineMagPageID: string;
  title: string;
  onPageName: string;
  tooltip: string;
  url: string;
  target: string;
  width: string;
  height: string;
  posLeft: string;
  posTop: string;
  important: boolean;
  mandatory: boolean;
  mandatoryMessage: string;
  chain: any[];
  isComplete: boolean;
  focusOutlineColour: string;

  constructor(links: OnlineMagPageLinks, link: string) {
    this.onlineMagPageLinks = links;

    if (link.length === 0) {
      return;
    }

    const pageTurn = links.onlineMagPage.onlineMagPages.onlineMagIssue;
    const { previousSession } = pageTurn;
    const arData = link.split(COLUMN_SEPARATOR);
    const guid = arData[1];

    if (!isGuid(guid)) {
      return;
    }

    this.id = arData[0];
    this.moduleGuid = guid;
    this.moduleType = MODULES.LINK;
    this.subType = arData[2];
    this.onlineMagPageID = arData[3];
    this.title = arData[4];
    this.onPageName = arData[18];
    this.tooltip = arData[5];
    this.url = arData[6];
    this.target = arData[7];
    this.width = arData[8];
    this.height = arData[9];
    this.posLeft = arData[10];
    this.posTop = arData[11];
    this.important = isTrue(arData[12]);
    this.mandatory = pageTurn.disableMandatory ? false : isTrue(arData[13]);
    this.mandatoryMessage = arData[14];
    this.focusOutlineColour = arData[19];

    this.chain = parseChainData(arData[16]).map(chain => ({
      ...chain,
      cancelFn: Maybe.Nothing(),
      sourceModuleGuid: this.moduleGuid,
      sourceModuleType: MODULE_TYPES.MODULE
    }));

    this.isComplete = previousSession.includes(`${MODULES.LINK}-${this.id}`);
  }

  clicked() {
    const { onlineMagIssue } = this.onlineMagPageLinks.onlineMagPage.onlineMagPages;
    const { analytics, baseUrl, onlineMagIssueVisitID } = onlineMagIssue;
    const url = `${baseUrl}?M=4&VID=${onlineMagIssueVisitID}&LID=${this.id}`;

    this.isComplete = true;

    fireEvents(EVENT_TYPES.ON_SHOW, this.chain);

    $.ajax({
      url,
      dataType: 'jsonp'
    });

    onlineMagIssue.onModuleComplete(this);
    fireEvents(EVENT_TYPES.ON_COMPLETE, this.chain);
    analytics.fireEvent(ANALYTICS_EVENTS.CLICKED, this.title, this.subType);
  }
}

export { OnlineMagPageLink, OnlineMagPageLinks };
