/* eslint-disable complexity, max-statements */
import clamp from 'ramda/src/clamp';
import { isNotNil } from './utils';

import type { OnlineMag, Page } from './@types/pagetiger';
import { OnlineMagPage } from './mag-page';

const getNextPageIndex = (pageTurn: OnlineMag, pageIndex: number): number => {
  const { config, onlineMagPages } = pageTurn;
  const isPageIndexOnRightSide = pageIndex % 2 === 1;
  const isNotLastPage = pageIndex < onlineMagPages.length - 1;

  if (pageTurn.zoom) {
    return pageIndex;
  }

  if (config.alwaysOpened && isPageIndexOnRightSide) {
    return pageIndex - 1;
  }

  if (!config.alwaysOpened && isPageIndexOnRightSide && isNotLastPage) {
    return pageIndex + 1;
  }

  return pageIndex;
};

type CurrentPages = [OnlineMagPage | null, OnlineMagPage | null] | [OnlineMagPage];

/**
 * Bring back an array (tuple) of the current pages that should be visible
 * A null is returned where we render out a blank (missing page)
 */
const getCurrentPages = ({
  config,
  onlineMagPages,
  currentPageIndex,
  zoom
}: OnlineMag): CurrentPages => {
  const { alwaysOpened } = config;
  const { items: pages } = onlineMagPages;
  const clampedPageIndex = clamp(0, onlineMagPages.length - 1, currentPageIndex);
  const oddNumberOfPages = onlineMagPages.length % 2 === 1;
  const prevPage = pages[clampedPageIndex - 1];
  const currentPage = pages[clampedPageIndex];
  const nextPage = pages[clampedPageIndex + 1];
  const isFirstPage = clampedPageIndex === 0;
  const isLastPage =
    alwaysOpened && !oddNumberOfPages
      ? clampedPageIndex === onlineMagPages.length
      : clampedPageIndex === onlineMagPages.length - 1;
  const doubleLastPage = !alwaysOpened && oddNumberOfPages && isLastPage;

  if (zoom) {
    return [currentPage];
  }

  if ((!alwaysOpened && !isFirstPage && !isLastPage) || doubleLastPage) {
    return [prevPage, currentPage];
  }

  if ((alwaysOpened && oddNumberOfPages && isLastPage) || isLastPage) {
    return [currentPage, null];
  }

  if (!alwaysOpened && isFirstPage) {
    return [null, currentPage];
  }

  return [currentPage, nextPage];
};

const currentVisiblePages = (pageTurn: OnlineMag): OnlineMagPage[] =>
  getCurrentPages(pageTurn).filter(isNotNil);

const currentVisiblePageIndexes = (pageTurn: OnlineMag): number[] =>
  currentVisiblePages(pageTurn).map(({ pageIndex }) => pageIndex);

const isOnLastPage = (pageTurn: OnlineMag): boolean => {
  const lastPageIndex = pageTurn.onlineMagPages.length - 1;

  if (pageTurn.zoom) {
    return !(pageTurn.currentPageIndex < lastPageIndex);
  }

  return currentVisiblePageIndexes(pageTurn).includes(lastPageIndex);
};

const lowestVisiblePageIndex = (pageTurn: OnlineMag): number => {
  if (pageTurn.zoom) {
    return pageTurn.currentPageIndex;
  }

  return getCurrentPages(pageTurn).filter(item => item !== null)[0].pageIndex;
};

const getUpdatedUrl = (pageTurn: OnlineMag, url: URL, rightPage: Page | null): string => {
  const { currentPageIndex, config, onlineMagPages, zoom } = pageTurn;

  // Using the current URL as the we could be viewing from `/documentName/versionName or `/documentName`
  const urlWithoutPageInfo = url.pathname
    .substring(1)
    .split('/')
    .filter(section => !section.match(/^page[0-9]{1,4}.htm$/gi) && section !== '')
    .join('/');

  const totalPages = onlineMagPages.items.length;
  const lastRightMissingModifier =
    !zoom && rightPage === null && currentPageIndex + 1 === totalPages ? 1 : 0;
  const pageIndexModifier = !zoom && !config.alwaysOpened ? 0 : 1;
  const currentPage = clamp(
    1,
    totalPages,
    currentPageIndex + pageIndexModifier + lastRightMissingModifier
  );

  url.pathname = `${urlWithoutPageInfo}/page${currentPage}.htm`;

  return url.href;
};

export {
  getUpdatedUrl,
  getCurrentPages,
  currentVisiblePageIndexes,
  currentVisiblePages,
  getNextPageIndex,
  isOnLastPage,
  lowestVisiblePageIndex
};
