import { getI18nByKey, getLayout } from './utils';
import { A11Y_PARAM_NAME, ACCESSIBILITY_MODE, HIGH_CONTRAST } from './constants';
import type { OnlineMag } from './@types/pagetiger';

const button = 'button';
const link = 'link';

type ToolbarMenuItemType = typeof button | typeof link;

export type UnprocessedToolbarMenuItem = {
  type: ToolbarMenuItemType;
  action: (pageTurn: OnlineMag) => string;
  extraClasses: string;
  text: (pageTurn: OnlineMag) => string;
  validate: (pageTurn: OnlineMag) => boolean;
};

export type ToolbarMenuItem = {
  type: UnprocessedToolbarMenuItem['type'];
  action: string;
  extraClasses: UnprocessedToolbarMenuItem['extraClasses'];
  text: string;
  validate?: UnprocessedToolbarMenuItem['validate'];
};

const getAccessibleLink = (
  p: OnlineMag,
  mode: OnlineMag['config']['accessibleInterface']
): string => {
  const url = new URL(p.onlineMagIssueUrl);

  url.searchParams.set(A11Y_PARAM_NAME, mode);

  if (p.proofingToken.length > 0) {
    url.searchParams.set('ptit', p.proofingToken);
  }

  if (p.previewDesignerImages) {
    url.searchParams.set('ptidi', 'y');
  }

  return url.href;
};

const toolbarMenu: UnprocessedToolbarMenuItem[] = [
  {
    type: button,
    action: _ => 'ptiContents',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.Contents'),
    validate: p => p.onlineMagShowContents && p.onlineMagPages.length > 1
  },
  {
    type: button,
    action: _ => 'ptiSwitchPageView',
    extraClasses: 'js-switch-page-view',
    text: p => (getLayout(p) === 'double' ? getI18nByKey('Menu.SinglePage') : getI18nByKey('Menu.DoublePage')),
    validate: p =>
      p.availableLayouts === 'single and double' &&
      p.onlineMagPages.length > 1 &&
      p.viewingInterface !== HIGH_CONTRAST
  },
  {
    type: button,
    action: _ => 'ptiIssues',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.OtherVersions'),
    validate: p => p.onlineMagShowIssueList
  },
  {
    type: link,
    action: p => getAccessibleLink(p, ACCESSIBILITY_MODE.LEGACY),
    extraClasses: '',
    text: _ => getI18nByKey('Menu.AccessibleView'),
    validate: p => p.config.accessibleOptions.includes(ACCESSIBILITY_MODE.LEGACY)
  },
  {
    type: link,
    action: p => getAccessibleLink(p, ACCESSIBILITY_MODE.HTML),
    extraClasses: '',
    text: _ => getI18nByKey('Menu.AccessibleView'),
    validate: p => p.config.accessibleOptions.includes(ACCESSIBILITY_MODE.HTML)
  },
  {
    type: link,
    action: p => getAccessibleLink(p, ACCESSIBILITY_MODE.HIGH_CONTRAST),
    extraClasses: '',
    text: _ => getI18nByKey('Menu.HighContrastView'),
    validate: p => p.config.accessibleOptions.includes(ACCESSIBILITY_MODE.HIGH_CONTRAST)
  },
  {
    type: link,
    action: p => getAccessibleLink(p, ACCESSIBILITY_MODE.NONE),
    extraClasses: '',
    text: _ => getI18nByKey('Menu.DefaultView'),
    validate: p => p.config.accessibleOptions.includes(ACCESSIBILITY_MODE.NONE)
  },
  {
    type: button,
    action: _ => 'ptiShare',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.Share'),
    validate: p => p.onlineMagShowShare
  },
  {
    type: button,
    action: _ => 'ptiSearch',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.Search'),
    validate: p => p.onlineMagShowSearch && p.onlineMagPages.length > 1
  },
  {
    type: button,
    action: _ => 'ptiDownloadPDF',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.DownloadPdf'),
    validate: p => p.onlineMagShowDownload
  },
  {
    type: button,
    action: _ => 'ptiPrint',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.PrintPDF'),
    validate: p => p.onlineMagShowPrint
  },
  {
    type: button,
    action: _ => 'ptiCookie',
    extraClasses: '',
    text: _ => getI18nByKey('Menu.Cookies'),
    validate: _ => true
  }
];

const processToolbarItem = (
  pageTurn: OnlineMag,
  item: UnprocessedToolbarMenuItem
): ToolbarMenuItem => {
  return {
    ...item,
    action: item.action(pageTurn),
    extraClasses: item.extraClasses,
    text: item.text(pageTurn)
  };
};

const getToolbarItems = (pageTurn: OnlineMag): ReadonlyArray<ToolbarMenuItem> =>
  toolbarMenu.reduce((items, item) => {
    if (item.validate(pageTurn)) {
      return [...items, processToolbarItem(pageTurn, item)];
    }

    return items;
  }, [] as ReadonlyArray<ToolbarMenuItem>);

export { getToolbarItems };
