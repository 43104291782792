/* eslint-disable sort-keys */
import type { PropKeyToUnion } from "./@types/helpers";

const COLUMN_SEPARATOR = '~\\';

const LANDSCAPE = 'landscape';

const NAV_ARROW_CLASS = 'js-nav-arrow';

const PORTRAIT = 'portrait';

const ROW_SEPARATOR = '|\\';

const CHART_WIDTHS = {
  XX_SMALL: 130,
  X_SMALL: 260,
  SMALL: 550
} as const;

const TOOLBAR_POSITION = {
  TOP: 'top',
  TOP_AND_BOTTOM: 'top and bottom',
  BOTTOM: 'bottom',
  HIDDEN: 'hidden'
} as const;

const COOKIE_MANAGERS = {
  ONETRUST: 'onetrust',
  PAGETIGER: 'pagetiger'
} as const;

const DESIGNER_MESSAGE_EVENTS = {
  REMOVE_HIGHLIGHT_PANEL: 'REMOVE_HIGHLIGHT_PANEL',
  HIGHLIGHT_PANEL: 'HIGHLIGHT_PANEL',
  LOADED: 'LOADED'
} as const;

const ANALYTICS_EVENTS = {
  CLICKED: 'Clicked',
  PLAYED: 'Played',
  SUBMITTED: 'Submitted'
} as const;

const LAYOUTS = {
  AUTO: 'auto',
  SINGLE: 'single',
  DOUBLE: 'double',
  SINGLE_AND_DOUBLE: 'single and double'
} as const;

const VIEWING_INTERFACE = {
  STACKABLE: 'STACKABLE',
  PAGE_TURN: 'PAGE_TURN',
  HTML_PAGE_TURN: 'HTML_PAGE_TURN',
  HTML_STACKABLE: 'HTML_STACKABLE',
  HIGH_CONTRAST: 'HIGH_CONTRAST'
};

const ANIMATION_MODES = {
  BOOK: 'book',
  SCROLLING: 'scrolling',
  /** 'page turn' should be deprecated. Need to check the DB before removing */
  PAGE_TURN: 'page turn'
} as const;

const ACCESSIBILITY_MODE = {
  LEGACY: 'Y',
  HTML: 'H',
  HIGH_CONTRAST: 'C',
  NONE: 'N'
} as const;

export type A11yMode = PropKeyToUnion<typeof ACCESSIBILITY_MODE>;

export type ViewingInterface = PropKeyToUnion<typeof VIEWING_INTERFACE>;

export type AnimationMode = PropKeyToUnion<typeof ANIMATION_MODES>;

export type AnalyticsEvents = PropKeyToUnion<typeof ANALYTICS_EVENTS>;

export type Layouts = PropKeyToUnion<typeof LAYOUTS>;

export type PageLayouts = Exclude<Layouts, 'single and double'>;

export type CookieManagers = PropKeyToUnion<typeof COOKIE_MANAGERS>;

/**
 * This size is used in CSS (.side-nav and .toolbar) So make sure you change it in those places
 */
const SMALL_SCREEN_WIDTH = 900;

const TABLE_SEPARATOR = '}\\';

const CHAIN_ACTION = 'CHAIN_ACTION';

const ADD_EVENT = 'ADD_EVENT';

const CANCEL_AND_REMOVE_EVENTS = 'CANCEL_AND_REMOVE_EVENTS';

const A11Y_PARAM_NAME = 'ptia';

const TRANSPARENT_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const VIDEO_SUB_TYPES = {
  MP4_IN_PAGE: 'Play video file',
  MP4_POPUP: 'Play video file in popup',
  YOUTUBE: 'Play Vimeo video in popup',
  VIMEO: 'Play YouTube video in popup'
} as const;

const MODULES = {
  LINK: 1,
  POLL_ANSWER: 2,
  ARTICLE: 3,
  IFRAME: 4,
  VIDEO: 5,
  JUMP: 6,
  COMP: 7,
  GALLERY: 8,
  FORWARD: 9,
  SHARE: 10,
  CHART: 11
} as const;

export type ModuleTypes = PropKeyToUnion<typeof MODULES>;

const MODULE_SUB_TYPES = {
  CHART: 'Chart',
  CHART_POP_UP: 'Chart in popup',
  VIDEO: 'Play video file',
  VIDEO_POP_UP: 'Play video file in popup',
  VIMEO: 'Play Vimeo video in popup',
  YOUTUBE: 'Play YouTube video in popup'
} as const;

const MODULE_TYPES = {
  PAGE: 'PAGE',
  MODULE: 'MODULE'
} as const;

const ACTION_TYPES = {
  GO_TO_PAGE_INDEX: 1,
  GO_TO_PAGE_GUID: 2,
  GO_TO_NEXT_PAGE: 3,
  GO_TO_PREV_PAGE: 4,
  ACTIVATE_MODULE: 5,
  CLOSE_MODULE: 6,
  PLAY_VIDEO: 8
} as const;

const COLOURS = {
  BLACK: '#000',
  WHITE: '#fff'
} as const;

export type ActionTypes = typeof ACTION_TYPES[keyof typeof ACTION_TYPES];

const EVENT_TYPES = {
  ON_CLOSE: 1,
  ON_SHOW: 2,
  ON_COMPLETE: 3,
  ON_VIDEO_TIME: 4,
  ON_GALLERY_IMAGE_INDEX: 5,
  ON_RESULT_POP_UP_CLOSE: 6,
  ON_RESULT_SHOW: 7
} as const;

export type EventTypes = PropKeyToUnion<typeof EVENT_TYPES>;

const NEXT_PAGE = 'NEXT_PAGE';
const PREV_PAGE = 'PREV_PAGE';
const RESIZE = 'RESIZE';
const GO_TO_PAGE = 'GO_TO_PAGE';
const SHOW = 'SHOW';
const GLOW = 'GLOW';

const HIGH_CONTRAST = 'HIGH_CONTRAST';
const HTML_STACKABLE = 'HTML_STACKABLE';
const HTML_PAGE_TURN = 'HTML_PAGE_TURN';
const PAGE_TURN = 'PAGE_TURN';
const STACKABLE = 'STACKABLE';
const DEVICE_MOBILE = 'MOBILE';
const DEVICE_DESKTOP = 'DESKTOP';

const DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE = `
  <h1 class="lightbox-required-heading">{{0}}</h1>
  <p class="lightbox-required-text">{{1}}</p>`;

const AUTH_REQUIRED = Symbol('authRequired');

const MANDATORY_CLASS = 'mod-mandatory';

export {
  ACCESSIBILITY_MODE,
  A11Y_PARAM_NAME,
  ADD_EVENT,
  ACTION_TYPES,
  ANALYTICS_EVENTS,
  ANIMATION_MODES,
  AUTH_REQUIRED,
  CANCEL_AND_REMOVE_EVENTS,
  CHART_WIDTHS,
  CHAIN_ACTION,
  COLUMN_SEPARATOR,
  COLOURS,
  COOKIE_MANAGERS,
  DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE,
  DESIGNER_MESSAGE_EVENTS,
  DEVICE_MOBILE,
  DEVICE_DESKTOP,
  EVENT_TYPES,
  GLOW,
  GO_TO_PAGE,
  HIGH_CONTRAST,
  HTML_PAGE_TURN,
  HTML_STACKABLE,
  LANDSCAPE,
  LAYOUTS,
  MANDATORY_CLASS,
  MODULES,
  MODULE_TYPES,
  MODULE_SUB_TYPES,
  NAV_ARROW_CLASS,
  NEXT_PAGE,
  PAGE_TURN,
  PREV_PAGE,
  PORTRAIT,
  RESIZE,
  ROW_SEPARATOR,
  SHOW,
  SMALL_SCREEN_WIDTH,
  STACKABLE,
  TABLE_SEPARATOR,
  TOOLBAR_POSITION,
  TRANSPARENT_GIF,
  VIDEO_SUB_TYPES,
  VIEWING_INTERFACE
};
