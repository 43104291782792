import { h } from 'preact';

function PageTigerLogo() {
  return (
    <div class="desktop-menu-right js-desktop-menu-right">
      <a
        data-t="pagetiger-logo"
        class="toolbar-button mod-logo"
        target="_blank"
        rel="noopener noreferrer"
        href="https://pagetiger.com/"
        data-tooltip="Powered By PageTiger"
      >
        <span class="no-pointer visually-hidden">Powered By PageTiger</span>
        <svg class="mobile-menu-button-svg no-pointer">
          <use xlinkHref="#svg-logo"></use>
        </svg>
      </a>
    </div>
  );
}

export { PageTigerLogo };
