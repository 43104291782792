import { h, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { CSSTransition } from 'react-transition-group';

import { HoverIntent } from './HoverIntent';
import { PageTigerLogo } from './PageTigerLogo';
import { AccountDropDown } from './AccountDropdown';
import { fetchMenuItems, MenuAccount, UPDATE_MENU_EVENT } from '../menu';

import type { OnlineMag } from '../@types/pagetiger';
import type { PropKeyToUnion } from '../@types/helpers';
import type { MutableRef, StateUpdater } from 'preact/hooks';
import { throttle } from 'throttle-debounce';

// ================
// CONSTANTS
// ================

const ARROW_DIRECTIONS = {
  VERTICAL: 'vertical',
  HORZIONTAL: 'horizontal'
} as const;

const ESCAPE = 'Escape';
const MOBILE_CLASS = 'mobile';
const DESKTOP_CLASS = 'desktop';
const DESKTOP = 'DESKTOP';
const MOBILE = 'MOBILE';

/**
 * A mapping a key names to the movement value they represent
 */
const keyMap = new Map([
  ['ArrowDown', 1],
  ['ArrowUp', -1],
  ['ArrowLeft', -1],
  ['ArrowRight', 1],
  ['Home', -Infinity],
  ['End', Infinity]
]);

/**
 * All links in that open a new tab require these attributes for security
 */
const safeNewTabLinkAttributes = {
  target: '_blank',
  rel: 'noopener noreferrer'
} as const;

// ================
// TYPES
// ================
type MenuType = typeof DESKTOP | typeof MOBILE;

type Direction = PropKeyToUnion<typeof ARROW_DIRECTIONS>;

type MenuItemCommon = {
  backgroundColour: string;
  backgroundHoverColour: string;
  id: number;
  text: string;
};

export type MenuLink = {
  _brand: 'link';
  href: string;
  target: '_blank' | '';
} & MenuItemCommon;

export type MenuButton = {
  _brand: 'button';
  action: string;
  actionArg: string;
} & MenuItemCommon;

export type MenuSubMenu = {
  _brand: 'submenu';
  items: MenuItems;
} & MenuItemCommon;

export type MenuItem = MenuSubMenu | MenuButton | MenuLink;

export type MenuItems = MenuItem[];

export type MenuOptions = {
  items: MenuItems;
  backgroundColour: string;
  backgroundHoverColour: string;
  textColour: string;
  submenuArrows: boolean;
  horizontalSeperator: boolean;
  verticalSeperator: boolean;
  seperatorColour: string;
  showLogo: boolean;
  accountMenu: MenuAccount;
};

type SetActiveIndex = StateUpdater<number>;

export type MenuSettings = Omit<MenuOptions, 'items'>;

type MenuItemElement = HTMLAnchorElement | HTMLButtonElement;

// ================
// HELPER FUNCTIONS
// ================

/**
 * When a key is pressed return the value in which direction we want to go.
 * Home and End are set to the largest possible values, so we'll need to clamp
 */
function mapKeyToMovement(key: string): number {
  return keyMap.get(key) ?? 0;
}

/**
 * Clamps a number between 0 and the supplied upper bound {0, 1, 2, 3, ..., upperBound}
 * @returns The bounded number
 */
function clampUpper(number: number, upperBound: number): number {
  return Math.min(Math.max(number, 0), upperBound);
}

/**
 * Works out the next index based upon the current key (ArrowLeft) that was pressed.
 */
function getNewActiveIndex(key: string, index: number | null = 0, menuLength: number) {
  const newIndexWithoutBoundsCheck = index + mapKeyToMovement(key);
  return clampUpper(newIndexWithoutBoundsCheck, menuLength - 1);
}

/**
 * Type guard to limit refine an event target to an Anchor or a button.
 */
function isButtonOrAnchor(el: EventTarget): el is HTMLAnchorElement | HTMLButtonElement {
  return el instanceof HTMLAnchorElement || el instanceof HTMLButtonElement;
}

/**
 * Focuses on the supplied element if isActive is true.
 */
function useActiveFocus(isActive: boolean, el: MenuItemElement) {
  useEffect(() => {
    if (isActive) {
      el.focus();
    }
  }, [isActive]);
}

// ================
// COMPONENTS
// ================

type RenderMenuItem = {
  closeMobileDrawer: () => void;
  activeIndex: number;
  currentMenuLength: number;
  index: number;
  level: number;
  menuItem: MenuItem;
  menuSettings: MenuSettings;
  setActiveIndex: SetActiveIndex;
};

/**
 * Renders the correct menu type based upon the type that's passed in
 */
function renderMenuItem({
  menuSettings,
  menuItem,
  level,
  index,
  activeIndex,
  setActiveIndex,
  currentMenuLength,
  closeMobileDrawer
}: RenderMenuItem) {
  switch (menuItem._brand) {
    case 'link':
      return (
        <MenuLink
          closeMobileDrawer={closeMobileDrawer}
          key={level + menuItem.id}
          link={menuItem}
          activeIndex={activeIndex}
          index={index}
          level={level}
          setActiveIndex={setActiveIndex}
        />
      );

    case 'button':
      return (
        <MenuButton
          closeMobileDrawer={closeMobileDrawer}
          key={level + menuItem.id}
          menuSettings={menuSettings}
          item={menuItem}
          activeIndex={activeIndex}
          index={index}
          level={level}
          setActiveIndex={setActiveIndex}
        />
      );

    case 'submenu':
      return (
        <MenuSubMenu
          closeMobileDrawer={closeMobileDrawer}
          activeIndex={activeIndex}
          currentMenuLength={currentMenuLength}
          key={level + menuItem.id}
          index={index}
          item={menuItem}
          level={level}
          menuSettings={menuSettings}
          setActiveIndex={setActiveIndex}
        />
      );
  }
}

type MenuItemSubMenuProps = {
  closeMobileDrawer: () => void;
  currentMenuLength: number;
  item: MenuSubMenu;
  level: number;
  menuSettings: MenuSettings;
  index: number;
  activeIndex: number;
  setActiveIndex: SetActiveIndex;
};

function MenuSubMenu({
  closeMobileDrawer,
  item,
  level,
  index,
  activeIndex,
  menuSettings,
  setActiveIndex,
  currentMenuLength
}: MenuItemSubMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef<HTMLButtonElement>();
  const popupRef = useRef<HTMLUListElement>();
  const itemId = item.id.toString();
  const isActive = activeIndex === index;

  useActiveFocus(isActive, parentRef.current);

  return (
    <HoverIntent
      enterWait={150}
      enterCallback={() => {
        setIsOpen(true);
      }}
      leaveCallback={() => {
        setIsOpen(false);
      }}
    >
      <div
        className="pt-nav-sub-nav-container"
        onKeyDown={e => {
          if (isButtonOrAnchor(e.target)) {
            const targetsLevel = parseInt(e.target.dataset.level);

            /** Allow the current levels events to bubble up. Stop anything else. */
            if (level !== targetsLevel) {
              e.stopPropagation();
            }

            if (e.key === ESCAPE) {
              setIsOpen(false);
              parentRef.current?.focus();
            }
          }
        }}
        onBlur={e => {
          const currentTarget = e.currentTarget;

          // Give browser time to focus the next element
          requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
              // Do blur logic here!
              setIsOpen(false);
            }
          });
        }}
      >
        <button
          onKeyDown={e => {
            if (e.key !== ESCAPE) {
              e.stopPropagation();
            }

            if (keyMap.has(e.key)) {
              e.preventDefault();
            }

            const newActiveIndex = getNewActiveIndex(e.key, activeIndex, currentMenuLength);

            if (activeIndex !== newActiveIndex) {
              setActiveIndex(newActiveIndex);
            }
          }}
          onFocus={() => setActiveIndex(index)}
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}
          id={`menu-${item.id}`}
          className={`pt-nav-item-button mod-submenu mod-level-${level}`}
          ref={parentRef}
          data-t={`menu-item-${item.id}`}
          data-level={level}
          type="button"
          aria-expanded={isOpen}
          aria-controls={itemId}
        >
          {item.text}

          {menuSettings.submenuArrows && (
            <SubMenuArrow
              direction={level === 0 ? ARROW_DIRECTIONS.VERTICAL : ARROW_DIRECTIONS.HORZIONTAL}
              highlight={isOpen}
            />
          )}
        </button>

        <MenuItems
          item={item}
          popupRef={popupRef}
          level={level + 1}
          menuSettings={menuSettings}
          menuItems={item.items}
          closeMobileDrawer={closeMobileDrawer}
        />
      </div>
    </HoverIntent>
  );
}

type SubMenuArrowProps = {
  direction: Direction;
  highlight: boolean;
};

function SubMenuArrow({ direction, highlight }: SubMenuArrowProps) {
  const highlightClass = highlight ? `mod-highlight` : ``;

  return (
    <div
      className={`pt-nav-item-arrow mod-${direction} ${highlightClass}`}
      data-t="submenu-arrow"
    ></div>
  );
}

type MenuItemButtonProps = {
  activeIndex: number;
  index: number;
  item: MenuButton;
  level: number;
  menuSettings: MenuSettings;
  setActiveIndex: SetActiveIndex;
  closeMobileDrawer: () => void;
};
/**
 * Renders a menu button
 */
function MenuButton({
  item,
  activeIndex,
  closeMobileDrawer,
  index,
  level,
  setActiveIndex
}: MenuItemButtonProps) {
  const element = useRef<HTMLButtonElement>();
  const isActive = index === activeIndex;

  useActiveFocus(isActive, element.current);

  return (
    <button
      className={`pt-nav-item-button mod-level-${level}`}
      onFocus={() => setActiveIndex(index)}
      onClick={closeMobileDrawer}
      id={`menu-${item.id}`}
      data-t={`menu-item-${item.id}`}
      data-level={level}
      type="button"
      data-action={item.action}
      data-action-arg={item.actionArg}
      ref={element}
    >
      {item.text}
    </button>
  );
}

type MenuItemLinkProps = {
  activeIndex: number;
  closeMobileDrawer: () => void;
  index: number;
  level: number;
  link: MenuLink;
  setActiveIndex: SetActiveIndex;
};

/**
 * Renders a link menu item
 */
function MenuLink({
  activeIndex,
  closeMobileDrawer,
  index,
  level,
  link,
  setActiveIndex
}: MenuItemLinkProps) {
  const element = useRef<HTMLAnchorElement>();
  const isActive = index === activeIndex;
  const linkAttributes = link.target === '_blank' ? safeNewTabLinkAttributes : {};

  useActiveFocus(isActive, element.current);

  return (
    <a
      className={`pt-nav-item-link mod-level-${level}`}
      onFocus={() => setActiveIndex(index)}
      onClick={closeMobileDrawer}
      id={`menu-${link.id}`}
      data-t={`menu-item-${link.id}`}
      data-level={level}
      href={link.href}
      data-active={isActive}
      ref={element}
      {...linkAttributes}
    >
      {link.text}
    </a>
  );
}

type MenuItemsProps = {
  closeMobileDrawer: () => void;
  item?: MenuItem;
  level: number;
  menuItems: MenuItems;
  menuSettings: MenuSettings;
  popupRef: MutableRef<HTMLUListElement>;
};

function MenuItems({
  closeMobileDrawer,
  item,
  level,
  menuItems,
  menuSettings,
  popupRef
}: MenuItemsProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const itemId = item ? item.id : 0;

  return (
    <ul
      className={`pt-nav-list mod-level-${level}`}
      id={itemId.toString()}
      data-level={level}
      data-t={`menu-container-${itemId}`}
      ref={popupRef}
      onKeyDown={e => {
        /* Don't allow the arrow keys to actually scroll the page, but do allow Tab
            to focus on the next item */
        if (keyMap.has(e.key)) {
          e.preventDefault();
        }

        /* Don't allow nested submenu events to bubble up to their parent submenu */
        if (e.key !== ESCAPE) {
          e.stopPropagation();
        }

        const newActiveIndex = getNewActiveIndex(e.key, activeIndex, menuItems.length);

        if (activeIndex !== newActiveIndex) {
          setActiveIndex(newActiveIndex);
        }
      }}
    >
      {menuItems.map((menuItem, itemIndex) => (
        <li className={`pt-nav-list-item mod-level-${level}`}>
          {renderMenuItem({
            menuSettings,
            menuItem,
            level,
            index: itemIndex,
            activeIndex,
            setActiveIndex,
            currentMenuLength: menuItems.length,
            closeMobileDrawer
          })}
        </li>
      ))}
    </ul>
  );
}

/**
 * The entry point for rendering the new menu system
 */
function Menu({
  docGuid,
  menuSettings,
  menuItems: items
}: {
  docGuid: OnlineMag['guid'];
  menuSettings: MenuSettings;
  menuItems: MenuItems;
}) {
  const [menuItems, setMenuItems] = useState(items);
  const [menuMode, setMenuMode] = useState<MenuType>(DESKTOP);
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(function navWidthEffect() {
    const navWidth = document.querySelector('.pt-nav').getBoundingClientRect().width;
    const navRight = document.querySelector('.pt-nav-right').getBoundingClientRect().width;

    const handleWindowResize = throttle(200, () => {
      const viewportWidth = document.documentElement.clientWidth;
      const totalNavWidth = navWidth + navRight;

      if (viewportWidth < totalNavWidth) {
        setMenuMode(MOBILE);
      }

      if (viewportWidth > totalNavWidth) {
        setMenuMode(DESKTOP);
        setIsOpen(false);
      }
    });

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return function navWidthEffectCleanup() {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(function fetchMenuEffect() {
    setMenuItems(menuItems);

    function getMenuItems() {
      fetchMenuItems(docGuid).then(setMenuItems).catch(console.error);
    }

    document.addEventListener(UPDATE_MENU_EVENT, getMenuItems);

    return function fetchMenuEffectCleanup() {
      document.removeEventListener(UPDATE_MENU_EVENT, getMenuItems);
    };
  }, []);

  return (
    <div
      data-t="menu"
      className="nav-container"
      ref={navRef}
      style={{
        backgroundColor: menuSettings.backgroundColour
      }}
    >
      {menuMode === MOBILE ? (
        <MobileMenu
          menuItems={menuItems}
          menuSettings={menuSettings}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <DesktopMenu
          menuItems={menuItems}
          menuSettings={menuSettings}
          closeMobileDrawer={() => setIsOpen(false)}
        />
      )}

      <div className="pt-nav-right">
        {menuSettings.accountMenu && (
          <AccountDropDown
            backgroundColour={menuSettings.backgroundColour}
            dropdowns={menuSettings.accountMenu.dropdowns}
          />
        )}
        {menuSettings.showLogo && <PageTigerLogo />}
      </div>
    </div>
  );
}

type MenuProps = {
  menuItems: MenuItems;
  menuSettings: MenuSettings;
};

type DesktopMenuProps = MenuProps & {
  closeMobileDrawer: () => void;
};

function DesktopMenu({ closeMobileDrawer, menuItems, menuSettings }: DesktopMenuProps) {
  return (
    <nav
      data-t="menu-desktop"
      className={`pt-nav pt-nav-${DESKTOP_CLASS} js-menu-bar`}
      aria-label="Quick links menu"
    >
      <MenuItems
        popupRef={null}
        level={0}
        menuItems={menuItems}
        menuSettings={menuSettings}
        closeMobileDrawer={closeMobileDrawer}
      />
    </nav>
  );
}

type MobileMenuProps = MenuProps & {
  isOpen: boolean;
  setIsOpen: StateUpdater<boolean>;
};

function MobileMenu({ isOpen, menuItems, menuSettings, setIsOpen }: MobileMenuProps) {
  const closeButtonRef = useRef<HTMLButtonElement>();
  const openButtonRef = useRef<HTMLButtonElement>();

  useEffect(
    function toggleInert() {
      /** All of these elements contain focusable elements which we want
       * to hide from a keyboard user */
      const elements = [
        document.querySelector('main'),
        ...document.querySelectorAll('nav'),
        ...document.querySelectorAll('.skip-link-accessible')
      ];
      const addInert = (el: Element) => el.setAttribute('inert', 'true');
      const removeInert = (el: Element) => el.removeAttribute('inert');
      const fn = isOpen ? addInert : removeInert;

      elements.forEach(fn);

      return function toggleInertCleanup() {
        elements.forEach(removeInert);
      };
    },
    [isOpen]
  );

  return (
    <div className={`pt-nav-mobile-menu pt-nav-${MOBILE_CLASS}`} data-t="menu-mobile">
      <button
        onClick={() => setIsOpen(true)}
        class="pt-nav-mobile-menu-button"
        data-t="menu-mobile-open-button"
        ref={openButtonRef}
        id="open-menu"
      >
        <span class="pt-nav-mobile-menu-text">Menu</span>
      </button>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={'pt-nav-mobile-drawer'}
        onEnter={() => {
          closeButtonRef.current.focus();
        }}
        onExit={() => {
          openButtonRef.current.focus();
        }}
        unmountOnExit={true}
      >
        <div
          style={{ backgroundColor: menuSettings.backgroundColour }}
          data-t="menu-mobile-drawer"
          className="pt-nav-mobile-drawer"
          data-open={isOpen}
        >
          <div className="pt-nav-mobile-close-container">
            <button
              onClick={() => setIsOpen(false)}
              data-t="menu-mobile-close-button"
              ref={closeButtonRef}
              class="pt-nav-mobile-close-button"
              id="close-menu-button"
            >
              <span class="visually-hidden">Close</span>
              <svg class="slideout-nav-close-button-svg" fill={menuSettings.textColour}>
                <use xlinkHref="#svg-close"></use>
              </svg>
            </button>
          </div>

          <div>
            <MenuItems
              closeMobileDrawer={() => setIsOpen(false)}
              popupRef={null}
              level={0}
              menuItems={menuItems}
              menuSettings={menuSettings}
            />
          </div>
        </div>
      </CSSTransition>

      <Overlay isActive={isOpen} handleClick={() => setIsOpen(false)} />
    </div>
  );
}

function Overlay({ handleClick, isActive }) {
  const activeClass = isActive ? 'mod-active' : '';

  return <div className={`pt-nav-overlay ${activeClass}`} onClick={handleClick} />;
}

function renderMenu(menuSettings: MenuSettings, menuItems: MenuItems, docGuid: OnlineMag['guid']) {
  render(
    <Menu docGuid={docGuid} menuSettings={menuSettings} menuItems={menuItems} />,
    document.querySelector('.js-menu-mounting-point')
  );
}

export { Menu, renderMenu };
